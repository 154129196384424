import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update
} from '../../permission-profiles/services/PermissionProfilesService';

import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase
} from '../../../store/RecordsSliceCaseHelpers';
import {PermissionProfile} from "../types.ts";
import {moduleConfig} from "../config";


const entityKey = moduleConfig.entityKey;

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
};

const normalizeRecord = (record: PermissionProfile) => {
    return Object.assign({}, record);
};

// genenrates pending, fulfilled and rejected
export const fetchPermissionProfiles = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    (query: object) => {
        return fetchRecords(query)
            .then((response) => {
                return response?.map((record) => {
                    return normalizeRecord(record);
                });
            });
    },
);

export const fetchPermissionProfile = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                return normalizeRecord(response as PermissionProfile);
            });
    });


export const savePermissionProfile = createAsyncThunk(
    `${entityKey}/add`,
    (payload: PermissionProfile) => {
        return add(payload)
            .then((response) => normalizeRecord(response as PermissionProfile));
    });

export const updatePermissionProfile = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record
     }: { id: string, record: PermissionProfile }) => {

        return update(id, record)
            .then((response) => {
                return normalizeRecord(response as PermissionProfile);
            });
    },
);

export const deletePermissionProfile = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    },
);

const permissionProfilesSlice = createSlice({
                                                name: entityKey,
                                                initialState,
                                                reducers: {},
                                                extraReducers: (builder) => {
                                                    // FETCH MANY
                                                    fetchManyCase(builder, fetchPermissionProfiles, entityKey);

                                                    // FETCH ONE
                                                    fetchOneCase(builder, fetchPermissionProfile);

                                                    // SAVE ONE
                                                    saveOneCase(builder, savePermissionProfile);

                                                    // UPDATE ONE
                                                    updateOneCase(builder, updatePermissionProfile);

                                                    // DELETE ONE
                                                    deleteOneCase(builder, deletePermissionProfile);
                                                },
                                            });

export default permissionProfilesSlice.reducer;
