import {useEffect} from 'react';

import {useParams} from 'react-router-dom';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    PlatformLoader,
    SimplePageContentWrapper,
} from '@pac/platform-ui-components';

import {EditPageNavigation} from '../navigation/EditPageNavigation.tsx';
import {Profile} from "../profile/Profile";
import {useEditPageTools} from "../hooks/useEditPageTools";
import {usePolicyStore} from "../hooks/usePolicyStore";


export const EditPage = () => {

    const {id: permissionProfileId} = useParams();

    const {
        record: policyStore,
        handleFetchRecord,
        handleUpdateRecord
    } = usePolicyStore(permissionProfileId);

    const {
        mode,
        tools
    } = useEditPageTools();


    useEffect(() => {
        handleFetchRecord();
    }, [permissionProfileId]);


    if (typeof policyStore === 'undefined') {
        return <PlatformLoader message='Loading policy store...Please wait'/>;
    }


    return (
        <FormPageLayout
            title={(`Policy Store: ${policyStore.description} [ ${policyStore.id} ]`)}
            pageNavigation={<EditPageNavigation record={policyStore}/>}
            tools={tools}
        >

            {mode === 'view' ?
                <SimplePageContentWrapper>
                    <Profile record={policyStore}/>
                </SimplePageContentWrapper>
                :
                <SimplePageContentWrapper>
                    <RecordForm
                        record={policyStore}
                        handleSubmit={handleUpdateRecord}
                    />
                </SimplePageContentWrapper>}


        </FormPageLayout>
    );
};
