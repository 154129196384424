import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {
    fetch,
    fetchRecords
} from '../services/PlatformAccountsService';

import {
    fetchManyCase,
    fetchOneCase
} from '../../../store/RecordsSliceCaseHelpers';
import {PlatformAccount} from "../types.ts";
import {moduleConfig} from "../config";


const entityKey = moduleConfig.entityKey;

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
};

const normalizeRecord = (record: PlatformAccount) => {
    return {
        ...record,
        ...{
            id: record.accountId
        }
    };
};

// genenrates pending, fulfilled and rejected
export const fetchPlatformAccounts = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    (query: object) => {
        return fetchRecords(query)
            .then((response) => {
                return response?.map((record) => {
                    return normalizeRecord(record);
                });
            });
    },
);

export const fetchPlatformAccount = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                return normalizeRecord(response as PlatformAccount);
            });
    });


const deploymentEnvironmentsSlice = createSlice({
                                                    name: entityKey,
                                                    initialState,
                                                    reducers: {},
                                                    extraReducers: (builder) => {
                                                        // FETCH MANY
                                                        fetchManyCase(builder, fetchPlatformAccounts, entityKey);

                                                        // FETCH ONE
                                                        fetchOneCase(builder, fetchPlatformAccount);

                                                    },
                                                });

export default deploymentEnvironmentsSlice.reducer;
