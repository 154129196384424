import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update,
} from '../services/UsersService';

import {
    deleteOneCase,
    fetchManyWithPaginationCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase,
} from '../../../store/RecordsSliceCaseHelpers';
import {NEXTUser} from "../types.ts";
import {
    colors,
    moduleConfig
} from "../config";

const entityKey = moduleConfig.entityKey;

interface UsersState {
    byId: { [key: string]: NEXTUser },
    ids: string[],
    totalCount: number,
    loading: boolean,
    error: string
}

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
} as UsersState;

const normalizeRecord = (record: NEXTUser) => {


    return {
        ...record,
        id: record.id ? record.id : record.username,
        //id: getUuid(record.id),
        //id: btoa(record.id),
        cognitoUserId: record.id,
        colorCode: record.color && colors[record.color] ? colors[record.color] : '#000000',
    };

};

// genenrates pending, fulfilled and rejected
export const fetchUsers = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    (query: object) => {

        return fetchRecords(query)
            .then((response) => {

                const collection = response?.Items;
                const totalCount = response?.TotalCount

                const normalCollection = collection.map((record: NEXTUser) => {
                    return normalizeRecord(record);
                });

                return {
                    items: normalCollection,
                    totalCount: totalCount
                }
            });
    }
);

export const fetchUser = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {

        // const state = getState()
        // const {record} = recordSelector(state, id)
        //
        // console.debug({record})
        // if (typeof record === 'undefined') {
        //     throw new Error('user record is required parameter')
        // }

        return fetch(id)
            .then((response) => {
                return normalizeRecord(response as any);
            });
    });


export const saveUser = createAsyncThunk(`${entityKey}/add`,
                                         (payload: object) => {
                                             return add(payload)
                                                 .then((response) => response);
                                         });

export const updateUser = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record
     }: { id: string, record: NEXTUser }) => {

        console.log('Update user', id)

        if (typeof record.cognitoUserId === 'undefined') {
            throw new Error('cognitoUserId is required parameter')
        }


        // remove tc property
        const {
            tc,
            ...rest
        } = record;


        return update(record.cognitoUserId, rest)
            .then(() =>
                      normalizeRecord(record)
            );
    }
);

export const deleteUser = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    ({
         id,
         record
     }: { id: string, record: NEXTUser }) => {

        const decodedId = atob(id)
        console.debug('DECODED USER ID', decodedId)

        if (typeof record.cognitoUserId === 'undefined') {
            throw new Error('cognitoUserId is required parameter')
        }

        return deleteRecord(record.cognitoUserId)
            .then(() => id);
    }
);

const applicationsSlice = createSlice({
                                          name: entityKey,
                                          initialState,
                                          reducers: {},
                                          extraReducers: (builder) => {
                                              // FETCH MANY
                                              //fetchManyCase(builder, fetchUsers, entityKey);
                                              fetchManyWithPaginationCase(builder, fetchUsers, entityKey);

                                              // FETCH ONE
                                              fetchOneCase(builder, fetchUser);

                                              // SAVE ONE
                                              saveOneCase(builder, saveUser);

                                              // UPDATE ONE
                                              updateOneCase(builder, updateUser);

                                              // DELETE ONE
                                              deleteOneCase(builder, deleteUser);
                                          },
                                      });

export default applicationsSlice.reducer;
