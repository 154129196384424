export const createPermissionProfile = `
  mutation createPermissionProfile($input: CreatePermissionProfileInput!) {
    createPermissionProfile(input: $input){
      id
      name
      description
      policyStoreId
      deploymentEnvironmentId
      deploymentEnvironment {
        id
        platformAccount {
          accountId
          name
        }
      }
      createdByUsername
      created
      updated
    }
  }  
`;

export const updatePermissionProfile = `
  mutation updatePermissionProfile($input: UpdatePermissionProfileInput!) {
    updatePermissionProfile(input: $input){
      id
      name
      description
      policyStoreId
      deploymentEnvironmentId
      deploymentEnvironment {
        id
        platformAccount {
          accountId
          name
        }
      }
      createdByUsername
      created
      updated
    }
  }
`;

export const deletePermissionProfile = `
  mutation deletePermissionProfile($input: DeletePermissionProfileInput!) {
    deletePermissionProfile(input: $input){
      id
    }
  }  
`;
