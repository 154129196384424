import {Field} from 'formik';

import {I18n} from '@aws-amplify/core';

import {SimpleGrid} from '@mantine/core';

import {
    PacFormikInput,
    PacFormikTextarea
} from '@pac/platform-ui-components';
import {EntityTypesAutocomplete} from "../../entity-types/inputs/EntityTypesAutocomplete.tsx";

export const FormComponent = () => {

    return (
        <SimpleGrid cols={1}>

            <Field
                name='name'
                label='Permission Group Name'
                placeholder={I18n.get('Permission Group Name')}
                component={PacFormikInput}
            />

            <Field
                name='entityTypeId'
                label='Entity Type'
                placeholder={I18n.get('Entity Type')}
                component={EntityTypesAutocomplete}
            />

            <Field
                name='entityIdentifier'
                label='Entity Identifier'
                placeholder={I18n.get('Entity Identifier')}
                component={PacFormikInput}
            />

            <Field
                name='description'
                label='Permission Group Description'
                placeholder={I18n.get('Description')}
                component={PacFormikTextarea}
            />


        </SimpleGrid>
    );
};

