import {
    deleteIdentityProvider,
    fetchIdentityProvider,
    saveIdentityProvider,
    updateIdentityProvider
} from '../reducers/recordsSlice';

import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";
import {IdentityProvider} from "../types.ts";

export const useIdentityProvider = (identityProviderId?: string) => {
    const dispatch = useAppDispatch();

    const {
        record,
        loading
    } = useAppSelector((state) =>
                           recordSelector(state, identityProviderId ? identityProviderId : ''),
    );


    // FETCH
    const handleFetchRecord = () => {

        if (typeof identityProviderId === 'undefined') {
            throw new Error('identityProviderId is required parameter')
        }

        dispatch(fetchIdentityProvider(identityProviderId));
    };


    // CREATE, UPDATE DELETE
    const handleCreateRecord = (values: IdentityProvider) => {
        dispatch(saveIdentityProvider(values));
    };

    const handleUpdateRecord = (values: IdentityProvider) => {

        if (typeof identityProviderId === 'undefined') {
            throw new Error('identityProviderId is required parameter')
        }

        dispatch(updateIdentityProvider({
                                            id: identityProviderId,
                                            record: values
                                        }));
    };

    const handleDeleteRecord = () => {

        if (typeof identityProviderId === 'undefined') {
            throw new Error('identityProviderId is required parameter')
        }

        dispatch(deleteIdentityProvider(identityProviderId));
    };


    return {
        record,
        loading,
        handleFetchRecord,
        handleCreateRecord,
        handleUpdateRecord,
        handleDeleteRecord,
    };
};
