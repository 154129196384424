import {FunctionComponent} from 'react';

import {
    AppShell,
    Box,
    Button,
    Center,
    Stack,
    Title
} from '@mantine/core';

import {Navigate} from 'react-router-dom';


import {PlatformFooter} from '@pac/platform-ui-components';


import {HeroSection} from '../components/HeroSection';
import {useAuth} from "../../../hooks/useAuth.ts";
import {signInWithRedirect} from 'aws-amplify/auth';


export const PublicHomePage: FunctionComponent = () => {

    const {user} = useAuth()
    if (user) {
        return <Navigate to={'/permission-profiles'}/>
    }

    return (
        <AppShell padding='md' footer={{height: 95}}>

            <AppShell.Main>
                <Stack gap='xl'>
                    <Center>
                        <HeroSection/>
                    </Center>


                    <Center>
                        <Box mt='xl'>

                            <Button onClick={() => {
                                signInWithRedirect()
                            }}><Title order={6}>Sign In</Title></Button>

                        </Box>
                    </Center>
                </Stack>
            </AppShell.Main>


            <AppShell.Footer py='xs'>
                <PlatformFooter/>
            </AppShell.Footer>

        </AppShell>
    );

};
