import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update
} from '../../actions/services/ActionsService';

import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase
} from '../../../store/RecordsSliceCaseHelpers';
import {NEXTAction} from "../types.ts";
import {moduleConfig} from "../config";

const entityKey = moduleConfig.entityKey;

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
};

const normalizeRecord = (permissionProfileId: string, record: NEXTAction) => {
    return Object.assign({}, record, {permissionProfileId: permissionProfileId});
};


// genenrates pending, fulfilled and rejected
export const fetchActions = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    (permissionProfileId: string) => {

        return fetchRecords(permissionProfileId)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return response.map((record) => {
                        return normalizeRecord(permissionProfileId, record)
                    });
                }
            });
    },
);

export const fetchAction = createAsyncThunk(
    `${entityKey}/fetch`,
    ({
         permissionProfileId,
         id
     }: { permissionProfileId: string, id: string }) => {
        return fetch(id)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return normalizeRecord(permissionProfileId, response)
                }
            });
    });


export const saveAction = createAsyncThunk(
    `${entityKey}/add`,
    ({
         permissionProfileId,
         payload
     }: {
        permissionProfileId: string,
        payload: NEXTAction
    }) => {

        const updated: NEXTAction = {
            ...payload,
            permissionProfileId: permissionProfileId,
        }

        return add(updated)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return normalizeRecord(permissionProfileId, response)
                }
            });
    });

export const updateAction = createAsyncThunk(
    `${entityKey}/update`,
    ({
         permissionProfileId,
         id,
         record
     }: { permissionProfileId: string, id: string, record: NEXTAction }) => {

        const updated: NEXTAction = {
            ...record,
            permissionProfileId: permissionProfileId,
        }

        return update(id, updated)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return normalizeRecord(permissionProfileId, response)
                }
            });
    },
);

export const deleteAction = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    },
);

const actionsSlice = createSlice({
                                     name: entityKey,
                                     initialState,
                                     reducers: {},
                                     extraReducers: (builder) => {
                                         // FETCH MANY
                                         fetchManyCase(builder, fetchActions, entityKey);

                                         // FETCH ONE
                                         fetchOneCase(builder, fetchAction);

                                         // SAVE ONE
                                         saveOneCase(builder, saveAction);

                                         // UPDATE ONE
                                         updateOneCase(builder, updateAction);

                                         // DELETE ONE
                                         deleteOneCase(builder, deleteAction);
                                     },
                                 });

export default actionsSlice.reducer;
