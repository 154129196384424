export const listPermissionGroups = `
query listPermissionGroups($filter: PermissionGroupsFilterInput) {
  listPermissionGroups(filter: $filter) {
    items {
      id
      name
      description
      permissionProfileId
      permissionProfile {
        id
        name
      }
      entityTypeId
      entityType {
        id
        name
      }
      entityIdentifier
      createdByUsername
      managedByUsername
      created
      updated
    }
  }
}
`

export const getPermissionGroup = `
  query getPermissionGroup($id: ID!) {
    getPermissionGroup(id: $id){
      id
      name
      description
      permissionProfileId
      permissionProfile {
        id
        name
      }
      entityTypeId
      entityIdentifier
      createdByUsername
      managedByUsername
      created
      updated
    }
  }  
`;