import {FunctionComponent} from 'react';

import {
    Navigate,
    Route,
    Routes
} from 'react-router-dom';

import {IndexPage} from './pages/IndexPage';
import {AddPage} from './pages/AddPage';
import {EditPage} from './pages/EditPage';
import {IndexPage as PoliciesPage} from "../policies/pages/IndexPage.tsx";
import {EditPage as PolicyPage} from "../policies/pages/EditPage.tsx";
import {AddPage as AddPolicyPage} from "../policies/pages/AddPage.tsx";
import {EditPage as PolicyTemplatePage} from "../policy-templates/pages/EditPage.tsx";
import {AddPage as AddPolicyTemplatePage} from "../policy-templates/pages/AddPage.tsx";
import {EditPage as ActionPage} from "../actions/pages/EditPage.tsx";
import {AddPage as AddActionPage} from "../actions/pages/AddPage.tsx";
import {EditPage as EntityTypePage} from "../entity-types/pages/EditPage.tsx";
import {AddPage as AddEntityTypePage} from "../entity-types/pages/AddPage.tsx";
import {EditPage as IdentitySourcePage} from "../identity-sources/pages/EditPage.tsx";
import {AddPage as AddIdentitySourcePage} from "../identity-sources/pages/AddPage.tsx";
import {EditPage as PermissionGroupPage} from "../permission-groups/pages/EditPage.tsx";
import {AddPage as AddPermissionGroupPage} from "../permission-groups/pages/AddPage.tsx";
import {EditPage as AuthorizationRequestPage} from "../authorization-requests/pages/EditPage.tsx";
import {AddPage as AddAuthorizationRequestPage} from "../authorization-requests/pages/AddPage.tsx";
import {useVerifiedPermissions} from "../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../config/rbac/actions.ts";

export const PermissionProfilesLayout: FunctionComponent = () => {

    const {
        allowedActions
    } = useVerifiedPermissions()

    return (
        <Routes>
            <Route
                path="/"
                element={
                    (allowedActions.includes(rbacActions.ListPermissionProfiles) ? (
                        <IndexPage/>
                    ) : (
                        <Navigate to="/"/>
                    ))
                }
            />
            <Route
                path="add"
                element={
                    (allowedActions.includes(rbacActions.CreatePermissionProfile) ? (
                        <AddPage/>
                    ) : (
                        <Navigate to="/permission-profiles"/>
                    ))
                }
            />
            <Route
                path=":id"
                element={
                    (allowedActions.includes(rbacActions.GetPermissionProfile) ? (
                        <EditPage/>
                    ) : (
                        <Navigate to="/permission-profiles"/>
                    ))
                }
            />


            <Route
                path=":id/policies"
                element={
                    (allowedActions.includes(rbacActions.ListPolicies) ? (
                        <PoliciesPage/>
                    ) : (
                        <Navigate to="/policy-stores/:id"/>
                    ))
                }
            />

            <Route
                path=":id/policies/:policyId"
                element={
                    (allowedActions.includes(rbacActions.GetPolicy) ? (
                        <PolicyPage/>
                    ) : (
                        <Navigate to="/policy-stores/:id"/>
                    ))
                }
            />

            <Route
                path=":id/policies/add"
                element={
                    (allowedActions.includes(rbacActions.CreatePolicy) ? (
                        <AddPolicyPage/>
                    ) : (
                        <Navigate to="/policy-stores/:id"/>
                    ))
                }
            />


            <Route
                path=":id/policy-templates/:policyTemplateId"
                element={
                    (allowedActions.includes(rbacActions.GetPolicyTemplate) ? (
                        <PolicyTemplatePage/>
                    ) : (
                        <Navigate to="/policy-stores/:id"/>
                    ))
                }
            />

            <Route
                path=":id/policy-templates/add"
                element={
                    (allowedActions.includes(rbacActions.CreatePolicyTemplate) ? (
                        <AddPolicyTemplatePage/>
                    ) : (
                        <Navigate to="/policy-stores/:id"/>
                    ))
                }
            />

            <Route
                path=":id/actions/:actionId"
                element={
                    (allowedActions.includes(rbacActions.GetAction) ? (
                        <ActionPage/>
                    ) : (
                        <Navigate to="/policy-stores/:id"/>
                    ))
                }
            />


            <Route
                path=":id/actions/add"
                element={
                    (allowedActions.includes(rbacActions.CreateAction) ? (
                        <AddActionPage/>
                    ) : (
                        <Navigate to="/policy-stores/:id"/>
                    ))
                }
            />


            <Route
                path=":id/entity-types/:entityTypeId"
                element={
                    (allowedActions.includes(rbacActions.GetEntityType) ? (
                        <EntityTypePage/>
                    ) : (
                        <Navigate to="/policy-stores/:id"/>
                    ))
                }
            />

            <Route
                path=":id/entity-types/add"
                element={
                    (allowedActions.includes(rbacActions.CreateEntityType) ? (
                        <AddEntityTypePage/>
                    ) : (
                        <Navigate to="/policy-stores/:id"/>
                    ))
                }
            />


            <Route
                path=":id/identity-sources/:identitySourceId"
                element={
                    (allowedActions.includes(rbacActions.GetIdentitySource) ? (
                        <IdentitySourcePage/>
                    ) : (
                        <Navigate to="/policy-stores/:id"/>
                    ))
                }
            />

            <Route
                path=":id/identity-sources/add"
                element={
                    (allowedActions.includes(rbacActions.CreateIdentitySource) ? (
                        <AddIdentitySourcePage/>
                    ) : (
                        <Navigate to="/policy-stores/:id"/>
                    ))
                }
            />

            <Route
                path=":id/permission-groups/:permissionGroupId"
                element={
                    (allowedActions.includes(rbacActions.GetPermissionGroup) ? (
                        <PermissionGroupPage/>
                    ) : (
                        <Navigate to="/permission-profiles/:id"/>
                    ))
                }
            />

            <Route
                path=":id/permission-groups/add"
                element={
                    (allowedActions.includes(rbacActions.CreatePermissionGroup) ? (
                        <AddPermissionGroupPage/>
                    ) : (
                        <Navigate to="/policy-stores/:id"/>
                    ))
                }
            />


            <Route
                path=":id/authorization-requests/:authorizationRequestId"
                element={
                    (allowedActions.includes(rbacActions.GetEntityType) ? (
                        <AuthorizationRequestPage/>
                    ) : (
                        <Navigate to="/policy-stores/:id"/>
                    ))
                }
            />

            <Route
                path=":id/authorization-requests/add"
                element={
                    (allowedActions.includes(rbacActions.CreateEntityType) ? (
                        <AddAuthorizationRequestPage/>
                    ) : (
                        <Navigate to="/policy-stores/:id"/>
                    ))
                }
            />


        </Routes>
    );
};
