import {Field} from 'formik';

import {I18n} from '@aws-amplify/core';

import {
    Group,
    SimpleGrid,
    Text
} from '@mantine/core';

import {
    PacFormikInput,
    PacFormikRadioInput,
    PacFormikTextarea
} from '@pac/platform-ui-components';
import {PolicyTemplate} from "../types.ts";
import {ActionNamesMultiSelect} from "../../actions/inputs/ActionNamesMultiSelect.tsx";


const inputMethodOptions = [
    {
        label: 'Permit Action',
        value: 'permit',
    },
    {
        label: 'Forbid Action',
        value: 'forbid',
    },
];

export const FormComponent = ({values}: { values: PolicyTemplate }) => {

    const editing =
        typeof values !== 'undefined' &&
        Object.prototype.hasOwnProperty.call(values, 'policyId') &&
        typeof values.policyTemplateId !== 'undefined' &&
        values.policyTemplateId !== '' && values.policyTemplateId !== null
            ? true
            : false;


    return (
        <SimpleGrid cols={1}>

            <Text fw={600}>Policy Action</Text>
            <Group>
                {inputMethodOptions.map((option, index) => (
                    <Field
                        key={index}
                        name="policyAction"
                        value={option.value}
                        type="radio"
                        label={option.label}
                        component={PacFormikRadioInput}
                    />
                ))}
            </Group>


            <SimpleGrid cols={2}>
                {editing ? <Field
                    name='policyId'
                    label={I18n.get('Policy ID')}
                    description={I18n.get('Policy Id')}
                    component={PacFormikInput}
                    disabled={true}
                    withAsterisk
                /> : null}

                {editing ? <Field
                    name='permissionProfileId'
                    label={I18n.get('Permission Profile ID')}
                    description={I18n.get('Permission Profile ID')}
                    component={PacFormikInput}
                    disabled={true}
                    withAsterisk
                /> : null}
            </SimpleGrid>

            <Text fw={600}>Description</Text>
            <Field
                name='description'
                placeholder={I18n.get('Policy Description')}
                component={PacFormikTextarea}
            />

            <Field
                name='actions'
                label='Actions'
                description='Select policy template actions'
                placeholder={I18n.get('Actions')}
                component={ActionNamesMultiSelect}
            />


        </SimpleGrid>
    );


};
