import {RecordsTable} from '../tables/RecordsTable';

import {SimplePageLayout} from '@pac/platform-ui-components';

import {IndexPageHeader} from '../components/IndexPageHeader';

export const IndexPage = () => {
    return (
        <SimplePageLayout
            pageHeader={<IndexPageHeader/>}
        >
            <RecordsTable/>
        </SimplePageLayout>
    );
};
