export const listPolicies = `
query listPolicies($filter: PoliciesFilterInput!, $limit: Int, $nextToken: String) {
  listPolicies(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      policyId
      policyStoreId
      policyType
      principal {
        entityId
        entityType
      }
      resource {
        entityId
        entityType
      }
      definition {
        static {
          description
        }
      }
      createdDate
      lastUpdatedDate
    }
  }
}
`

export const getPolicy = `
  query getPolicy($permissionProfileId: ID!, $policyId: ID!) {
    getPolicy(permissionProfileId: $permissionProfileId, policyId: $policyId){
      policyId
      policyStoreId
      policyType
      principal {
        entityId
        entityType
      }
      resource {
        entityId
        entityType
      }
      definition {
        static {
          statement
          description
        }
      }
      createdDate
      lastUpdatedDate
    }
  }  
`;