import {NavLink} from "react-router-dom";
import {PermissionProfile} from "../types.ts";
import {PlatformPropertiesCard} from "@pac/platform-ui-components";
import {Stack} from "@mantine/core";


export const Profile = ({record}: { record: PermissionProfile }) => {

    const properties = [
        {
            label: 'Profile Name',
            value: record.name,
            span: 12
        },
        {
            label: 'Policy Store ID',
            value: <NavLink to={`/policy-stores/${record.policyStoreId}`}>{record.policyStoreId}</NavLink>,
            span: 12
        },
        {
            label: 'Description',
            value: record.description,
            span: 12
        },
    ];

    const properties2 = [
        {
            label: 'Created By',
            value: record.createdByUsername,
            span: 12
        }, {
            label: 'Created',
            value: record.created ? record.created : 'n/a',
            formatAs: 'Timestamp',
            span: 12
        },
        {
            label: 'Managed By',
            value: record.managedByUsername,
            span: 12
        },
        {
            label: 'Last Updated',
            value: record.updated ? record.updated : 'n/a',
            formatAs: 'Timestamp',
            span: 12
        },
    ];

    return (
        <Stack gap={'xl'}>
            <PlatformPropertiesCard cols={2} properties={properties}/>
            <PlatformPropertiesCard cols={2} properties={properties2}/>
        </Stack>
    );


};
