export const createAction = `
  mutation createAction($input: CreateActionInput!) {
    createAction(input: $input){
      id
      name
      permissionProfileId
      principalTypeIds
      resourceTypeIds
      createdByUsername
      managedByUsername
      created
      updated
    }
  }  
`;

export const updateAction = `
  mutation updateAction($input: UpdateActionInput!) {
    updateAction(input: $input){
      id
      name
      permissionProfileId
      principalTypeIds
      resourceTypeIds
      createdByUsername
      managedByUsername
      created
      updated
    }
  }
`;

export const deleteAction = `
  mutation deleteAction($input: DeleteActionInput!) {
    deleteAction(input: $input){
      id
    }
  }  
`;
