import {
    getPolicy,
    listPolicies
} from './queries';

import {
    createPolicy,
    deletePolicy,
    updatePolicy
} from './mutations';

import {
    CreatePolicy,
    DeletePolicy,
    GetPolicy,
    ListPolicies,
    NEXTPolicyDefinition,
    UpdatePolicy
} from "../types.ts";
import {
    notifyError,
    notifySuccess
} from "../../../components/ServiceResponseNotifications.tsx";
import {
    generateClient,
    GraphQLQuery
} from "@aws-amplify/api";

const client = generateClient();


export const fetchRecords = async (permissionProfileId: string, filter: object = {}, maxResults = 200, nextToken = '') => {

    let variables = {
        MaxResults: maxResults,
    };

    if (filter) {
        filter = Object.assign({}, filter, {permissionProfileId: permissionProfileId})
    } else {
        filter = {permissionProfileId: permissionProfileId}
    }


    if (filter) {
        variables = Object.assign({}, variables, {filter: filter})
    }
    if (nextToken.length > 0) {
        variables = Object.assign({}, variables, {NextToken: nextToken})
    }


    try {
        const response = await client.graphql<GraphQLQuery<ListPolicies>>({
                                                                              query: listPolicies,
                                                                              variables: variables
                                                                          });

        return response.data?.listPolicies.items;
    } catch (e: any) {
        notifyError(e);
    }
};

export const fetch = async (permissionProfileId: string, id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetPolicy>>({
                                                                           query: getPolicy,
                                                                           variables: {
                                                                               permissionProfileId: permissionProfileId,
                                                                               policyId: id
                                                                           },
                                                                       });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            return response.data.getPolicy;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }

    } catch (e: any) {
        notifyError(e);
    }
};

export const add = async (permissionProfileId: string, definition: NEXTPolicyDefinition) => {
    const variables = Object.assign({}, {
        input: {
            permissionProfileId: permissionProfileId,
            definition: definition
        }
    });

    try {
        const response = await client.graphql<GraphQLQuery<CreatePolicy>>(
            {
                query: createPolicy,
                variables: variables,
            });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Policy created');
            return response.data.createPolicy;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e)
    }
};

export const update = async (permissionProfileId: string, id: string, definition: NEXTPolicyDefinition) => {

    const variables = Object.assign({}, {
        input: {
            permissionProfileId: permissionProfileId,
            policyId: id,
            definition: definition
        }
    });

    try {
        const response = await client.graphql<GraphQLQuery<UpdatePolicy>>({
                                                                              query: updatePolicy,
                                                                              variables: variables,
                                                                          });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Policy updated');
            return response.data.updatePolicy;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e);
    }
};

export const deleteRecord = async (permissionProfileId: string, id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeletePolicy>>(
            {
                query: deletePolicy,
                variables: {
                    input: {
                        permissionProfileId: permissionProfileId,
                        policyId: id
                    }
                },
            });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Policy deleted');
            return response.data.deletePolicy;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e);
    }
};
