export const listPermissionProfiles = `
query listPermissionProfiles($filter: PermissionProfilesFilterInput) {
  listPermissionProfiles(filter: $filter) {
    items {
      id
      name
      description
      policyStoreId
      deploymentEnvironmentId
      createdByUsername
      created
      updated
    }
  }
}
`

export const getPermissionProfile = `
  query getPermissionProfile($id: ID!) {
    getPermissionProfile(id: $id){
      id
      name
      description
      policyStoreId
      deploymentEnvironmentId
      deploymentEnvironment {
        id
        platformAccount {
          accountId
          name
        }
      }
      createdByUsername
      created
      updated
    }
  }  
`;