export const listIdentitySources = `
query listIdentitySources($filter: IdentitySourcesFilterInput!, $limit: Int, $nextToken: String) {
  listIdentitySources(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      identitySourceId
      permissionProfileId
      providerType
      userPoolArn
      clientIds
      groupEntityType
      principalEntityType
      created
      updated
    }
  }
}
`

export const getIdentitySource = `
  query getIdentitySource($permissionProfileId: ID!, $identitySourceId: ID!) {
    getIdentitySource(permissionProfileId: $permissionProfileId, identitySourceId: $identitySourceId){
      identitySourceId
      permissionProfileId
      providerType
      userPoolArn
      clientIds
      groupEntityType
      principalEntityType
      created
      updated
    }
  }  
`;