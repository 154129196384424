const translationPairs = {
  Home: "ホーム",
  Customers: "エアライン",
  Applications: "アプリケーション",
  Analytics: "アナリティクス",
  Users: "ユーザー",
  Templates: "テンプレート",
  "API Management": "API管理",
  "Email Management": "Eメール管理",
  Identities: "ID",
  "Platform Management": "プラットホーム管理",
  "Audit Log": "ログ",
};

export default translationPairs;
