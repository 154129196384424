import React from 'react'
import moment from 'moment';


export const formatTimestamp = (ts?: string) => (
    <React.Fragment>
        {moment(ts)
            .format('MMM DD, YYYY HH:mm:ss')}
    </React.Fragment>
);
