import React from 'react'

import {NavLink} from 'react-router-dom';

import {
    Box,
    Breadcrumbs
} from '@mantine/core';
import {BreadcrumbElement,} from "../../../types";
import {NEXTPolicyStore} from "../types.ts";

const renderBreadcrumbs = (elements: BreadcrumbElement[]) => {

    const breadcrumbs = elements.map((item, index) => (
        <React.Fragment key={index}>{item.href ?
            <NavLink to={item.href}>{item.title}</NavLink> : item.title}</React.Fragment>
    ));
    return (
        <Box p={'xs'}><Breadcrumbs separator='→' mt='xs'>{breadcrumbs}</Breadcrumbs></Box>
    );

}

const rootPathElements = (): BreadcrumbElement[] => {

    return [
        {
            title: 'Home',
            href: '/dashboard'
        },
        {
            title: 'Policy Stores',
            href: `/policy-stores`
        },
    ];

}

export const AddPageNavigation = () => {
    const elements = rootPathElements()
        .concat([{title: 'Add Store'}]);
    return renderBreadcrumbs(elements)
};


export const EditPageNavigation = ({record}: { record: NEXTPolicyStore }) => {
    const elements = rootPathElements()
        .concat([{
            title: record.policyStoreId,
            href: `/policy-stores/${record.policyStoreId}`
        }]);
    return renderBreadcrumbs(elements)
};
