import {
    deleteDeploymentEnvironment,
    fetchDeploymentEnvironment,
    saveDeploymentEnvironment,
    updateDeploymentEnvironment
} from '../reducers/recordsSlice';

import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";
import {DeploymentEnvironment} from "../types.ts";

export const useDeploymentEnvironment = (deploymentEnvironmentId?: string) => {
    const dispatch = useAppDispatch();

    const {
        record,
        loading
    } = useAppSelector((state) =>
                           recordSelector(state, deploymentEnvironmentId ? deploymentEnvironmentId : ''),
    );


    // FETCH
    const handleFetchRecord = () => {

        if (typeof deploymentEnvironmentId === 'undefined') {
            throw new Error('deploymentEnvironmentId is required parameter')
        }

        dispatch(fetchDeploymentEnvironment(deploymentEnvironmentId));
    };


    // CREATE, UPDATE DELETE
    const handleCreateRecord = (values: DeploymentEnvironment) => {
        dispatch(saveDeploymentEnvironment(values));
    };

    const handleUpdateRecord = (values: DeploymentEnvironment) => {

        if (typeof deploymentEnvironmentId === 'undefined') {
            throw new Error('deploymentEnvironmentId is required parameter')
        }

        dispatch(updateDeploymentEnvironment({
                                                 id: deploymentEnvironmentId,
                                                 record: values
                                             }));
    };

    const handleDeleteRecord = () => {

        if (typeof deploymentEnvironmentId === 'undefined') {
            throw new Error('deploymentEnvironmentId is required parameter')
        }

        dispatch(deleteDeploymentEnvironment(deploymentEnvironmentId));
    };


    return {
        record,
        loading,
        handleFetchRecord,
        handleCreateRecord,
        handleUpdateRecord,
        handleDeleteRecord,
    };
};
