import {ReactNode} from "react";
import {ITableColumn} from "../../types/tables";
import {User} from "../users/types.ts";

export enum OIDCProviderType {
    AmazonCognito = 'AmazonCognito',
    ExternalProvider = 'ExternalProvider'
}

export type IdentityProvider = {
    id: string
    name: string
    providerType: OIDCProviderType
    description: string
    createdByUsername: string
    createdBy: User
    managedByUsername: string
    managedBy: User
    created?: string
    updated?: string
}

export interface IIdentityProviderTableColumn extends ITableColumn {
    render?: (item: IdentityProvider) => ReactNode
}


export type ListIdentityProviders = {
    listIdentityProviders: { items: IdentityProvider[] }
}

export type GetIdentityProvider = {
    getIdentityProvider: IdentityProvider
}

export type CreateIdentityProvider = {
    createIdentityProvider: IdentityProvider
}

export type UpdateIdentityProvider = {
    updateIdentityProvider: IdentityProvider
}

export type DeleteIdentityProvider = {
    deleteIdentityProvider: string
}