import {Field} from 'formik';

import {I18n} from '@aws-amplify/core';

import {SimpleGrid} from '@mantine/core';

import {PacFormikInput} from '@pac/platform-ui-components';
import {EntityTypesMultiSelect} from "../../../entity-types/inputs/EntityTypesMultiSelect";

export const FormComponent = () => {


    return (
        <SimpleGrid cols={1}>
            <Field
                name='name'
                label={I18n.get('Action Name')}
                description={I18n.get('Use double colon to prepend action namespace i.e. Portal::CreateApplication')}
                component={PacFormikInput}
                withAsterisk={true}
            />

            <Field
                name='principalTypeIds'
                label={I18n.get('Principal Types')}
                description={I18n.get('Principal Types allowed')}
                placeholder={I18n.get('Principal Types')}
                component={EntityTypesMultiSelect}
            />


            <Field
                name='resourceTypeIds'
                label={I18n.get('Resource Types')}
                description={I18n.get('Resource Types allowed')}
                placeholder={I18n.get('Resource Types')}
                component={EntityTypesMultiSelect}
            />

        </SimpleGrid>
    );


};

