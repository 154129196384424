import {
    useEffect,
    useState
} from 'react';

import {Autocomplete} from '@mantine/core';

import {FieldProps} from 'formik'
import {useUsers} from "../hooks/useUsers";
import {NEXTUser} from "../types.ts";

const getKey = (user: NEXTUser) => [user["first-name"], user["last-name"]].join(' ') + ' [ ' + user["email-address"] + ' ]'


export const UsersAutocomplete = ({
                                      field: {
                                          name,
                                          value: fieldValue
                                      },
                                      form: {setFieldValue},
                                      ...rest
                                  }: FieldProps) => {


    const {
        items: records,
        handleFetchRecords
    } = useUsers()


    const [value, setValue] = useState('');
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        handleFetchRecords({});
    }, []);

    const data = records.map((record: NEXTUser) => {
        return {
            ...record,
            value: getKey(record),
        };
    });

    useEffect(() => {
        if (records.length === 0) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
        const found = records.find((item: NEXTUser) => item.id === fieldValue);

        if (typeof found !== 'undefined' && Object.prototype.hasOwnProperty.call(found, 'first-name') && Object.prototype.hasOwnProperty.call(found, 'last-name')) {
            setValue(getKey(found));
        }
    }, [records.length]);

    const handleChange = (value: string) => {
        if (!Array.isArray(data)) {
            throw new Error('Data is expected to be an array');
        }

        handleFetchRecords({search: value});

        const {id} = data.find((item) => getKey(item) === value) || {};

        if (typeof id !== 'undefined') {
            setFieldValue(name, id);
        }

        setValue(value);
    };

    return (
        <Autocomplete
            name={name}
            value={value}
            onChange={handleChange}
            data={data}
            disabled={disabled}
            placeholder="Select User"
            {...rest}
        />
    );
};
