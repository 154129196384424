import {
    getPermissionProfile,
    listPermissionProfiles
} from './queries';

import {
    createPermissionProfile,
    deletePermissionProfile,
    updatePermissionProfile
} from './mutations';

import {
    CreatePermissionProfile,
    DeletePermissionProfile,
    GetPermissionProfile,
    ListPermissionProfiles,
    PermissionProfile,
    UpdatePermissionProfile
} from "../types.ts";
import {
    notifyError,
    notifySuccess
} from "../../../components/ServiceResponseNotifications.tsx";
import {
    generateClient,
    GraphQLQuery
} from "@aws-amplify/api";

const client = generateClient();

export const fetchRecords = async (query: { [k: string]: any }) => {

    let filter = {}
    if (query.filter) {
        filter = query.filter
    }

    try {
        const response = await client.graphql<GraphQLQuery<ListPermissionProfiles>>({
                                                                                        query: listPermissionProfiles,
                                                                                        variables: {
                                                                                            filter: filter,
                                                                                        }
                                                                                    });

        return response.data?.listPermissionProfiles.items;
    } catch (e: any) {
        notifyError(e);
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetPermissionProfile>>({
                                                                                      query: getPermissionProfile,
                                                                                      variables: {
                                                                                          id: id
                                                                                      },
                                                                                  });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            return response.data.getPermissionProfile;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }

    } catch (e: any) {
        notifyError(e);
    }
};

export const add = async (payload: PermissionProfile) => {

    const input = {
        name: payload.name,
        description: payload.description,
    } as { [k: string]: any };

    if (typeof payload.deploymentEnvironmentId !== 'undefined') {
        input.deploymentEnvironmentId = payload.deploymentEnvironmentId;
    }

    if (typeof payload.policyStoreId !== 'undefined') {
        input.policyStoreId = payload.policyStoreId;
    }

    const variables = Object.assign({}, {
        input: input
    });

    try {
        const response = await client.graphql<GraphQLQuery<CreatePermissionProfile>>(
            {
                query: createPermissionProfile,
                variables: variables,
            });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Permission Profile created');
            return response.data.createPermissionProfile;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }

    } catch (e: any) {
        notifyError(e);
    }
};

export const update = async (id: string, record: PermissionProfile) => {

    console.debug('IN UPDATE ENTITY TYPE SERVICE id ', id)

    const variables = Object.assign({}, {
        input: {
            id: record.id,
            name: record.name,
            description: record.description,
            deploymentEnvironmentId: record.deploymentEnvironmentId,
            policyStoreId: record.policyStoreId,
        }
    });

    try {
        const response = await client.graphql<GraphQLQuery<UpdatePermissionProfile>>(
            {
                query: updatePermissionProfile,
                variables: variables,
            });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Permission Profile updated');
            return response.data.updatePermissionProfile;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e);
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeletePermissionProfile>>(
            {
                query: deletePermissionProfile,
                variables: {
                    input: {
                        id: id
                    }
                },
            });


        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Permission Profile deleted');
            return response.data.deletePermissionProfile;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e);
    }
};
