import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';
import {usePolicyTemplate} from "../hooks/usePolicyTemplate";
import {AddPageNavigation} from "../navigation/EditPageNavigation.tsx";
import {useParams} from 'react-router-dom'

export const AddPage = () => {
    const {id: permissionProfileId} = useParams();

    if (typeof permissionProfileId === 'undefined') {
        throw new Error('permissionProfileId is required parameter')
    }

    const {
        record,
        handleCreateRecord
    } = usePolicyTemplate(permissionProfileId);

    console.debug(record)

    return (
        <FormPageLayout
            title='Add Policy Template'
            pageNavigation={<AddPageNavigation/>}
        >
            <SimplePageContentWrapper><RecordForm record={record}
                                                  handleSubmit={handleCreateRecord}/></SimplePageContentWrapper>
        </FormPageLayout>
    );
};
