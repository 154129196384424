import React from 'react'

import {
    NavLink,
    useParams
} from 'react-router-dom';

import {
    Box,
    Breadcrumbs
} from '@mantine/core';
import {BreadcrumbElement,} from "../../../types";
import {PermissionGroup} from "../types.ts";
import {usePermissionProfileBreadcrumbElements} from "../../permission-profiles/hooks/usePermissionProfileBreadcrumbElements.ts";

const renderBreadcrumbs = (elements: BreadcrumbElement[]) => {

    const breadcrumbs = elements.map((item, index) => (
        <React.Fragment key={index}>{item.href ?
            <NavLink to={item.href}>{item.title}</NavLink> : item.title}</React.Fragment>
    ));
    return (
        <Box p={'xs'}><Breadcrumbs separator='→' mt='xs'>{breadcrumbs}</Breadcrumbs></Box>
    );

}


export const AddPageNavigation = () => {

    const {id: permissionProfileId} = useParams()

    if (!permissionProfileId) {
        throw new Error('permissionProfileId is required parameter')
    }

    const {elements: rootPathElements} = usePermissionProfileBreadcrumbElements(permissionProfileId)
    console.debug(rootPathElements)

    const elements = rootPathElements.concat([
                                                 {title: 'Add Permission Group'}
                                             ]);
    return renderBreadcrumbs(elements)
};

export const EditPageNavigation = ({record}: { record: PermissionGroup }) => {

    const {elements: rootPathElements} = usePermissionProfileBreadcrumbElements(record.permissionProfileId)

    const elements = rootPathElements.concat([
                                                 {
                                                     title: 'Permission Group',
                                                 },
                                                 {title: record.name}
                                             ]);
    return renderBreadcrumbs(elements)
};
