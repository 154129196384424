import React from 'react'

import {
    NavLink,
    useParams
} from 'react-router-dom';

import {
    Box,
    Breadcrumbs
} from '@mantine/core';
import {BreadcrumbElement,} from "../../../types";
import {IdentitySource} from "../types.ts";
import {usePermissionProfileBreadcrumbElements} from "../../permission-profiles/hooks/usePermissionProfileBreadcrumbElements.ts";

const renderBreadcrumbs = (elements: BreadcrumbElement[]) => {

    const breadcrumbs = elements.map((item, index) => (
        <React.Fragment key={index}>{item.href ?
            <NavLink to={item.href}>{item.title}</NavLink> : item.title}</React.Fragment>
    ));
    return (
        <Box p={'xs'}><Breadcrumbs separator='→' mt='xs'>{breadcrumbs}</Breadcrumbs></Box>
    );

}

export const AddPageNavigation = () => {
    const {id: permissionProfileId} = useParams();

    if (typeof permissionProfileId === 'undefined') {
        throw new Error('permissionProfileId is required parameter')
    }

    const {elements: rootPathElements} = usePermissionProfileBreadcrumbElements(permissionProfileId)

    const elements: BreadcrumbElement[] = rootPathElements.concat([
                                                                      {title: 'IdentitySources'},
                                                                      {title: 'Add IdentitySource'}
                                                                  ]);
    return renderBreadcrumbs(elements)
};

export const EditPageNavigation = ({record}: { record: IdentitySource }) => {

    const {elements: rootPathElements} = usePermissionProfileBreadcrumbElements(record.permissionProfileId)

    const elements = rootPathElements.concat([
                                                 {title: 'IdentitySources'},
                                                 {title: record.identitySourceId}
                                             ]);
    return renderBreadcrumbs(elements)
};
