import {stage} from '../stage';


type ConfigShape = {
    [key: string]: {
        endpoint: string,
        region: string,
        apiKey?: string,
    }
}

const tenantManagementServiceAPI: ConfigShape = {
    prd: {
        endpoint: 'https://uhdg0ahs3d.execute-api.us-west-2.amazonaws.com/dev',
        region: 'us-west-2',
        apiKey: 'pac-idam-permissions-prd',
    },
    test: {
        endpoint: 'https://hh3iqenpr2.execute-api.us-west-2.amazonaws.com/dev',
        region: 'us-west-2',
        apiKey: 'pac-idam-permissions-test',
    },
    dev: {
        endpoint: 'https://e4f81ajvp6.execute-api.us-west-2.amazonaws.com/dev',
        region: 'us-west-2',
        apiKey: '9kkbAajccQ9t1JkgmKE2x6RL3JH4MS5gS4LN6fQg',
    },
    ide: {
        endpoint: 'https://e4f81ajvp6.execute-api.us-west-2.amazonaws.com/dev',
        region: 'us-west-2',
        apiKey: '9kkbAajccQ9t1JkgmKE2x6RL3JH4MS5gS4LN6fQg',
    },
};


const RBACManagementAPI: ConfigShape = {
    prd: {
        endpoint: 'https://tzamz0hfx1.execute-api.us-west-2.amazonaws.com/v1',
        region: 'us-west-2',
    },
    test: {
        endpoint: 'https://4um1qu1n3l.execute-api.us-west-2.amazonaws.com/v1',
        region: 'us-west-2',
    },
    dev: {
        endpoint: 'https://7jxnqmqf40.execute-api.us-west-2.amazonaws.com/v1',
        region: 'us-west-2',
    },
    ide: {
        endpoint: 'https://7jxnqmqf40.execute-api.us-west-2.amazonaws.com/v1',
        region: 'us-west-2',
    },
};


export const endpoints = {
    TenantManagementServiceAPI: {
        endpoint: tenantManagementServiceAPI[stage].endpoint,
        region: tenantManagementServiceAPI[stage].region,
    },
    RBACManagementAPI: {
        endpoint: RBACManagementAPI[stage].endpoint,
        region: RBACManagementAPI[stage].region,
    },
};

export const config = endpoints
