import {stage} from './stage';

type ConfigShape = {
    [key: string]: {
        name: string,
        host: string,
        latestVersion: number
    }
}

const config: ConfigShape = {
    prd: {
        name: 'NEXT Cloud Console',
        host: 'https://console.nextcloud.aero',
        latestVersion: 4,
    },
    test: {
        name: 'NEXT Cloud Console',
        host: 'https://console-test.nextcloud.aero',
        latestVersion: 4,
    },
    dev: {
        name: 'NEXT Cloud Console',
        host: 'https://console-dev.nextcloud.aero',
        latestVersion: 4,
    },
    ide: {
        name: 'NEXT Cloud Permissions',
        host: import.meta.env.VITE_HOST,
        latestVersion: 1,
    },
};

export default config[stage];
