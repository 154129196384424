export const listPlatformAccounts = `
query listPlatformAccounts($filter: PlatformAccountsFilterInput!, $limit: Int, $nextToken: String) {
  listPlatformAccounts(filter: $filter, limit: $limit, nextToken:$nextToken) {
    items {
      accountId
      name
    }
  }
}
`

export const getPlatformAccount = `
  query getPlatformAccount($id: ID!) {
    getPlatformAccount(id: $id){
      accountId
      name
    }
  }  
`;