export const createIdentityProvider = `
  mutation createIdentityProvider($input: CreateIdentityProviderInput!) {
    createIdentityProvider(input: $input){
      id
      name
      providerType
      description
      createdByUsername
      managedByUsername
      created
      updated
    }
  }  
`;

export const updateIdentityProvider = `
  mutation updateIdentityProvider($input: UpdateIdentityProviderInput!) {
    updateIdentityProvider(input: $input){
      id
      name
      providerType
      description
      createdByUsername
      managedByUsername
      created
      updated
    }
  }
`;

export const deleteIdentityProvider = `
  mutation deleteIdentityProvider($input: DeleteIdentityProviderInput!) {
    deleteIdentityProvider(input: $input){
      id
    }
  }  
`;
