import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update
} from '../../entity-types/services/EntityTypesService';

import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase
} from '../../../store/RecordsSliceCaseHelpers';
import {NEXTEntityType} from "../types.ts";
import {moduleConfig} from "../config";


const entityKey = moduleConfig.entityKey;

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
};

const normalizeRecord = (record: NEXTEntityType) => {
    return Object.assign({}, record,);
};

// genenrates pending, fulfilled and rejected
export const fetchEntityTypes = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    (permissionProfileId: string) => {

        return fetchRecords(permissionProfileId)
            .then((response) => {
                return response?.map((record) => {
                    return normalizeRecord(record);
                });
            });
    },
);

export const fetchEntityType = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                return normalizeRecord(response as NEXTEntityType);
            });
    });


export const saveEntityType = createAsyncThunk(
    `${entityKey}/add`,
    (payload: NEXTEntityType) => {
        return add(payload)
            .then((response) => normalizeRecord(response as NEXTEntityType));
    });

export const updateEntityType = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record
     }: { id: string, record: NEXTEntityType }) => {

        return update(id, record)
            .then((response) => {
                return normalizeRecord(response as NEXTEntityType);
            });
    },
);

export const deleteEntityType = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    },
);

const entityTypesSlice = createSlice({
                                         name: entityKey,
                                         initialState,
                                         reducers: {},
                                         extraReducers: (builder) => {
                                             // FETCH MANY
                                             fetchManyCase(builder, fetchEntityTypes, entityKey);

                                             // FETCH ONE
                                             fetchOneCase(builder, fetchEntityType);

                                             // SAVE ONE
                                             saveOneCase(builder, saveEntityType);

                                             // UPDATE ONE
                                             updateOneCase(builder, updateEntityType);

                                             // DELETE ONE
                                             deleteOneCase(builder, deleteEntityType);
                                         },
                                     });

export default entityTypesSlice.reducer;
