import {
    BatchIsAuthorizedWithTokenCommand,
    BatchIsAuthorizedWithTokenCommandInput,
    IsAuthorizedWithTokenCommand,
    IsAuthorizedWithTokenCommandInput,
    VerifiedPermissionsClient,
} from '@aws-sdk/client-verifiedpermissions'; // ES Modules import
// const { VerifiedPermissionsClient, IsAuthorizedWithTokenCommand } = require("@aws-sdk/client-verifiedpermissions"); // CommonJS import
import {notifyAccessDenied} from '../../components/ServiceResponseNotifications.tsx';
import config from '../../config';

import {fetchAuthSession} from "@aws-amplify/auth";


export const isAuthorized = async (input: IsAuthorizedWithTokenCommandInput) => {

    if (!config.permissions.enabled) {
        return true;
    }

    const {credentials} = (await fetchAuthSession());

    const client = new VerifiedPermissionsClient({
                                                     region: 'us-west-2',
                                                     credentials: credentials,
                                                 });

    const command = new IsAuthorizedWithTokenCommand(input);
    const response = await client.send(command);


    if (response.decision === 'ALLOW') {
        // notifySuccess('Access Allowed')
        return true;
    } else {
        notifyAccessDenied();
        return false;
    }


};

export const runBatchAuthorization = async (input: BatchIsAuthorizedWithTokenCommandInput) => {

    if (!config.permissions.enabled) {
        return [];
    }

    const {credentials} = (await fetchAuthSession());

    const client = new VerifiedPermissionsClient({
                                                     region: 'us-west-2',
                                                     credentials: credentials,
                                                 });

    const command = new BatchIsAuthorizedWithTokenCommand(input);
    const response = await client.send(command);

    if (response.results && response.results.length > 0) {
        return response.results;
    } else {
        notifyAccessDenied();
        return [];
    }

};

