import {Accordion} from '@mantine/core';
import {PolicyEffect} from "./sections/PolicyEffect.tsx";
import {PolicyDescription} from './sections/PolicyDescription.tsx';
import {PolicyScope} from './sections/PolicyScope.tsx';
import {FormikProps} from "formik";
import {NEXTPolicyFormValues} from "../types.ts";
import {PolicyConditions} from './sections/PolicyConditions.tsx';


export const FormComponent = (props: FormikProps<NEXTPolicyFormValues>) => {


    return (
        <Accordion multiple defaultValue={["policy-effect", 'policy-scope']} chevronPosition={'left'} {...props}>
            <Accordion.Item value={'policy-effect'}>
                <Accordion.Control>Policy Effect</Accordion.Control>
                <Accordion.Panel>
                    <PolicyEffect/>
                </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value={'policy-scope'}>
                <Accordion.Control>Policy Scope</Accordion.Control>
                <Accordion.Panel>
                    <PolicyScope/>
                </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value={'policy-conditions'}>
                <Accordion.Control>Policy Conditions</Accordion.Control>
                <Accordion.Panel>
                    <PolicyConditions/>
                </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value={'policy-description'}>
                <Accordion.Control>Policy Description</Accordion.Control>
                <Accordion.Panel>
                    <PolicyDescription/>
                </Accordion.Panel>
            </Accordion.Item>

        </Accordion>
    );
};
