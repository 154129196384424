import {
    Group,
    JsonInput,
    Stack,
    Text
} from '@mantine/core';
import {PolicyTemplate} from "../types.ts";
import {PlatformPropertiesCard} from "@pac/platform-ui-components";


const PolicyBlock = ({record}: { record: PolicyTemplate }) => {
    return (
        <Stack gap={'xs'}>
            <Group ml={'1em'} gap={'xs'}><Text
                fw={600}>Description:</Text><Text>{record.description}</Text></Group>
            <Stack ml={'1em'} gap={'xs'}>
                <Text fw={600}>PolicyBody:</Text>
                <JsonInput
                    value={record.statement}
                    autosize
                    minRows={6}
                />
            </Stack>

        </Stack>
    );
};

export const Profile = ({record}: { record: PolicyTemplate }) => {

    const properties = [
        {
            label: 'Policy Template ID',
            value: record.policyTemplateId,
        }, {
            label: 'Policy Store ID',
            value: record.policyStoreId,
        },
        {
            label: 'Created',
            value: record.createdDate ? record.createdDate : 'n/a',
            formatAs: 'Timestamp',
        },
        {
            label: 'Last Updated',
            value: record.lastUpdatedDate ? record.lastUpdatedDate : 'n/a',
            formatAs: 'Timestamp',
        }, {
            label: 'Policy Body',
            value: <PolicyBlock record={record}/>,
            span: 12
        }];

    return (
        <PlatformPropertiesCard cols={2} properties={properties}/>
    );

};
