import {
    getPolicyTemplate,
    listPolicyTemplates
} from './queries';

import {
    createPolicyTemplate,
    deletePolicyTemplate,
    updatePolicyTemplate
} from './mutations';

import {NEXTPolicyDefinition} from "../../policies/types.ts";
import {
    notifyError,
    notifySuccess
} from "../../../components/ServiceResponseNotifications.tsx";
import {
    generateClient,
    GraphQLQuery
} from "@aws-amplify/api";
import {
    CreatePolicyTemplate,
    DeletePolicyTemplate,
    GetPolicyTemplate,
    ListPolicyTemplates,
    UpdatePolicyTemplate
} from "../types.ts";

const client = generateClient();

export const fetchRecords = async (permissionProfileId: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<ListPolicyTemplates>>(
            {
                query: listPolicyTemplates,
                variables: {
                    filter: {
                        permissionProfileId: permissionProfileId
                    }
                }
            });

        return response.data?.listPolicyTemplates.items;
    } catch (e: any) {
        notifyError(e);
    }
};

export const fetch = async (permissionProfileId: string, id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetPolicyTemplate>>({
                                                                                   query: getPolicyTemplate,
                                                                                   variables: {
                                                                                       permissionProfileId: permissionProfileId,
                                                                                       policyTemplateId: id
                                                                                   },
                                                                               });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess(`Got Policy Template`);
            return response.data.getPolicyTemplate;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }

    } catch (e: any) {
        notifyError(e);
    }
};

export const add = async (permissionProfileId: string, statement: string, description?: string) => {
    const variables = Object.assign({}, {
        input: {
            permissionProfileId: permissionProfileId,
            statement: statement,
            description: description
        }
    });

    try {
        const response = await client.graphql<GraphQLQuery<CreatePolicyTemplate>>(
            {
                query: createPolicyTemplate,
                variables: variables,
            });


        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Policy Template created');
            return response.data.createPolicyTemplate;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e);
    }
};

export const update = async (permissionProfileId: string, id: string, policyDefinition: NEXTPolicyDefinition) => {

    const variables = Object.assign({}, {
        input: {
            permissionProfileId: permissionProfileId,
            policyTemplateId: id,
            policyDefinition: policyDefinition
        }
    });

    try {
        const response = await client.graphql<GraphQLQuery<UpdatePolicyTemplate>>(
            {
                query: updatePolicyTemplate,
                variables: variables,
            });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Policy Template updated');
            return response.data.updatePolicyTemplate;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e);
    }
};

export const deleteRecord = async (permissionProfileId: string, id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeletePolicyTemplate>>({
                                                                                      query: deletePolicyTemplate,
                                                                                      variables: {
                                                                                          input: {
                                                                                              permissionProfileId: permissionProfileId,
                                                                                              policyTemplateId: id
                                                                                          }
                                                                                      },
                                                                                  });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Policy Template deleted');
            return response.data.deletePolicyTemplate;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e);
    }
};
