export const listEntityTypes = `
    query listEntityTypes($filter:ListEntityTypesFilterInput!) {
      listEntityTypes(filter:$filter) {
        items {
          id
          name
          permissionProfileId
          permissionProfile {
            id
            name
          }
          memberOfTypesIds
          createdByUsername
          managedByUsername
          created
          updated
        }
      }
    }
`

export const getEntityType = `
  query getEntityType($id: ID!) {
    getEntityType(id: $id){
      id
      name
      permissionProfileId
      permissionProfile {
        id
        name
      }
      memberOfTypesIds
      createdByUsername
      managedByUsername
      created
      updated
    }
  }  
`;