import {useEffect} from 'react';

import {
    SimpleGrid,
    Table
} from '@mantine/core';

import {usePolicyTemplates} from '../hooks/usePolicyTemplates';

import {
    RowSelectionDataCell,
    SimpleTableBatchAction,
    SimpleTablePagination,
    TableBody,
    TableBodyColumn,
    TableHeaderColumn,
    TableHeaderRow,
} from '@pac/platform-ui-components';

import {useParams} from 'react-router-dom'
import {
    IPolicyTemplateTableColumn,
    PolicyTemplate
} from "../types.ts";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../../config/rbac/actions.ts";


type RecordsTableProps = {
    extraTools?: JSX.Element[],
    settings?: { label: string, onClick: () => void }[]
}

export const RecordsTable = ({
                                 extraTools,
                                 settings
                             }: RecordsTableProps) => {

    const {id: permissionProfileId} = useParams();

    if (typeof permissionProfileId === 'undefined') {
        throw new Error('permissionProfileId is required parameter')
    }


    const {allowedActions} = useVerifiedPermissions()


    const {
        columns,
        visibleItems,
        limit,
        loading,
        selection,
        totalPages,
        visibleCount,
        handleRowSelection,
        handleSelectAllRows,
        handleFetchRecords,
        handleFilterByKeyword,
        handleItemsPerPageChange,
        handlePageChange,
    } = usePolicyTemplates(permissionProfileId);

    useEffect(() => {
        handleFetchRecords();
    }, []);

    const renderRowSelectionDataCell = (item: PolicyTemplate) => (
        <RowSelectionDataCell item={item} handleChange={handleRowSelection}/>
    );

    const getColumns = () => {
        const cols: IPolicyTemplateTableColumn[] = [];
        if (allowedActions.includes(rbacActions.DeletePolicyTemplate)) {
            cols.push({
                          id: 'select',
                          title: '',
                          render: renderRowSelectionDataCell,
                      },
            );
        }
        return cols.concat(columns);
    };

    return (
        <SimpleGrid verticalSpacing='xl' m='sm'>
            <SimpleTableBatchAction
                limit={limit}
                handleItemsPerPageChange={handleItemsPerPageChange}
                settings={settings}
                handleFilterByKeyword={handleFilterByKeyword}
                extraTools={extraTools}
            />
            <Table verticalSpacing='sm' striped highlightOnHover>
                <TableHeaderRow
                    columns={getColumns() as TableHeaderColumn[]}
                    handleSelectAllRows={handleSelectAllRows}
                />
                <TableBody
                    items={visibleItems}
                    loading={loading}
                    selection={selection}
                    columns={getColumns() as TableBodyColumn[]}
                />
            </Table>

            <SimpleTablePagination
                totalPages={totalPages}
                totalCount={visibleCount}
                handlePageChange={handlePageChange}
            />
        </SimpleGrid>
    );
};
