export const listIdentityProviders = `
query listIdentityProviders($filter: IdentityProvidersFilterInput!, $limit: Int, $nextToken: String) {
  listIdentityProviders(filter: $filter, limit: $limit, nextToken:$nextToken) {
    items {
      id
      name
      providerType
      description
      createdByUsername
      managedByUsername
      created
      updated
    }
  }
}
`

export const getIdentityProvider = `
  query getIdentityProvider($id: ID!) {
    getIdentityProvider(id: $id){
      id
      name
      providerType
      description
      createdByUsername
      managedByUsername
      created
      updated
    }
  }  
`;