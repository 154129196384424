import {stage} from './stage';

type ConfigShape = {
    [key: string]: {
        isEnabled: boolean,
        applicationId: string,
        applicationVersion: string,
        applicationRegion: string,
        config: {
            sessionSampleRate: number,
            guestRoleArn: string,
            identityPoolId: string,
            endpoint: string,
            telemetries: string[],
            allowCookies: boolean,
            enableXRay: boolean,
        },
    }
}

const rum: ConfigShape = {
    prd: {
        isEnabled: true,
        applicationId: 'c7aac3ca-bb5f-4c8b-a266-a247e3c75483',
        applicationVersion: '1.0.0',
        applicationRegion: 'us-west-2',
        config: {
            sessionSampleRate: 1,
            guestRoleArn:
                'arn:aws:iam::827098250628:role/pac-platform-service-status-ap-AppCognitoGuestRole-18DM0Q97JMCYN',
            identityPoolId: 'us-west-2:35164169-98e6-45f7-aa61-80b5b9daddd1',
            endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
            telemetries: ['errors', 'performance', 'http'],
            allowCookies: true,
            enableXRay: true,
        },
    },
    test: {
        isEnabled: true,
        applicationId: 'e0b5c7d2-723d-4732-9950-185adb2fd7e8',
        applicationVersion: '1.0.0',
        applicationRegion: 'us-west-2',
        config: {
            sessionSampleRate: 1,
            guestRoleArn:
                'arn:aws:iam::766605398863:role/pac-platform-service-status-ap-AppCognitoGuestRole-K0W0HXBEKRKR',
            identityPoolId: 'us-west-2:bb150987-bdca-4ff0-b2c7-210d39f97d49',
            endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
            telemetries: ['errors', 'performance', 'http'],
            allowCookies: true,
            enableXRay: true,
        },
    },
    dev: {
        isEnabled: true,
        applicationId: 'a53d67bd-4f8d-42bb-a809-88a9fc2c9a86',
        applicationVersion: '1.0.0',
        applicationRegion: 'us-west-2',
        config: {
            sessionSampleRate: 1,
            guestRoleArn:
                'arn:aws:iam::364161771924:role/pac-platform-service-status-ap-AppCognitoGuestRole-1R0CZ73ZBJZ13',
            identityPoolId: 'us-west-2:e4ff6eb2-6eaa-4f84-8970-ba3a03f62c54',
            endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
            telemetries: ['errors', 'performance', 'http'],
            allowCookies: true,
            enableXRay: true,
        },
    },
    ide: {
        isEnabled: false,
        applicationId: 'xxxx',
        applicationVersion: '1.0.0',
        applicationRegion: 'us-west-2',
        config: {
            sessionSampleRate: 1,
            guestRoleArn: 'xxxx',
            identityPoolId: 'xxx',
            endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
            telemetries: ['errors', 'performance', 'http'],
            allowCookies: true,
            enableXRay: true,
        },
    },
};

let RUM = {
    isEnabled: rum[stage].isEnabled,
    applicationId: rum[stage].applicationId,
    applicationVersion: rum[stage].applicationVersion,
    applicationRegion: rum[stage].applicationRegion,
    config: rum[stage].config,
};

export default RUM;
