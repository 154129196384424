import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update
} from '../../policy-templates/services/PolicyTemplatesService';

import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase
} from '../../../store/RecordsSliceCaseHelpers';

import {
    PolicyTemplate,
    PolicyTemplateFormValues
} from "../types";
import {NEXTPolicyDefinition} from "../../policies/types.ts";
import {buildPolicyBody} from "../../../utils/PolicyHelper.ts";
import {
    moduleConfig,
    policyTemplateNamespace
} from "../config";

const entityKey = moduleConfig.entityKey;

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
};

const normalizeRecord = (permissionProfileId: string, record: PolicyTemplate) => {
    return {
        ...record, ...{
            id: record.policyTemplateId,
            permissionProfileId
        }
    };
};

// genenrates pending, fulfilled and rejected
export const fetchPolicyTemplates = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    (permissionProfileId: string) => {


        return fetchRecords(permissionProfileId)
            .then((response) => {
                return response?.map((record) => {
                    return normalizeRecord(permissionProfileId, record);
                });
            });
    },
);

export const fetchPolicyTemplate = createAsyncThunk(
    `${entityKey}/fetch`,
    ({
         permissionProfileId,
         id
     }: {
        permissionProfileId: string,
        id: string
    }) => {

        return fetch(permissionProfileId, id)
            .then((response) => {
                return normalizeRecord(permissionProfileId, response as PolicyTemplate);
            });
    });


export const savePolicyTemplate = createAsyncThunk(
    `${entityKey}/add`,
    ({
         permissionProfileId,
         payload
     }: {
        permissionProfileId: string,
        payload: PolicyTemplateFormValues
    }) => {


        const statement = buildPolicyBody(policyTemplateNamespace, null, {
            principals: {type: 'all'},
            actions: {
                type: 'set',
                value: payload.actions
            },
            resources: {type: 'all'}
        })

        return add(permissionProfileId, statement, payload.description)
            .then((response) => normalizeRecord(permissionProfileId, response as PolicyTemplate));
    });

export const updatePolicyTemplate = createAsyncThunk(
    `${entityKey}/update`,
    ({
         permissionProfileId,
         id,
         record
     }: {
        permissionProfileId: string, id: string,
        record: PolicyTemplateFormValues
    }) => {

        const statement = buildPolicyBody(policyTemplateNamespace, null, {
            principals: {type: 'all'},
            actions: {
                type: 'set',
                value: record.actions
            },
            resources: {type: 'all'}
        })

        const policyDefinition: NEXTPolicyDefinition = {
            static: {
                statement: statement,
                description: record.description
            }
        };

        return update(permissionProfileId, id, policyDefinition)
            .then((response) => {
                return normalizeRecord(permissionProfileId, response as PolicyTemplate);
            });
    },
);

export const deletePolicyTemplate = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    ({
         permissionProfileId,
         id
     }: {
        permissionProfileId: string,
        id: string
    }) => {

        return deleteRecord(permissionProfileId, id)
            .then(() => id);
    },
);

const policyTemplatesSlice = createSlice({
                                             name: entityKey,
                                             initialState,
                                             reducers: {},
                                             extraReducers: (builder) => {
                                                 // FETCH MANY
                                                 fetchManyCase(builder, fetchPolicyTemplates, entityKey);

                                                 // FETCH ONE
                                                 fetchOneCase(builder, fetchPolicyTemplate);

                                                 // SAVE ONE
                                                 saveOneCase(builder, savePolicyTemplate);

                                                 // UPDATE ONE
                                                 updateOneCase(builder, updatePolicyTemplate);

                                                 // DELETE ONE
                                                 deleteOneCase(builder, deletePolicyTemplate);
                                             },
                                         });

export default policyTemplatesSlice.reducer;
