import {stage} from '../stage';

type ConfigShape = {
    [key: string]: {
        endpoint: string,
        region: string,
    }
}

const appsyncConfig: ConfigShape = {
    prd: {
        endpoint:
            'https://igdn3v5e5ra5bjv32qkzjyfmeu.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
    },
    test: {
        endpoint:
            'https://3k52sb4gtveffk2swzsjrrwtgu.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
    },
    dev: {
        endpoint:
            'https://fu5odewqgbgfrj3wbm2fu7locq.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
    },
    ide: {
        endpoint:
            'https://fu5odewqgbgfrj3wbm2fu7locq.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
    },
};

export default appsyncConfig[stage];
