import {visibilityFilter} from '../../utils/VisibilityFilter';
import {RootState} from "../../store";
import {moduleConfig} from "./config";
import {DeploymentEnvironment} from "./types.ts";

const key = moduleConfig.entityKey;


export const recordsSelector = (state: RootState) => {
    return state[key].records;
};


export const recordSelector = (state: RootState, id: string) => {
    const records = recordsSelector(state);
    const loading = records.loading;
    const record = records.byId[id] as DeploymentEnvironment;
    return {
        record,
        loading
    }
};


export const selectionSelector = (state: RootState) => {
    return state[key].selection;
};

export const visibilitySelector = (state: RootState) => {
    const items = deploymentEnvironmentsSelector(state);
    const page = state[key].visibility.page;
    const limit = state[key].visibility.limit;
    const keyword = state[key].visibility.keyword;
    const visible = state[key].visibility.visible;
    const columns = state[key].visibility.columns;

    const visibleItems = visibilityFilter(items, page, limit, keyword);

    const totalCount = state[key].records.totalCount;

    let visibleCount = totalCount;
    if (keyword) {
        visibleCount = visibleItems.length;
    }

    const totalPages = Math.ceil(visibleCount / limit);

    return {
        items: items,
        visibleItems: visibleItems,
        page: page,
        limit: limit,
        keyword: keyword,
        visible: visible,
        columns: columns,
        visibleCount: visibleCount,
        totalCount: totalCount,
        totalPages: totalPages,
    };
};

export const deploymentEnvironmentsSelector = (state: RootState) => {
    return state[key].records.ids.map((id: string) => {
        return state[key].records.byId[id];
    });
};



