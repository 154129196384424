import {
    deletePolicy,
    fetchPolicy,
    savePolicy,
    updatePolicy,
} from '../reducers/recordsSlice';

import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";
import {NEXTPolicyFormValues} from "../types.ts";

export const usePolicy = (permissionProfileId: string, policyId?: string) => {
    const dispatch = useAppDispatch();

    if (typeof permissionProfileId === 'undefined') {
        throw new Error('permissionProfileId is required parameter')
    }

    const {
        record,
        loading
    } = useAppSelector((state) =>
                           recordSelector(state, policyId ? policyId : ''),
    );

    // FETCH
    const handleFetchRecord = () => {

        if (typeof policyId === 'undefined') {
            throw new Error('policyId is required parameter')
        }

        dispatch(fetchPolicy({
                                 permissionProfileId: permissionProfileId,
                                 id: policyId
                             }));
    };


    // CREATE, UPDATE DELETE
    const handleCreateRecord = (values: NEXTPolicyFormValues) => {

        dispatch(savePolicy({
                                permissionProfileId: permissionProfileId,
                                payload: values
                            }));
    };

    const handleUpdateRecord = (values: NEXTPolicyFormValues) => {

        if (typeof policyId === 'undefined') {
            throw new Error('policyId is required parameter')
        }

        dispatch(updatePolicy({
                                  permissionProfileId: permissionProfileId,
                                  id: policyId,
                                  record: values
                              }));
    };

    const handleDeleteRecord = () => {

        if (typeof policyId === 'undefined') {
            throw new Error('policyId is required parameter')
        }

        dispatch(deletePolicy({
                                  permissionProfileId: permissionProfileId,
                                  id: policyId
                              }));
    };


    return {
        record,
        loading,
        handleFetchRecord,
        handleCreateRecord,
        handleUpdateRecord,
        handleDeleteRecord,
    };
};
