import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';
import {AddPageNavigation} from "../navigation/EditPageNavigation.tsx";
import {usePolicyStore} from "../hooks/usePolicyStore";

export const AddPage = () => {

    const {
        record,
        handleCreateRecord
    } = usePolicyStore();

    return (
        <FormPageLayout
            title='Add Policy Store'
            pageNavigation={<AddPageNavigation/>}
        >
            <SimplePageContentWrapper><RecordForm record={record}
                                                  handleSubmit={handleCreateRecord}/></SimplePageContentWrapper>
        </FormPageLayout>
    );
};
