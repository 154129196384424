export const listActions = `
query listActions($filter: ListActionsFilterInput!) {
  listActions(filter: $filter) {
    items {
      id
      name
      permissionProfileId
      principalTypeIds
      resourceTypeIds
      createdByUsername
      managedByUsername
      created
      updated
    }
  }
}
`

export const getAction = `
  query getAction($id: ID!) {
    getAction(id: $id){
      id
      name
      permissionProfileId
      principalTypeIds
      resourceTypeIds
      createdByUsername
      managedByUsername
      created
      updated
    }
  }  
`;