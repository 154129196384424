import {FunctionComponent} from 'react';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';
import {AddPageNavigation} from "../navigation/EditPageNavigation.tsx";
import {usePermissionGroup} from "../hooks/usePermissionGroup";
import {useParams} from "react-router-dom";

export const AddPage: FunctionComponent = () => {

    const {id: permissionProfileId} = useParams();

    if (typeof permissionProfileId === 'undefined') {
        throw new Error('permissionProfileId is required parameter')
    }

    const {
        record,
        handleCreateRecord
    } = usePermissionGroup(permissionProfileId);

    return (
        <FormPageLayout
            title='Add Permission Group'
            pageNavigation={<AddPageNavigation/>}
        >
            <SimplePageContentWrapper>
                <RecordForm record={record} handleSubmit={handleCreateRecord}/>
            </SimplePageContentWrapper>
        </FormPageLayout>
    );
};
