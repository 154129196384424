import {
    useEffect,
    useState
} from 'react';

import {Autocomplete} from '@mantine/core';
import {FieldProps} from "formik";
import {useParams} from "react-router-dom";
import {PermissionGroup} from "../types.ts";
import {usePermissionGroups} from "../hooks/usePermissionGroups.ts";


const generateKey = (record: PermissionGroup) => record.entityType?.name + ' - ' + record.entityIdentifier;


export const PermissionGroupNamesAutocomplete = ({
                                                     field: {
                                                         name,
                                                         value: fieldValue
                                                     },
                                                     form: {setFieldValue},
                                                     ...rest
                                                 }: FieldProps) => {


    const [value, setValue] = useState('');
    const [disabled, setDisabled] = useState(false);

    const {id: permissionProfileId} = useParams();

    if (typeof permissionProfileId === 'undefined') {
        throw new Error('permissionProfileId is required parameter')
    }
    const {
        items: records,
        handleFetchRecords,
    } = usePermissionGroups(permissionProfileId);

    useEffect(() => {
        handleFetchRecords();
    }, []);


    const data = records.map((record: PermissionGroup) => {
        return {
            id: record.id,
            name: record.name,
            entityType: record.entityType,
            entityIdentifier: record.entityIdentifier,
            value: generateKey(record),
        };
    });


    useEffect(() => {
        if (records.length === 0) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
        const found = records.find((item: PermissionGroup) => generateKey(item) === fieldValue);
        if (typeof found !== 'undefined') {
            setValue(found.name);
        }
    }, [records.length]);


    const handleChange = (value: string) => {
        if (!Array.isArray(data)) {
            throw new Error('Data is expected to be an array');
        }
        const found = data.find((item) => generateKey(item) === value) || {};
        console.debug({found})

        if (typeof name !== 'undefined') {
            setFieldValue(name, found.entityIdentifier);
        }

        setValue(value);
    };


    return (
        <Autocomplete
            name={name}
            value={value}
            onChange={handleChange}
            data={data}
            disabled={disabled}
            placeholder='Select Permission Group'
            {...rest}
        />
    );
};
