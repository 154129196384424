import {
    getPolicyStore,
    listPolicyStores
} from './queries';

import {
    createPolicyStore,
    deletePolicyStore,
    updatePolicyStore
} from './mutations';


import {
    CreatePolicyStore,
    DeletePolicyStore,
    GetPolicyStore,
    ListPolicyStores,
    NEXTPolicyStore,
    UpdatePolicyStore
} from "../types.ts";
import {
    generateClient,
    GraphQLQuery
} from "@aws-amplify/api";

import getUuid from 'uuid-by-string';
import {
    notifyError,
    notifySuccess
} from "../../../components/ServiceResponseNotifications.tsx";

const client = generateClient();

export const fetchRecords = async () => {
    try {
        const response = await client.graphql<GraphQLQuery<ListPolicyStores>>({
                                                                                  query: listPolicyStores,
                                                                                  variables: {}
                                                                              })

        return response.data?.listPolicyStores.items;
    } catch (e) {
        console.debug(e);
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetPolicyStore>>({
                                                                                query: getPolicyStore,
                                                                                variables: {
                                                                                    policyStoreId: id
                                                                                },
                                                                            });
        if (typeof response.data !== 'undefined' && response.data !== null) {
            return response.data.getPolicyStore;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }
    } catch (e: any) {
        notifyError(e);
    }
};

export const add = async (payload: NEXTPolicyStore) => {

    console.debug('IN ADD POLICY STORE ', payload)

    const variables = {
        input: {
            clientToken: getUuid(payload.description + new Date()),
            ...payload
        }
    };

    try {
        const response = await client.graphql<GraphQLQuery<CreatePolicyStore>>({
                                                                                   query: createPolicyStore,
                                                                                   variables: variables,
                                                                               });


        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Policy Store created');
            return response.data.createPolicyStore;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e);
    }
};

export const update = async (id: string, record: NEXTPolicyStore) => {

    console.debug('IN UPDATE POLICY STORE RECORD', id, record)

    const variables = {
        input: {
            policyStoreId: id,
            description: record.description,
            validationSettings: record.validationSettings
        }
    };

    try {
        const response = await client.graphql<GraphQLQuery<UpdatePolicyStore>>({
                                                                                   query: updatePolicyStore,
                                                                                   variables: variables,
                                                                               });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Policy Store updated');
            return response.data.updatePolicyStore;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e);
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeletePolicyStore>>({
                                                                                   query: deletePolicyStore,
                                                                                   variables: {
                                                                                       input: {
                                                                                           policyStoreId: id
                                                                                       }
                                                                                   },
                                                                               });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Policy Store deleted');
            return response.data.deletePolicyStore;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }

    } catch (e: any) {
        notifyError(e);
    }
};
