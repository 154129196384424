import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update,
} from '../services/TenantsService';

import {
    deleteOneCase,
    fetchManyWithPaginationCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase,
} from '../../../store/RecordsSliceCaseHelpers';
import {moduleConfig} from "../config";
import {Tenant} from "../types.ts";

const entityKey = moduleConfig.entityKey;

interface TenantsState {
    byId: { [key: string]: Tenant },
    ids: string[],
    totalCount: number,
    loading: boolean,
    error: string
}

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
} as TenantsState;

const normalizeRecord = (record: object) => {
    return Object.assign({}, record);
};

// genenrates pending, fulfilled and rejected
export const fetchTenants = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    (query: object) => {
        return fetchRecords(query)
            .then((response) => {

                const collection = response?.Items;
                const totalCount = response?.TotalCount

                const normalCollection = collection.map((record: Tenant) => {
                    return normalizeRecord(record);
                });

                console.debug('normalCollection', normalCollection);

                return {
                    items: normalCollection,
                    totalCount: totalCount
                }


            });
    }
);

export const fetchTenant = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                return normalizeRecord(response as any);
            });
    });

export const saveTenant = createAsyncThunk(
    `${entityKey}/add`,
    (payload: object) => {
        return add(payload)
            .then((response) => response);
    });

export const updateTenant = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record
     }: { id: string, record: object }) => {
        return update(id, record)
            .then(() => record);
    }
);

export const deleteTenant = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    }
);

const applicationsSlice = createSlice({
                                          name: entityKey,
                                          initialState,
                                          reducers: {},
                                          extraReducers: (builder) => {
                                              // FETCH MANY
                                              fetchManyWithPaginationCase(builder, fetchTenants, entityKey);

                                              // FETCH ONE
                                              fetchOneCase(builder, fetchTenant);

                                              // SAVE ONE
                                              saveOneCase(builder, saveTenant);

                                              // UPDATE ONE
                                              updateOneCase(builder, updateTenant);

                                              // DELETE ONE
                                              deleteOneCase(builder, deleteTenant);
                                          },
                                      });

export default applicationsSlice.reducer;

