import {
    Group,
    Text
} from '@mantine/core';
import {NavLink} from "react-router-dom";
import {NEXTAuthorizationRequest} from "../types.ts";
import {PlatformPropertiesCard} from "@pac/platform-ui-components";

export const Profile = ({record}: { record: NEXTAuthorizationRequest }) => {

    const properties = [
        {
            label: 'Authorization Request ID',
            value: record.id,
            span: 12
        }, {
            label: 'ActionType',
            value: record.actionType,
        }, {
            label: 'ActionId',
            value: record.actionId,
        }, {
            label: 'Principal Type',
            value: record.principalType,
        }, {
            label: 'Principal ID',
            value: <Group><Text>{record.principalId}</Text><NavLink
                to={`/permission-profiles/${record.permissionProfileId}/policies?element=Principal&entityType=${record.principalType}&entityId=${record.principalId}`}>Policies</NavLink></Group>,
        }, {
            label: 'Resource Type',
            value: record.resourceType,
        }, {
            label: 'Resource ID',
            value: <Group><Text>{record.resourceId}</Text><NavLink
                to={`/permission-profiles/${record.permissionProfileId}/policies?element=Resource&entityType=${record.resourceType}&entityId=${record.resourceId}`}>Policies</NavLink></Group>,
        }, {
            label: 'Context',
            value: record.context,
        }, {
            label: 'Slice Compliment',
            value: record.sliceComplement,
        }, {
            label: 'Decision',
            value: record.decision,
        }, {
            label: 'Determining Polices',
            value: record.determiningPolicies,
        }];

    return (
        <PlatformPropertiesCard cols={2} properties={properties}/>
    );

};
