import {
    Group,
    JsonInput,
    Text
} from '@mantine/core';
import {NEXTEntityType} from "../types.ts";
import {PlatformPropertiesCard} from "@pac/platform-ui-components";

const EntitiesCollection = ({entities}: { entities: string[] }) => {
    return (
        <Group ml={'1em'} gap={'xs'}>
            [
            {entities.map(entity => {
                return (<Text key={entity}>{entity}</Text>)
            })}
            ]
        </Group>
    );
};

export const Profile = ({record}: { record: NEXTEntityType }) => {

    const properties = [
        {
            label: 'Entity Type Name',
            value: record.name,
        },
        {
            label: 'Member of Types',
            value: (<EntitiesCollection
                    entities={record.memberOfTypesIds ? record.memberOfTypesIds : []}/>
            ),
        },
        {
            label: 'Shape Type',
            value: record.shape ? record.shape.type : 'n/a'
        },
        {
            label: 'Shape Attributes',
            value: (<JsonInput
                    value={record.shape ? JSON.stringify(record.shape) : '{}'}/>
            ),
        }];

    return (
        <PlatformPropertiesCard cols={2} properties={properties}/>
    );


};

