import {useEffect} from 'react';
import {useAuth} from '../../../hooks/useAuth';
import {
    NextButton,
    PlatformLoader,
    SimplePageContentWrapper,
    SimplePageLayout
} from '@pac/platform-ui-components';
import {useNavigate} from "react-router-dom";
import {
    Badge,
    Box,
    Center,
    Group,
    Text
} from "@mantine/core";
import {IndexPageHeader} from "../components/IndexPageHeader.tsx";
import {usePermissionProfiles} from "../../permission-profiles";


export const IndexPage = () => {
    const {user} = useAuth();

    const navigate = useNavigate();

    const {
        items,
        handleFetchRecords
    } = usePermissionProfiles()

    useEffect(() => {
        handleFetchRecords()
    }, [])


    if (!user) {
        return <PlatformLoader message='Loading user...Please, wait!'/>;
    }

    const permissionProfilesCount = items.length

    if (permissionProfilesCount === 0) {
        return (
            <Center>
                <Group>
                    <Box>No Permission Profile Found.</Box>
                    <NextButton
                        onClick={() => navigate('/permission-profiles')}>Create your
                        first permission profile</NextButton>
                </Group>
            </Center>
        )
    }

    return (
        <SimplePageLayout
            pageHeader={<IndexPageHeader/>}
        >
            <SimplePageContentWrapper>
                <Text tt={'uppercase'}>You have <Badge size={'xl'}
                                                       variant={'filled'}>{permissionProfilesCount}</Badge> Permission
                    Profile{permissionProfilesCount > 1 ? 's' : ''}</Text>
            </SimplePageContentWrapper>
        </SimplePageLayout>
    )


    // return <PortalDashboard/>;

};
