import {Field} from 'formik';

import {
    Fieldset,
    Stack
} from '@mantine/core';

import {
    PacFormikInput,
    PacFormikRadioGroup
} from '@pac/platform-ui-components';


export const getConditionsElements = () => {
    return [
        {
            name: 'key',
            placeholder: 'Key',
            description: 'Condition Key',
            component: PacFormikInput,
            span: 6,
        },
        {
            name: 'value',
            placeholder: 'Value',
            description: 'Condition Value',
            component: PacFormikInput,
            span: 6,
        }
    ]
}

const policyConditionOptions = [
    {
        label: 'When',
        value: 'when',
    },
    {
        label: 'Unless',
        value: 'unless',
    },
];


export const PolicyConditions = () => {

    // const {
    //     values,
    // } = useFormikContext<NEXTPolicyFormValues>()
    //
    // console.debug('POLICY CONDITIONS SECTION', values)

    return (
        <Stack gap={'xl'}>
            <Fieldset fw={600} tt={'uppercase'} legend={'Policy Conditions'}>

                <Field
                    name="policyConditionName"
                    label={''}
                    options={policyConditionOptions}
                    component={PacFormikRadioGroup}
                    my={'xl'}
                />


                {/*<Field*/}
                {/*    name='policyConditions'*/}
                {/*    value={values.policyConditions}*/}
                {/*    label={I18n.get('Policy Conditions')}*/}
                {/*    component={PacFormikList}*/}
                {/*    getRowElements={getConditionsElements}*/}
                {/*    {...rest}*/}
                {/*/>*/}

            </Fieldset>
        </Stack>
    );
};
