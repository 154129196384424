import {
    addToSelection,
    clearKeyword,
    clearSelection,
    removeFromSelection,
    setKeyword,
    setPage,
    setPageSize,
} from '../reducers/index';

import {
    deletePolicyStore,
    fetchPolicyStores
} from '../reducers/recordsSlice';

import {
    recordsSelector,
    selectionSelector,
    visibilitySelector
} from '../selectors';

import {columns} from '../tables/columns';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";

export const usePolicyStores = () => {
    const dispatch = useAppDispatch();

    const selection = useAppSelector((state) => selectionSelector(state));

    const {
        items,
        visibleItems,
        limit,
        keyword,
        page,
        totalPages,
        visibleCount
    } =
        useAppSelector((state) => visibilitySelector(state));

    const {loading} = useAppSelector((state) =>
                                         recordsSelector(state),
    );

    // FETCH
    const handleFetchRecords = () => {
        dispatch(fetchPolicyStores());
    };

    const handleDeleteRecords = () => {
        if (Object.keys(selection).length > 0) {
            for (const key in selection) {
                dispatch(deletePolicyStore(key));
            }
        }
    };

    // SELECTION
    const handleRowSelection = (row: { id: string }) => {
        if (typeof selection[row.id] !== 'undefined') {
            dispatch(removeFromSelection(row.id));
        } else {
            dispatch(addToSelection(row));
        }
    };

    const handleSelectAllRows = () => {
        for (let i = 0; i < items.length; i++) {
            if (typeof selection[items[i].id] !== 'undefined') {
                dispatch(removeFromSelection(items[i].id));
            } else {
                dispatch(addToSelection(items[i]));
            }
        }
    };

    // FILTERING AND PAGING
    const handleFilterByKeyword = (keyword: string) => {
        if (keyword !== '') {
            dispatch(setKeyword(keyword));
        } else {
            dispatch(clearKeyword());
        }
    };

    const handleItemsPerPageChange = (pageSize: string | number | null) => {
        dispatch(setPageSize(pageSize));
    };

    const handlePageChange = (page: number) => {
        dispatch(setPage(page));
    };

    // BATCH ACTIONS
    const handleBatchActionCancel = () => {
        dispatch(clearSelection());
    };

    return {
        items,
        visibleItems,
        limit,
        loading,
        selection,
        keyword,
        page,
        totalPages,
        visibleCount,
        columns,
        handleRowSelection,
        handleSelectAllRows,
        handleFetchRecords,
        handleDeleteRecords,
        handleFilterByKeyword,
        handleItemsPerPageChange,
        handleBatchActionCancel,
        handlePageChange,
    };
};
