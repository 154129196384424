import {FunctionComponent} from 'react';

import {
    Navigate,
    Route,
    Routes
} from 'react-router-dom';

import {IndexPage} from './pages/IndexPage';
import {AddPage} from './pages/AddPage';
import {EditPage} from './pages/EditPage';
import {useVerifiedPermissions} from "../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../config/rbac/actions.ts";

export const DeploymentEnvironmentsLayout: FunctionComponent = () => {

    const {
        allowedActions
    } = useVerifiedPermissions()

    return (
        <Routes>
            <Route
                path="/"
                element={
                    (allowedActions.includes(rbacActions.ListDeploymentEnvironments) ? (
                        <IndexPage/>
                    ) : (
                        <Navigate to="/"/>
                    ))
                }
            />
            <Route
                path="add"
                element={
                    (allowedActions.includes(rbacActions.CreateDeploymentEnvironment) ? (
                        <AddPage/>
                    ) : (
                        <Navigate to="/deployment-environments"/>
                    ))
                }
            />
            <Route
                path=":id"
                element={
                    (allowedActions.includes(rbacActions.GetDeploymentEnvironment) ? (
                        <EditPage/>
                    ) : (
                        <Navigate to="/deployment-environments"/>
                    ))
                }
            />


        </Routes>
    );
};
