export const createEntityType = `
  mutation createEntityType($input: CreateEntityTypeInput!) {
    createEntityType(input: $input){
      id
      name
      permissionProfileId
      memberOfTypesIds
      createdByUsername
      managedByUsername
      created
      updated
    }
  }  
`;

export const updateEntityType = `
  mutation updateEntityType($input: UpdateEntityTypeInput!) {
    updateEntityType(input: $input){
      id
      name
      permissionProfileId
      memberOfTypesIds
      createdByUsername
      managedByUsername
      created
      updated
    }
  }
`;

export const deleteEntityType = `
  mutation deleteEntityType($input: DeleteEntityTypeInput!) {
    deleteEntityType(input: $input){
      id
    }
  }  
`;
