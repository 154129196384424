import en from "./en";
import bg from "./bg";
import hi from "./hi";
import ja from "./ja";

const translationSets = {
    en: en,
    bg: bg,
    hi: hi,
    ja: ja,
};

export default translationSets;
