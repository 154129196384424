import {
    useEffect,
    useState
} from 'react';

import {Autocomplete} from '@mantine/core';
import {useEntityTypes} from "../hooks/useEntityTypes";
import {FieldProps} from "formik";
import {useParams} from "react-router-dom";
import {NEXTAction} from "../../actions/types.ts";


export const EntityTypesAutocomplete = ({
                                            field: {
                                                name,
                                                value: fieldValue
                                            },
                                            form: {setFieldValue},
                                            ...rest
                                        }: FieldProps) => {


    const [value, setValue] = useState('');
    const [disabled, setDisabled] = useState(false);

    const {id: permissionProfileId} = useParams();

    if (typeof permissionProfileId === 'undefined') {
        throw new Error('permissionProfileId is required parameter')
    }
    const {
        items: records,
        handleFetchRecords,
    } = useEntityTypes(permissionProfileId);

    useEffect(() => {
        handleFetchRecords();
    }, []);


    const data = records.map((record: NEXTAction) => {
        return {
            ...record,
            value: record.name,
        };
    });

    useEffect(() => {
        if (records.length === 0) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
        const found = records.find((item: NEXTAction) => item.id === fieldValue);
        if (typeof found !== 'undefined' && Object.prototype.hasOwnProperty.call(found, 'name')) {
            setValue(found.name);
        }
    }, [records.length]);

    const handleChange = (value: string) => {
        if (!Array.isArray(data)) {
            throw new Error('Data is expected to be an array');
        }
        const {id} = data.find((item) => item.name === value) || {};

        if (typeof id !== 'undefined') {
            setFieldValue(name, id);
        }

        setValue(value);
    };


    return (
        <Autocomplete
            name={name}
            value={value}
            onChange={handleChange}
            data={data}
            disabled={disabled}
            placeholder='Select Entity Type'
            {...rest}
        />
    );
};
