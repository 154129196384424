import {useEffect} from 'react';

import {
    SimpleGrid,
    Table
} from '@mantine/core';

import {useIdentitySources} from '../hooks/useIdentitySources';

import {
    useParams,
    useSearchParams
} from 'react-router-dom'

import {
    SimpleTableBatchAction,
    SimpleTablePagination,
    TableBody,
    TableBodyColumn,
    TableHeaderColumn,
    TableHeaderRow,
} from '@pac/platform-ui-components';
import {useRecordsTableColumns} from "../hooks/useRecordsTableColumns.tsx";

type RecordsTableProps = {
    extraTools?: JSX.Element[],
    settings?: { label: string, onClick: () => void }[]
}

export const RecordsTable = ({
                                 extraTools,
                                 settings
                             }: RecordsTableProps) => {

    const [searchParams] = useSearchParams()

    const {id: permissionProfileId} = useParams();

    if (typeof permissionProfileId === 'undefined') {
        throw new Error('permissionProfileId is required parameter')
    }


    const {
        visibleItems,
        limit,
        loading,
        selection,
        totalPages,
        visibleCount,
        handleSelectAllRows,
        handleFetchRecords,
        handleFilterByKeyword,
        handleItemsPerPageChange,
        handlePageChange,
    } = useIdentitySources(permissionProfileId);

    const {
        columns,
    } = useRecordsTableColumns(permissionProfileId);


    let query = {}
    if (searchParams.get('entityType')) {
        if (searchParams.get('element') === 'Principal') {
            query = {
                Principal: {
                    Identifier: {
                        EntityType: searchParams.get('entityType'),
                        EntityId: searchParams.get('entityId')
                    }
                }
            }
        } else if (searchParams.get('element') === 'Resource') {
            query = {
                Resource: {
                    Identifier: {
                        EntityType: searchParams.get('entityType'),
                        EntityId: searchParams.get('entityId')
                    }
                }
            }

        }
    }

    useEffect(() => {
        handleFetchRecords(query);
    }, []);


    return (
        <SimpleGrid verticalSpacing='xl' m='sm'>
            <SimpleTableBatchAction
                limit={limit}
                handleItemsPerPageChange={handleItemsPerPageChange}
                settings={settings}
                handleFilterByKeyword={handleFilterByKeyword}
                extraTools={extraTools}
            />

            <Table verticalSpacing='sm' striped highlightOnHover>
                <TableHeaderRow
                    columns={columns as TableHeaderColumn[]}
                    handleSelectAllRows={handleSelectAllRows}
                />
                <TableBody
                    items={visibleItems}
                    loading={loading}
                    selection={selection}
                    columns={columns as TableBodyColumn[]}
                />
            </Table>

            <SimpleTablePagination
                totalPages={totalPages}
                totalCount={visibleCount}
                handlePageChange={handlePageChange}
            />
        </SimpleGrid>
    );
};
