import {
    createBrowserRouter,
    RouterProvider
} from 'react-router-dom';

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@pac/platform-ui-components/style.css'


import {AuthProvider} from './context/AuthContext';
import {ModalsProvider} from '@mantine/modals';

import {Notifications} from "@mantine/notifications";
import {MantineProvider} from "@mantine/core";
import {theme} from "./theme.ts";
import {routesConfig} from "./config/routes.tsx";
import {VerifiedPermissionsProvider} from "./context/VerifiedPermissionsProvider.tsx";
import {actions} from "./config/rbac/actions.ts";
import {UserAttributesContextProvider} from "./context/UserAttributesContext.tsx";


const App = () => {

    const router = createBrowserRouter(routesConfig);

    return (
        <MantineProvider theme={theme}>
            <AuthProvider>
                <UserAttributesContextProvider>
                    <VerifiedPermissionsProvider actions={actions}>

                        {/*<SessionTimeoutManager*/}
                        {/*    enabled={false}*/}
                        {/*    interval={60000}*/}
                        {/*/>*/}

                        <ModalsProvider>
                            <Notifications position={'bottom-left'}/>
                            <RouterProvider router={router}/>
                        </ModalsProvider>
                    </VerifiedPermissionsProvider>
                </UserAttributesContextProvider>
            </AuthProvider>
        </MantineProvider>
    );
};

export default App;
