import {stage} from './stage';

type ConfigShape = {
    [key: string]: {
        login: string,
        portal: string,
        console: string,
        status: string,
        permissions: string,
    }
}

const platform: ConfigShape = {
    prd: {
        login: 'https://login.nextcloud.aero',
        portal: 'https://portal.nextcloud.aero',
        console: 'https://console.nextcloud.aero',
        status: 'https://status.nextcloud.aero',
        permissions: 'https://permissions.nextcloud.aero',
    },
    test: {
        login: 'https://login-test.nextcloud.aero',
        portal: 'https://portal-test.nextcloud.aero',
        console: 'https://console-test.nextcloud.aero',
        status: 'https://status-test.nextcloud.aero',
        permissions: 'https://permissions-test.nextcloud.aero',
    },
    dev: {
        login: 'https://login-dev.nextcloud.aero',
        portal: 'https://portal-dev.nextcloud.aero',
        console: 'https://console-dev.nextcloud.aero',
        status: 'https://status-dev.nextcloud.aero',
        permissions: 'https://permissions-dev.nextcloud.aero',
    },
    ide: {
        login: 'https://login-dev.nextcloud.aero',
        portal: import.meta.env.VITE_HOST + ':8081',
        console: import.meta.env.VITE_HOST + ':8082',
        status: import.meta.env.VITE_HOST + ':8087',
        permissions: import.meta.env.VITE_HOST + ':8084',
    },
};

export default platform[stage]
