import {
    createContext,
    FunctionComponent,
    ReactNode,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import {
    AuthUser,
    getCurrentUser
} from 'aws-amplify/auth';
import {GuestLayout} from "../layout/GuestLayout.tsx";


type AuthContextType = {
    user: AuthUser | undefined,
}


export const AuthContext = createContext<AuthContextType>({
                                                              user: {} as AuthUser,
                                                          });

interface IAuthProvider {
    children?: ReactNode
}

export const AuthProvider: FunctionComponent<IAuthProvider> = ({children}) => {

    const [user, setUser] = useState<AuthUser | undefined>();


    useEffect(() => {
        const fetchCognitoUser = async () => {

            try {
                const result = await getCurrentUser()

                const {
                    username,
                    userId,
                    signInDetails
                } = result

                setUser({
                            username,
                            userId,
                            signInDetails
                        })


            } catch (error) {
                console.trace(
                    'AUTH CONTEXT. FETCH USER ERROR',
                    error
                );
            }
        };

        fetchCognitoUser();

    }, []);


    if (!user) {
        return (<GuestLayout/>)
    }

    return (
        <AuthContext.Provider value={{
            user,
        }}>
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node,
};
