import {NavLink} from "react-router-dom";
import {PermissionGroup} from "../types.ts";
import {PlatformPropertiesCard} from "@pac/platform-ui-components";


export const Profile = ({record}: { record: PermissionGroup }) => {

    const properties = [
        {
            label: 'Profile Name',
            value: record.name,
        },
        {
            label: 'Description',
            value: record.description,
        },
        {
            label: 'Permission Profile ID',
            value: <NavLink
                to={`/permission-profiles/${record.permissionProfileId}`}>{record.permissionProfile ? record.permissionProfile.name : record.permissionProfileId}</NavLink>,
            span: 12
        },
        {
            label: 'Entity Type ID',
            value: record.entityTypeId,
        },
        {
            label: 'Entity Identifier',
            value: record.entityIdentifier,
        },
        {
            label: 'Created By',
            value: record.createdByUsername,
        },
        {
            label: 'Managed By',
            value: record.managedByUsername,
        }, {
            label: 'Created',
            value: record.created ? record.created : 'n/a',
            formatAs: 'Timestamp',
        },
        {
            label: 'Last Updated',
            value: record.updated ? record.updated : 'n/a',
            formatAs: 'Timestamp',
        },
    ];

    return (
        <PlatformPropertiesCard cols={2} properties={properties}/>
    );


};
