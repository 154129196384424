import {FunctionComponent} from 'react';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';
import {AddPageNavigation} from "../navigation/EditPageNavigation.tsx";
import {useIdentityProvider} from "../hooks/useIdentityProvider";

export const AddPage: FunctionComponent = () => {

    const {
        record,
        handleCreateRecord
    } = useIdentityProvider();

    return (
        <FormPageLayout
            title='Add Identity Provider'
            pageNavigation={<AddPageNavigation/>}
        >
            <SimplePageContentWrapper><RecordForm record={record}
                                                  handleSubmit={handleCreateRecord}/></SimplePageContentWrapper>
        </FormPageLayout>
    );
};
