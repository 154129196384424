import {
    normalize,
    schema
} from 'normalizr';
import {RootState} from "./index";
import {
    ActionReducerMapBuilder,
    AsyncThunk
} from "@reduxjs/toolkit";
import {IReduxAction} from "../types";


export const fetchManyCase = (builder: ActionReducerMapBuilder<RootState>, asyncThunk: AsyncThunk<any, any, any>, entityKey: string) => {
    builder.addCase(asyncThunk.pending, (state: RootState) => {
        state.loading = true;
    });

    builder.addCase(asyncThunk.fulfilled, (state: RootState, action: IReduxAction) => {
        state.loading = false;

        // console.debug('IN THE HELPER', action);

        const entitySchema = new schema.Entity(entityKey);
        const arrayOfRecords = new schema.Array(entitySchema);

        const normalized = normalize(action.payload, arrayOfRecords);
        const {
            entities,
            result: ids
        } = normalized;

        // state.byId = { ...entities[entityKey] };
        // state.ids = [...new Set(ids)];

        state.byId = {...state.byId, ...entities[entityKey]};
        state.ids = [...new Set(state.ids.concat(ids))];

        state.totalCount = ids.length;
    });

    builder.addCase(asyncThunk.rejected, (state: RootState, action: IReduxAction) => {
        // state.byId = {};
        // state.ids = [];
        state.loading = false;
        state.error = action.error.message;
    });
};

export const fetchManyWithPaginationCase = (builder: ActionReducerMapBuilder<RootState>, asyncThunk: AsyncThunk<any, any, any>, entityKey: string) => {
    builder.addCase(asyncThunk.pending, (state: RootState) => {
        state.loading = true;
    });

    builder.addCase(asyncThunk.fulfilled, (state: RootState, action: IReduxAction) => {
        state.loading = false;

        const entitySchema = new schema.Entity(entityKey);
        const arrayOfRecords = new schema.Array(entitySchema);

        const normalized = normalize(action.payload.items, arrayOfRecords);
        const {
            entities,
            result: ids
        } = normalized;

        // state.byId = { ...entities[entityKey] };
        // state.ids = [...new Set(ids)];

        state.byId = {...state.byId, ...entities[entityKey]};
        state.ids = [...new Set(state.ids.concat(ids))];

        state.totalCount = action.payload.totalCount;
    });

    builder.addCase(asyncThunk.rejected, (state: RootState, action: IReduxAction) => {
        // state.byId = {};
        // state.ids = [];
        state.loading = false;
        state.error = action.error.message;
    });
};


export const fetchOneCase = (builder: ActionReducerMapBuilder<RootState>, asyncThunk: AsyncThunk<any, any, any>) => {
    builder.addCase(asyncThunk.pending, (state: RootState) => {
        state.loading = true;
    });

    builder.addCase(asyncThunk.fulfilled, (state: RootState, action: IReduxAction) => {

        state.byId[action.payload.id] = action.payload;
        state.loading = false;
    });

    builder.addCase(asyncThunk.rejected, (state: RootState, action: IReduxAction) => {
        state.loading = false;
        state.error = action.error.message;
    });
};

export const saveOneCase = (builder: ActionReducerMapBuilder<RootState>, asyncThunk: AsyncThunk<any, any, any>) => {
    builder.addCase(asyncThunk.pending, (state: RootState) => {
        state.loading = true;
    });

    builder.addCase(asyncThunk.fulfilled, (state: RootState, action: IReduxAction) => {
        const {payload: record} = action;

        if (typeof record.uuid !== 'undefined') {
            state.byId[record.uuid] = record;
            state.ids.push(record.uuid);
        } else {
            state.byId[record.id] = record;
            state.ids.push(record.id);
        }

        state.ids = [...new Set(state.ids)];
        state.totalCount = state.ids.length;

        state.loading = false;
    });

    builder.addCase(asyncThunk.rejected, (state: RootState, action: IReduxAction) => {
        state.loading = false;
        state.error = action.error.message;
    });
};

export const updateOneCase = (builder: ActionReducerMapBuilder<RootState>, asyncThunk: AsyncThunk<any, any, any>) => {
    builder.addCase(asyncThunk.pending, (state: RootState) => {
        state.loading = true;
    });

    builder.addCase(asyncThunk.fulfilled, (state: RootState, action: IReduxAction) => {
        const {payload: record} = action;

        if (typeof record.uuid !== 'undefined') {
            state.byId[record.uuid] = record;
        } else {
            state.byId[record.id] = record;
        }
        state.loading = false;
    });

    builder.addCase(asyncThunk.rejected, (state: RootState, action: IReduxAction) => {
        state.loading = false;
        state.error = action.error.message;
    });
};

export const deleteOneCase = (builder: ActionReducerMapBuilder<RootState>, asyncThunk: AsyncThunk<any, any, any>) => {
    builder.addCase(asyncThunk.pending, (state: RootState) => {
        state.loading = true;
    });

    builder.addCase(asyncThunk.fulfilled, (state: RootState, action: IReduxAction) => {
        const {payload: recordId} = action;

        delete state.byId[recordId];
        state.ids = state.ids.filter(
            (item: string) => item.toString() !== recordId.toString()
        );
        state.totalCount = state.totalCount - 1;

        state.loading = false;
    });

    builder.addCase(asyncThunk.rejected, (state: RootState, action: IReduxAction) => {
        state.loading = false;
        state.error = action.error.message;
    });
};
