import {FunctionComponent} from 'react';

import {
    PlatformTabbedComponent,
    PlatformTabComponentTab,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';
import {IndexPage as ActionsIndex} from "../../actions/pages/IndexPage";
import {IndexPage as EntityTypesIndex} from "../../entity-types/pages/IndexPage";
import {IndexPage as PermissionGroupsIndex} from "../../permission-groups/pages/IndexPage.tsx";

export const SchemaPageSegment: FunctionComponent = () => {

    const schemaTabs = [
        {
            title: 'Actions',
            component: <ActionsIndex/>
        },
        {
            title: 'Entity Types',
            component: <EntityTypesIndex/>
        },
        {
            title: 'Permission Groups',
            component: <PermissionGroupsIndex/>
        },

    ] as PlatformTabComponentTab[]


    return (
        <SimplePageContentWrapper>
            <PlatformTabbedComponent tabs={schemaTabs}></PlatformTabbedComponent>
        </SimplePageContentWrapper>
    );
};
