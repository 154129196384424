import {FunctionComponent} from 'react';

import {RecordsTable} from '../tables/RecordsTable';

import {
    SimplePageContentWrapper,
    SimplePageLayout
} from '@pac/platform-ui-components';

import {IndexPageHeader} from '../components/IndexPageHeader';

export const IndexPage: FunctionComponent = () => {
    return (
        <SimplePageLayout
            pageHeader={<IndexPageHeader/>}
        >
            <SimplePageContentWrapper><RecordsTable/></SimplePageContentWrapper>
        </SimplePageLayout>
    );
};
