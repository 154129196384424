import {I18n} from '@aws-amplify/core';

import {
    NavLink,
    useNavigate
} from 'react-router-dom';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {
    IPolicyTableColumn,
    NEXTPolicy
} from "../types.ts";
import {
    NextButton,
    NextCloseButton
} from "@pac/platform-ui-components";
import {modals} from "@mantine/modals";
import {PolicyPreviewPage} from "../pages/PolicyPreviewPage.tsx";
import {
    AiOutlineEye,
    AiOutlineProfile
} from "react-icons/ai";
import {
    Box,
    Group
} from "@mantine/core";


const PreviewComponent = ({item}: { item: NEXTPolicy }) => {

    const navigate = useNavigate()
    return (
        <NextButton
            variant="outline"
            leftSection={<AiOutlineEye/>}
            onClick={() => {
                modals.open({
                                title: 'Preview Policy',
                                size: 'xl',
                                children: (
                                    <Box>
                                        <PolicyPreviewPage permissionProfileId={item.permissionProfileId}
                                                           policyId={item.id}/>
                                        <Group justify={'flex-end'}>
                                            <NextCloseButton onClick={() => modals.closeAll()} mt="md"/>
                                            <NextButton
                                                leftSection={<AiOutlineProfile/>}
                                                onClick={() => {
                                                    modals.closeAll()
                                                    navigate(`/permission-profiles/${item.permissionProfileId}/policies/${item.id}`)
                                                }
                                                }
                                                mt="md">Open</NextButton>
                                        </Group>
                                    </Box>
                                ),
                            });
            }}
        >
            Preview
        </NextButton>
    )

}
export const columns: IPolicyTableColumn[] = [
    {
        title: I18n.get('Policy ID'),
        render: (item: NEXTPolicy) => {
            return (
                <NavLink
                    to={{
                        pathname: `/permission-profiles/${item.permissionProfileId}/policies/${item.id}`,
                    }}
                >
                    {item.policyId}
                </NavLink>
            );
        },
    },
    {
        title: I18n.get('Preview'),
        width: '1fr',
        render: (item: NEXTPolicy) => <PreviewComponent item={item}/>
    },
    {
        title: I18n.get('Description'),
        width: '1fr',
        render: (item: NEXTPolicy) => item.definition && item.definition.static ? item.definition.static.description : 'n/a'
    },
    {
        title: I18n.get('Created Date'),
        width: '1fr',
        render: (item: NEXTPolicy) => formatTimestamp(item.createdDate),
    },
];
