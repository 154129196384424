import {I18n} from '@aws-amplify/core';

import {NextDeleteButton} from '@pac/platform-ui-components';

import {usePolicyStores} from '../hooks/usePolicyStores';

import {openDeleteConfirmationModal} from "../../../components/DeleteConfirmationHandler.tsx";
import {usePolicyStoresSelection} from "../hooks/usePolicyStoresSelection.ts";


export const DeleteRecordButton = () => {

    const {
        handleDeleteRecords
    } = usePolicyStores();

    const {
        selection,
        handleClearSelection
    } = usePolicyStoresSelection();


    const recordIds = Object.keys(selection);

    let disabled = true;
    if (recordIds.length > 0) {
        disabled = false
    }


    return (
        <NextDeleteButton
            disabled={disabled}
            onClick={() => openDeleteConfirmationModal(() => {
                handleDeleteRecords();
                handleClearSelection();
            })}
        >
            {recordIds.length > 0 ? [I18n.get('Delete'), recordIds.length, 'Selected'].join(' ') : I18n.get('Delete')}
        </NextDeleteButton>
    );
};
