import {
    useEffect,
    useState
} from 'react';

import {MultiSelect} from '@mantine/core';
import {useActions} from "../hooks/useActions";

import {FieldProps} from 'formik'
import {useParams} from "react-router-dom";


export const ActionNamesMultiSelect = ({
                                           field: {
                                               name,
                                               value
                                           },
                                           form: {setFieldValue},
                                           ...rest
                                       }: FieldProps) => {

    const [disabled, setDisabled] = useState(false);

    const {id: permissionProfileId} = useParams();

    if (typeof permissionProfileId === 'undefined') {
        throw new Error('permissionProfileId is required parameter')
    }

    const {
        items: allActions,
        handleFetchRecords
    } = useActions(permissionProfileId)

    useEffect(() => {
        handleFetchRecords();
    }, []);

    useEffect(() => {
        if (allActions.length === 0) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [allActions.length]);


    if (allActions.length === 0) {
        return null;
    }

    console.debug('allActions', allActions, value)

    const data = allActions.map((action: { id: string, name: string }) => {
        return {
            ...action,
            value: action.name,
            label: action.name
        };
    });

    const handleChange = (value: string[]) => {
        const actionNames = value.map(valueItem => {
            const found = data.find((item: { name: string }) => item.name === valueItem);
            if (found) {
                return found.name;
            }
        })
        setFieldValue(name, actionNames);
    };

    return (
        <MultiSelect
            data={data}
            value={value}
            onChange={handleChange}
            disabled={disabled}
            searchable
            {...rest}
        />
    );
};

