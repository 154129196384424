import {Field} from 'formik';

import {I18n} from '@aws-amplify/core';

import {SimpleGrid} from '@mantine/core';

import {PacFormikInput} from '@pac/platform-ui-components';
import {EntityTypeNamesAutocomplete} from '../../entity-types/inputs/EntityTypeNamesAutocomplete';
import {ActionNamesAutocomplete} from '../../actions/inputs/ActionNamesAutocomplete';

export const FormComponent = () => {

    return (
        <SimpleGrid cols={1}>

            <Field
                name='action'
                label='Action'
                placeholder={I18n.get('Authorization Request Action')}
                component={ActionNamesAutocomplete}
            />

            <Field
                name='context'
                label='Context'
                placeholder={I18n.get('Authorization Request Context')}
                component={PacFormikInput}
            />

            <Field
                name='principal'
                label='Principal'
                placeholder={I18n.get('Authorization Request Principal')}
                component={EntityTypeNamesAutocomplete}
            />

            <Field
                name='resource'
                label='Resource'
                placeholder={I18n.get('Authorization Request Resource')}
                component={EntityTypeNamesAutocomplete}
            />

            <Field
                name='sliceComplement'
                label='Slice Complement'
                placeholder={I18n.get('Authorization Request Slice Complement')}
                component={PacFormikInput}
            />

        </SimpleGrid>
    );
};

