import {
    add as post,
    fetch as get,
    list,
    remove,
    update as put
} from "../../../services/BaseService.tsx";


const apiName = 'TenantManagementServiceAPI';
const collectionPath = '/tenants';

export const fetchRecords = (query: object) => {
    return list(apiName, collectionPath, query) as Promise<any>
};

export const fetch = (id: string) => {
    return get(apiName, collectionPath, id)
};

export const add = (payload: object) => {
    return post(apiName, collectionPath, payload as FormData)
};

export const update = (id: string, payload: object) => {
    return put(apiName, collectionPath, id, payload)
};

export const deleteRecord = (id: string) => {
    return remove(apiName, collectionPath, id)
};
