import {
    useEffect,
    useState
} from 'react';

import {Autocomplete} from '@mantine/core';
import {FieldProps} from 'formik'
import {useTenants} from "../hooks/useTenants";
import {Tenant} from "../types.ts";


export const TenantsAutocomplete = ({
                                        field: {
                                            name,
                                            value: fieldValue
                                        },
                                        form: {setFieldValue},
                                        ...rest
                                    }: FieldProps) => {

    const {
        items: records,
        handleFetchRecords
    } = useTenants()

    const [value, setValue] = useState('');
    const [disabled, setDisabled] = useState(false);


    useEffect(() => {
        handleFetchRecords();
    }, []);

    const data = records.map((tenant: Tenant) => {
        return {
            ...tenant,
            value: tenant.name,
        };
    });

    useEffect(() => {
        if (records.length === 0) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
        const found = records.find((item: Tenant) => item.id === fieldValue);
        if (typeof found !== 'undefined' && Object.prototype.hasOwnProperty.call(found, 'name')) {
            setValue(found.name);
        }
    }, [records.length]);

    const handleChange = (value: string) => {
        if (!Array.isArray(data)) {
            throw new Error('Data is expected to be an array');
        }
        const {id} = data.find((item) => item.name === value) || {};

        if (typeof id !== 'undefined') {
            setFieldValue(name, id);
        }

        setValue(value);
    };

    return (
        <Autocomplete
            name={name}
            value={value}
            onChange={handleChange}
            data={data}
            disabled={disabled}
            placeholder="Select Tenant"
            {...rest}
        />
    );
};
