import {
    IdentitySource,
    IIdentitySourceTableColumn
} from "../types.ts";
import {RowSelectionDataCell} from "@pac/platform-ui-components";
import {useIdentitySources} from "./useIdentitySources.ts";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {I18n} from "@aws-amplify/core";
import {NavLink} from "react-router-dom";
import {rbacActions} from "../../../config/rbac/actions.ts";

export const useRecordsTableColumns = (permissionProfileId: string) => {

    const {allowedActions} = useVerifiedPermissions()

    const {
        handleRowSelection,
    } = useIdentitySources(permissionProfileId);


    const renderRowSelectionDataCell = (item: IdentitySource) => (
        <RowSelectionDataCell item={item} handleChange={handleRowSelection}/>
    );

    const columns: IIdentitySourceTableColumn[] = [];
    if (allowedActions.includes(rbacActions.DeleteIdentitySource)) {
        columns.push({
                         id: 'select',
                         title: '',
                         render: renderRowSelectionDataCell,
                     },
        );
    }

    columns.push({
                     id: 'identitySourceId',
                     title: I18n.get('IdentitySource ID'),
                     render: (item: IdentitySource) => {
                         return (
                             <NavLink
                                 to={{
                                     pathname: `/permission-profiles/${item.permissionProfileId}/identity-sources/${item.id}`,
                                 }}
                             >
                                 {item.identitySourceId}
                             </NavLink>
                         );
                     },
                 },
                 {
                     id: 'userPoolArn',
                     title: I18n.get('User Pool Arn'),
                     width: '1fr',
                     render: (item: IdentitySource) => item.userPoolArn ? item.userPoolArn : 'n/a'
                 },
    );

    return {
        columns
    }


}