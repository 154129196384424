import {RootState} from "../../store";
import {visibilityFilter} from "../../utils/VisibilityFilter.ts";
import {NEXTUser} from "./types.ts";
import {moduleConfig} from "./config";
import {tenantSelector} from "../tenants";

const key = moduleConfig.entityKey;

export const recordsSelector = (state: RootState) => {
    return state[key].records;
};


export const recordSelector = (state: RootState, id: string) => {
    const records = recordsSelector(state);
    const loading = records.loading;
    const record = records.byId[id];
    return {
        record,
        loading
    } as { record: NEXTUser, loading: boolean }
};

export const selectionSelector = (state: RootState) => {
    return state[key].selection;
};

export const visibilitySelector = (state: RootState) => {
    const items = usersSelector(state);
    const page = state[key].visibility.page;
    const limit = state[key].visibility.limit;
    const keyword = state[key].visibility.keyword;
    const visible = state[key].visibility.visible;
    const columns = state[key].visibility.columns;

    const visibleItems = visibilityFilter(items, page, limit, keyword);
    // const visibleItems = items

    const totalCount = state[key].records.totalCount;

    let visibleCount = totalCount;
    if (keyword) {
        visibleCount = visibleItems.length;
    }

    // const totalPages = Math.ceil(visibleCount / limit);
    const totalPages = Math.ceil(totalCount / limit);

    return {
        items: items,
        visibleItems: visibleItems,
        page: page,
        limit: limit,
        keyword: keyword,
        visible: visible,
        columns: columns,
        visibleCount: visibleCount,
        totalPages: totalPages,
    };
};

export const usersSelector = (state: RootState) => {
    return state.users.records.ids.map((id: string) => {
        const record = state.users.records.byId[id];
        return updateUserTenant(state, record);
    });
};

export const userSelector = (state: RootState, id: string): NEXTUser => {
    const record = state[key].records.byId[id];

    return updateUserTenant(state, record);
};

export const userWithCognitoUserIdSelector = (state: RootState, cognitoUserId: string) => {
    const records = state.users.records.ids.map((id: string) => {
        return state.users.records.byId[id];
    });

    return records.find((item: { cognitoUserId: string }) => item.cognitoUserId === cognitoUserId);
};

const updateUserTenant = (state: RootState, record: NEXTUser) => {
    if (typeof record === 'undefined') {
        return record;
    }

    const tenantId = record['tenant-id'];

    const tenant = tenantSelector(state, tenantId);

    if (typeof tenant !== 'undefined' && Object.prototype.hasOwnProperty.call(tenant, 'name')) {
        return Object.assign({}, record, {
            tenantName: tenant.name,
        });
    }

    return record;
};

export const usersCountSelector = (state: RootState) => {
    return state.users.records.totalCount;
};

export const usersByTenantIdSelector = (state: RootState, tenantId: string) => {
    const users: NEXTUser[] = [];
    state.users.records.ids.forEach((id: string) => {
        const user = state.users.records.byId[id];
        if (user['tenant-id'] === tenantId) {
            users.push(user);
        }
    });
    return users;
};
