import {
    IActionTableColumn,
    NEXTAction
} from "../types.ts";
import {RowSelectionDataCell} from "@pac/platform-ui-components";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {I18n} from "@aws-amplify/core";
import {NavLink} from "react-router-dom";
import {useActions} from "./useActions.ts";
import {rbacActions} from "../../../config/rbac/actions.ts";

export const useRecordsTableColumns = (permissionProfileId: string) => {

    const {allowedActions} = useVerifiedPermissions()

    const {
        handleRowSelection,
    } = useActions(permissionProfileId);


    const renderRowSelectionDataCell = (item: NEXTAction) => (
        <RowSelectionDataCell item={item} handleChange={handleRowSelection}/>
    );

    const columns: IActionTableColumn[] = [];
    if (allowedActions.includes(rbacActions.DeleteAction)) {
        columns.push({
                         id: 'select',
                         title: '',
                         render: renderRowSelectionDataCell,
                     },
        );
    }

    columns.push(
        {
            title: I18n.get('Action Name'),
            render: (item: NEXTAction) => {
                return (
                    <NavLink
                        to={{
                            pathname: `/permission-profiles/${item.permissionProfileId}/actions/${item.id}`,
                        }}
                    >
                        {item.name}
                    </NavLink>
                );
            },
        },
        {
            title: I18n.get('Principal Types'),
            width: '1fr',
            render: (item: NEXTAction) => {
                return JSON.stringify(item.principalTypeIds, null, 2)
            },
        },
        {
            title: I18n.get('Resource Types'),
            width: '1fr',
            render: (item: NEXTAction) => {
                return JSON.stringify(item.resourceTypeIds, null, 2)
            },
        },
    );

    return {
        columns
    }


}