import {FunctionComponent} from 'react';

import {Stack} from '@mantine/core';

import {
    PlatformTabbedComponent,
    PlatformTabComponentTab,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';
import {IndexPage as IdentitySourcesIndex} from "../../identity-sources/pages/IndexPage";

export const SettingsPageSegment: FunctionComponent = () => {


    const groupsTabs = [
        {
            title: 'Identity Sources',
            component: <IdentitySourcesIndex/>
        },
    ] as PlatformTabComponentTab[]

    return (
        <Stack gap='lg'>

            <SimplePageContentWrapper>
                <PlatformTabbedComponent
                    tabs={groupsTabs}></PlatformTabbedComponent>
            </SimplePageContentWrapper>


        </Stack>

    );
};
