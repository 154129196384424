import {useParams} from "react-router-dom";
import {PortalDashboard} from "../components/PortalDashboard.tsx";
import {SimplePageLayout} from "@pac/platform-ui-components";
import {Title} from "@mantine/core";


export const DetailPage = () => {

    const {id: permissionProfileId} = useParams();

    if (typeof permissionProfileId === 'undefined') {
        throw new Error('permissionProfileId is required parameter')
    }

    return (

        <SimplePageLayout pageHeader={(<Title order={1}>Policy Store Dashboard {permissionProfileId}</Title>)}>
            <PortalDashboard/>
        </SimplePageLayout>
    )

};
