import {
    Field,
    useFormikContext
} from 'formik';

import {I18n} from '@aws-amplify/core';

import {
    Center,
    Fieldset,
    Grid,
    SimpleGrid,
    Stack,
    Text
} from '@mantine/core';

import {
    PacFormikInput,
    PacFormikRadioGroup
} from '@pac/platform-ui-components';

import {ActionNamesMultiSelect} from '../../../actions/inputs/ActionNamesMultiSelect';
import {EntityTypeNamesAutocomplete} from "../../../entity-types/inputs/EntityTypeNamesAutocomplete.tsx";
import {UsersAutocomplete} from "../../../users";
import {TenantsAutocomplete} from "../../../tenants/inputs/TenantsAutocomplete.tsx";
import {TenantsMultiSelect} from "../../../tenants/inputs/TenantsMultiSelect.tsx";
import {UsersMultiSelect} from "../../../users/inputs/UsersMultiSelect.tsx";
import {PermissionGroupNamesAutocomplete} from "../../../permission-groups/inputs/PermissionGroupNamesAutocomplete.tsx";
import {NEXTPolicyFormValues} from "../../types.ts";


export const PolicyScope = () => {

    const {
        values,
    } = useFormikContext<NEXTPolicyFormValues>()

    // console.debug('PolicyScope values', values)

    let principalEntityIdComponent = null;
    if (values && values.scope.principals.value) {
        switch (values.scope.principals.value.entityType) {
            case 'User':
                principalEntityIdComponent = UsersAutocomplete
                break;
            case 'Tenant':
                principalEntityIdComponent = TenantsAutocomplete
                break;
            default:
                principalEntityIdComponent = PacFormikInput
        }
    }

    let groupOfPrincipalEntityIdsComponent = null;
    if (values && values.scope.principals.value) {
        switch (values.scope.principals.value.entityType) {
            case 'CognitoGroup':
                groupOfPrincipalEntityIdsComponent = PermissionGroupNamesAutocomplete
                break;
            default:
                groupOfPrincipalEntityIdsComponent = PermissionGroupNamesAutocomplete
        }
    }

    let resourceEntityIdComponent = null;
    if (values && values.scope.resources.value) {
        switch (values.scope.resources.value.entityType) {
            case 'User':
                resourceEntityIdComponent = UsersAutocomplete
                break;
            case 'Tenant':
                resourceEntityIdComponent = TenantsAutocomplete
                break;
            default:
                resourceEntityIdComponent = PacFormikInput
        }
    }

    let groupOfResourceEntityIdsComponent = null;
    if (values && values.scope.resources.value) {
        switch (values.scope.resources.value.entityType) {
            case 'User':
                groupOfResourceEntityIdsComponent = UsersMultiSelect
                break;
            case 'Tenant':
                groupOfResourceEntityIdsComponent = TenantsMultiSelect
                break;
            default:
                groupOfResourceEntityIdsComponent = PacFormikInput
        }
    }

    return (
        <Stack gap={'xl'}>
            <Fieldset fw={600} tt={'uppercase'} legend={'Principals Scope'}>

                <Field
                    name="scope.principals.type"
                    label={''}
                    options={[{
                        label: 'All',
                        value: 'all'
                    }, {
                        label: 'Group of principals',
                        value: 'group'
                    }, {
                        label: 'Specific principal',
                        value: 'specific'
                    }]}
                    component={PacFormikRadioGroup}
                    my={'xl'}
                />

                {values.scope.principals.type === 'specific' ?
                    <>
                        <Text fw={600}>Principal</Text>
                        <SimpleGrid cols={2}>
                            <Field
                                name='scope.principals.value.entityType'
                                placeholder={I18n.get('Principal Entity Type')}
                                component={EntityTypeNamesAutocomplete}
                                my={'xl'}
                            />
                            {values.scope.principals.value && values.scope.principals.value.entityType != '' ?
                                <Field
                                    name='scope.principals.value.entityId'
                                    placeholder={I18n.get('Principal Entity ID')}
                                    component={principalEntityIdComponent}
                                    my={'xl'}
                                /> : null}
                        </SimpleGrid></> : null
                }

                {values.scope.principals.type === 'group' ?
                    <>
                        <Text fw={600}>Principal</Text>
                        <Grid>
                            <Grid.Col span={5}>
                                <Field
                                    name='scope.principals.value.entityType'
                                    placeholder={I18n.get('Principal Entity Type')}
                                    component={EntityTypeNamesAutocomplete}
                                    my={'xl'}
                                />
                            </Grid.Col>
                            {values.scope.principals.value && values.scope.principals.value.entityType != '' ? (
                                <>
                                    <Grid.Col span={1}>
                                        <Center>
                                            <Text fw={800} my={'xl'}>IN</Text>
                                        </Center>
                                    </Grid.Col>

                                    <Grid.Col span={5}>
                                        <Field
                                            name='scope.principals.value.entityId'
                                            placeholder={I18n.get('Principal Entity ID')}
                                            component={groupOfPrincipalEntityIdsComponent}
                                            my={'xl'}
                                        /></Grid.Col>
                                </>
                            ) : null}
                        </Grid>
                    </> : null
                }

            </Fieldset>
            <Fieldset fw={600} tt={'uppercase'} legend={'Actions Scope'}>

                <Field
                    name="scope.actions.type"
                    label={''}
                    options={[{
                        label: 'All',
                        value: 'all'
                    }, {
                        label: 'Specific set of actions',
                        value: 'set'
                    }]}
                    component={PacFormikRadioGroup}
                    my={'xl'}
                />

                {values.scope.actions.type === 'set' ?
                    <>
                        <Text fw={600}>Actions</Text>
                        <Field
                            name='scope.actions.value'
                            label=''
                            placeholder={I18n.get('Actions')}
                            component={ActionNamesMultiSelect}
                            my={'xl'}
                        />
                    </> : null}

            </Fieldset>

            <Fieldset fw={600} tt={'uppercase'} legend={'Resource Scope'}>

                <Field
                    name="scope.resources.type"
                    label={''}
                    options={[{
                        label: 'All',
                        value: 'all'
                    }, {
                        label: 'Group of resources',
                        value: 'group'
                    }, {
                        label: 'Specific resource',
                        value: 'specific'
                    }]}
                    component={PacFormikRadioGroup}
                    my={'xl'}
                />

                {values.scope.resources.type === 'specific' ?
                    <>
                        <Text fw={600}>Resource</Text>
                        <SimpleGrid cols={2}>
                            <Field
                                name='scope.resources.value.entityType'
                                placeholder={I18n.get('Resource Entity Type')}
                                component={EntityTypeNamesAutocomplete}
                                my={'xl'}
                            />

                            {values.scope.resources.value && values.scope.resources.value.entityType != '' ? <Field
                                name='scope.resources.value.entityId'
                                placeholder={I18n.get('Resource Entity ID')}
                                component={resourceEntityIdComponent}
                                my={'xl'}
                            /> : null}

                        </SimpleGrid></> : null}

                {values.scope.resources.type === 'group' ?
                    <>
                        <Text fw={600}>Resource</Text>
                        <Grid>
                            <Grid.Col span={5}>
                                <Field
                                    name='scope.resources.value.entityType'
                                    placeholder={I18n.get('Resource Entity Type')}
                                    component={EntityTypeNamesAutocomplete}
                                    my={'xl'}
                                />
                            </Grid.Col>

                            {values.scope.resources.value && values.scope.resources.value.entityType != '' ? (
                                <>
                                    <Grid.Col span={1}>
                                        <Center>
                                            <Text fw={800} my={'xl'}>IN</Text>
                                        </Center>
                                    </Grid.Col>

                                    <Grid.Col span={5}><Field
                                        name='scope.resources.value.entityIds'
                                        placeholder={I18n.get('Resource Entity ID')}
                                        component={groupOfResourceEntityIdsComponent}
                                        my={'xl'}
                                    /></Grid.Col>
                                </>
                            ) : null}

                        </Grid></> : null}

            </Fieldset>

        </Stack>
    );
};
