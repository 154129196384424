import {
    NavLink as ReactRouteNavLink,
    useParams
} from 'react-router-dom';
import {usePolicyTemplates} from "../../../policy-templates";
import {useEffect} from "react";
import {
    Group,
    Text
} from "@mantine/core";
import {PolicyTemplateIcon} from "../../../policy-templates/images/PolicyTemplateIcon.tsx";
import {DashboardWidgetBuilder} from "@pac/platform-ui-components";
import {useVerifiedPermissions} from "../../../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../../../config/rbac/actions.ts";


export const PolicyTemplatesWidget = () => {

    const {id: permissionProfileId} = useParams();

    if (typeof permissionProfileId === 'undefined') {
        throw new Error('permissionProfileId is required parameter')
    }

    const {
        visibleCount,
        handleFetchRecords: fetchPolicyTemplates
    } = usePolicyTemplates(permissionProfileId)


    const {allowedActions} = useVerifiedPermissions()

    useEffect(() => {
        if (allowedActions.includes(rbacActions.ListPolicyTemplates)) {
            fetchPolicyTemplates();
        }

    }, []);

    const widget = {
        header: {
            content: <Group p={'sm'}><PolicyTemplateIcon/><Text>PolicyTemplates</Text></Group>
        },
        body: {
            dataPoints: [
                {
                    value: visibleCount,
                    label: 'Policy Templates',
                    color: '#194a74',
                },
            ],
            metrics: [],
        },
        footer: {
            actionItems: [
                {
                    label: 'All Policy Templates',
                    component: {ReactRouteNavLink},
                    to: `/permission-profiles/${permissionProfileId}/#policy-templates`,
                    visible: true,
                },
                {
                    label: 'Add Policy Template',
                    component: {ReactRouteNavLink},
                    to: `/permission-profiles/${permissionProfileId}/policy-templates/add`,
                    visible: true,
                },
            ],
        }
    };

    return <DashboardWidgetBuilder widget={widget}/>;
};
