import {Field} from 'formik';

import {I18n} from '@aws-amplify/core';

import {
    Fieldset,
    Stack
} from '@mantine/core';

import {PacFormikTextarea} from '@pac/platform-ui-components';


export const PolicyDescription = () => {

    return (
        <Stack gap={'xl'}>
            <Fieldset fw={600} tt={'uppercase'} legend={'Policy Description'}>
                <Field
                    name='policyDescription'
                    placeholder={I18n.get('Policy Description')}
                    component={PacFormikTextarea}
                    my={'xl'}
                />
            </Fieldset>
        </Stack>
    );
};
