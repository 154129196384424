import {FunctionComponent} from 'react';

import {
    PlatformTabbedComponent,
    PlatformTabComponentTab,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';

import {IndexPage as PoliciesIndex} from "../../policies/pages/IndexPage";
import {IndexPage as PolicyTemplatesIndex} from "../../policy-templates/pages/IndexPage";

export const PoliciesPageSegment: FunctionComponent = () => {

    const tabs = [
        {
            title: 'Policies',
            component: <PoliciesIndex/>
        },
        {
            title: 'Policy Templates',
            component: <PolicyTemplatesIndex/>
        },
    ] as PlatformTabComponentTab[]


    return (
        <SimplePageContentWrapper>
            <PlatformTabbedComponent tabs={tabs}></PlatformTabbedComponent>
        </SimplePageContentWrapper>
    );
};
