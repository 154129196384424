import {I18n} from '@aws-amplify/core';

import {NavLink} from 'react-router-dom';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {
    IPermissionProfileTableColumn,
    PermissionProfile
} from "../types.ts";


export const columns: IPermissionProfileTableColumn[] = [
    {
        title: I18n.get('Permission Profile Name'),
        render: (item: PermissionProfile) => {
            return (
                <NavLink
                    to={{
                        pathname: `/permission-profiles/${item.id}`,
                    }}
                >
                    {item.name}
                </NavLink>
            );
        },
    },
    {
        title: I18n.get('Deployment Environment'),
        render: (item: PermissionProfile) => {

            return (
                <NavLink
                    to={{
                        pathname: `/deployment-environments/${item.deploymentEnvironmentId}`,
                    }}
                >
                    {item.deploymentEnvironment ? item.deploymentEnvironment?.platformAccount?.name : item.deploymentEnvironmentId}
                </NavLink>
            );
        },
    },
    {
        title: I18n.get('Created By'),
        width: '1fr',
        render: (item: PermissionProfile) =>
            ((typeof item.createdBy !== 'undefined' && item.createdBy !== null)
                ? [item.createdBy.givenName, item.createdBy.familyName].join(
                    ' ',
                )
                : item.createdByUsername),
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: PermissionProfile) => formatTimestamp(item.created),
    },
];
