import {I18n} from '@aws-amplify/core';

import {NextDeleteButton} from '@pac/platform-ui-components';

import {useParams} from 'react-router-dom';

import {usePermissionGroups} from '../hooks/usePermissionGroups';

import {openDeleteConfirmationModal} from "../../../components/DeleteConfirmationHandler.tsx";
import {usePermissionGroupsSelection} from "../hooks/usePermissionGroupsSelection.ts";


export const DeleteRecordButton = () => {
    const {id: permissionProfileId} = useParams();

    if (typeof permissionProfileId === 'undefined') {
        throw new Error('permissionProfileId is required parameter')
    }

    const {
        handleDeleteRecords
    } = usePermissionGroups(permissionProfileId);

    const {
        selection,
        handleClearSelection
    } = usePermissionGroupsSelection(permissionProfileId);


    const recordIds = Object.keys(selection);

    let disabled = true;
    if (recordIds.length > 0) {
        disabled = false
    }


    return (
        <NextDeleteButton
            disabled={disabled}
            onClick={() => openDeleteConfirmationModal(() => {
                handleDeleteRecords();
                handleClearSelection();
            })}
        >
            {recordIds.length > 0 ? [I18n.get('Delete'), recordIds.length, 'Selected'].join(' ') : I18n.get('Delete')}
        </NextDeleteButton>
    );
};

