import {Formik} from 'formik';

import {FormComponent} from './FormComponent';

import {RecordSchema} from './RecordSchema';

import {FormWithButtons} from '@pac/platform-ui-components';

import {
    useNavigate,
    useParams
} from 'react-router-dom';

import {stage} from '../../../config/stage';
import {NEXTAuthorizationRequest} from "../types.ts";

type RecordFormProps = {
    record: object,
    handleSubmit: (values: NEXTAuthorizationRequest) => void
}
export const RecordForm = ({
                               record,
                               handleSubmit
                           }: RecordFormProps) => {
    const navigate = useNavigate();

    const {id: permissionProfileId} = useParams();

    const onSubmit = (values: NEXTAuthorizationRequest) => {

        const merged = Object.assign({}, values, {
            permissionProfileId: permissionProfileId
        })
        handleSubmit(merged);

        navigate(`/permission-profiles/${permissionProfileId}`);
    };

    const onCancel = () => {
        navigate(`/permission-profiles/${permissionProfileId}`);
    };


    let initialValues = {} as NEXTAuthorizationRequest;

    if (record) {
        initialValues = Object.assign({}, initialValues, record);
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={RecordSchema}
            onSubmit={onSubmit}
        >
            {({
                  handleSubmit,
                  isSubmitting,
                  ...rest
              }) => (
                <FormWithButtons handleSubmit={handleSubmit} isSubmitting={isSubmitting}
                                 handleCancel={onCancel} debug={false && stage === 'ide'} {...rest}  >
                    <FormComponent/>
                </FormWithButtons>)}
        </Formik>
    );
};

