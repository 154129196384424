import {visibilityFilter} from '../../utils/VisibilityFilter';
import {RootState} from "../../store";
import {NEXTPolicy} from "./types.ts";
import {moduleConfig} from "./config";
import {recordSelector as permissionProfileRecordSelector} from "../permission-profiles";

const key = moduleConfig.entityKey;

export const recordsSelector = (state: RootState) => {
    return state[key].records;
};

export const recordSelector = (state: RootState, id: string) => {
    const records = recordsSelector(state);
    const loading = records.loading;
    const record = records.byId[id];
    return {
        record,
        loading
    };
};

export const selectionSelector = (state: RootState) => {
    return state[key].selection;
};

export const visibilitySelector = (state: RootState, permissionProfileId: string, searchParams: URLSearchParams | null = null) => {
    const itemsBeforeFilter = policiesSelector(state, permissionProfileId);

    let items: NEXTPolicy[] = [];
    if (searchParams?.has('element')) {
        if (searchParams.get('element') && searchParams.get('entityType')) {

            const element = searchParams.get('element');
            const entityType = searchParams.get('entityType')
            const entityId = searchParams.get('entityId')

            itemsBeforeFilter.forEach((item: NEXTPolicy) => {
                if (element === 'Principal') {
                    if (item.principal.entityType === entityType && item.principal.entityId === entityId) {
                        items.push(item)
                    }
                }
                if (element === 'Resource') {
                    if (item.resource && item.resource.entityType === entityType && item.resource.entityId === entityId) {
                        items.push(item)
                    }
                }
            })
        }
    } else {
        items = [].concat(itemsBeforeFilter)
    }


    const page = state[key].visibility.page;
    const limit = state[key].visibility.limit;
    const keyword = state[key].visibility.keyword;
    const visible = state[key].visibility.visible;
    const columns = state[key].visibility.columns;

    const visibleItems = visibilityFilter(items, page, limit, keyword);


    const totalCount = state[key].records.totalCount;

    let visibleCount = totalCount;
    if (keyword) {
        visibleCount = visibleItems.length;
    }

    const totalPages = Math.ceil(visibleCount / limit);

    return {
        items: items,
        visibleItems: visibleItems,
        page: page,
        limit: limit,
        keyword: keyword,
        visible: visible,
        columns: columns,
        visibleCount: visibleCount,
        totalPages: totalPages,
    };
};

export const policiesSelector = (state: RootState, permissionProfileId: string) => {
    const all = state[key].records.ids.map((id: string) => {
        return state[key].records.byId[id];
    });

    // permission profile
    const {record: permissionProfile} = permissionProfileRecordSelector(state, permissionProfileId);

    return all.filter((item: { policyStoreId: string }) => item.policyStoreId === permissionProfile.policyStoreId);
};