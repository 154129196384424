import {BrowserStorageCache} from '@aws-amplify/cache';

import config from '../config';

const METADATA_LOCAL_STORAGE_KEY = '-pac-app-metadata';
const METADATA_LOCAL_STORAGE_VALUE = {
    version: 1,
};

const LATEST_APP_VERSION = config.about.latestVersion;

export const metadata = BrowserStorageCache.getItem(METADATA_LOCAL_STORAGE_KEY, {
    callback: () => {
        return METADATA_LOCAL_STORAGE_VALUE;
    },
});

if (metadata.version < LATEST_APP_VERSION) {
    // clear
    BrowserStorageCache.clear();
    // update version
    metadata.version = LATEST_APP_VERSION;
    // store
    BrowserStorageCache.setItem(METADATA_LOCAL_STORAGE_KEY, metadata);
}

