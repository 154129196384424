import {useEffect} from 'react';

import {MultiSelect} from '@mantine/core';
import {FieldProps} from 'formik'
import {useUsers} from "../hooks/useUsers.ts";
import {NEXTUser} from "../types.ts";

export const UsersMultiSelect = ({
                                     field: {
                                         name,
                                         value
                                     },
                                     form: {setFieldValue},
                                     ...rest
                                 }: FieldProps) => {


    const {
        items: records,
        handleFetchRecords
    } = useUsers()


    useEffect(() => {
        handleFetchRecords({});
    }, []);

    const data = records.map((user: NEXTUser) => {
        return {
            value: user.id,
            label: user.name ? user.name : [user['first-name'], user['last-name']].join(' ')
        };
    });

    const handleChange = (value: string[]) => {
        setFieldValue(name, value);
    };

    return (
        <MultiSelect
            data={data}
            value={value}
            onChange={handleChange}
            searchable
            {...rest}
        />
    );
};
