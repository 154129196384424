import {useNavigate} from 'react-router-dom';

import {Button} from '@mantine/core';
import {useToggle} from '@mantine/hooks';
import {
    NextCloseButton,
    NextEditButton
} from "@pac/platform-ui-components";
import {AiOutlineProfile} from 'react-icons/ai';
import {IconContext} from 'react-icons';
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../../config/rbac/actions.ts";

export const useEditPageTools = () => {

    const navigate = useNavigate();


    const {allowedActions} = useVerifiedPermissions()

    const [mode, toggle] = useToggle(['view', 'edit']);


    const tools = [];

    if (allowedActions.includes(rbacActions.UpdateDeploymentEnvironment)) {
        if (mode === 'edit') {
            tools.push(<Button radius='xs' variant={'filled'} key={'profile'}
                               leftSection={<IconContext.Provider value={{size: '24px'}}>
                                   <AiOutlineProfile/></IconContext.Provider>}
                               onClick={() => toggle()}>Profile</Button>);
        } else {

            tools.push(<NextEditButton variant={'filled'} key={'edit'}
                                       onClick={() => toggle()}>Edit</NextEditButton>);

        }
    }

    tools.push(<NextCloseButton variant={'outline'} key={'close'}
                                onClick={() => navigate(`/deployment-environments`)}>Close</NextCloseButton>);


    return {
        mode,
        tools
    };

};
