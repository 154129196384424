import {
    FunctionComponent,
    useEffect,
    useState
} from 'react';

import {useParams} from 'react-router-dom';

import {
    Box,
    Center,
    Grid,
    SegmentedControl
} from '@mantine/core';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    PlatformLoader,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';

import {usePermissionProfile} from '../hooks/usePermissionProfile';

import {EditPageNavigation} from '../navigation/EditPageNavigation.tsx';
import {Profile} from "../profile/Profile";

import {useEditPageTools} from "../hooks/useEditPageTools";
import {PolicyIcon} from "../../policies/images/PolicyIcon.tsx";
import {AuthorizationRequestIcon} from "../../authorization-requests/images/AuthorizationRequestIcon.tsx";
import {AiOutlineSetting} from "react-icons/ai";
import {MdOutlineSchema} from "react-icons/md";
import {PoliciesPageSegment} from "../page-segments/PoliciesPageSegment.tsx";
import {SchemaPageSegment} from '../page-segments/SchemaPageSegment.tsx';
import {SettingsPageSegment} from "../page-segments/SettingsPageSegments.tsx";
import {AuthorizationRequestsPageSegment} from "../page-segments/AuthorizationRequestsPageSegment.tsx";

export const EditPage: FunctionComponent = () => {

    const {
        id: permissionProfileId
    } = useParams();

    const {
        record: permissionProfile,
        handleFetchRecord,
        handleUpdateRecord
    } = usePermissionProfile(permissionProfileId);


    const {
        mode,
        tools
    } = useEditPageTools();


    useEffect(() => {
        handleFetchRecord()
    }, [])


    // enable / disable transactions
    const showTransactions = false

    const [segmentedControlValue, setSegmentedControlValue] = useState('policies');

    const segmentedControlData = [
        {
            label: <Center><PolicyIcon/><Box ml={'sm'}>Policies</Box></Center>,
            value: 'policies'
        },
        {
            label: <Center><MdOutlineSchema/><Box ml={'sm'}>Schema</Box></Center>,
            value: 'schema'
        },
        {
            label: <Center><AiOutlineSetting size={'1.6rem'}/><Box ml={'sm'}>Settings</Box></Center>,
            value: 'settings'
        },

        ...(showTransactions ? [{
            label: <Center><AuthorizationRequestIcon/><Box
                ml={'sm'}>Authorization Requests</Box></Center>,
            value: 'transactions'
        }] : []),
    ];

    if (!permissionProfile) {
        return <PlatformLoader message='Loading permission profile...Please wait'/>;
    }


    return (
        <FormPageLayout
            title={(`${permissionProfile.name}`)}
            pageNavigation={<EditPageNavigation record={permissionProfile}/>}
            tools={tools}
        >

            {mode === 'view' ?
                <Grid>
                    <Grid.Col span={{
                        base: 4,
                        sm: 12
                    }}>

                        <Profile record={permissionProfile}/>

                    </Grid.Col>
                    <Grid.Col span={{
                        base: 8,
                        sm: 12
                    }}>

                        <>
                            <SegmentedControl
                                mb={'sm'}
                                value={segmentedControlValue}
                                onChange={setSegmentedControlValue}
                                data={segmentedControlData}
                            />

                            {segmentedControlValue === 'policies' ? <PoliciesPageSegment/> : null}
                            {segmentedControlValue === 'schema' ? <SchemaPageSegment/> : null}
                            {segmentedControlValue === 'settings' ? <SettingsPageSegment/> : null}
                            {segmentedControlValue === 'transactions' ? <AuthorizationRequestsPageSegment/> : null}
                        </>

                    </Grid.Col>
                </Grid>
                :
                <SimplePageContentWrapper>
                    <RecordForm record={permissionProfile} handleSubmit={handleUpdateRecord}/>
                </SimplePageContentWrapper>}

        </FormPageLayout>
    );
};
