import {
    getPlatformAccount,
    listPlatformAccounts
} from './queries';

import {
    GetPlatformAccount,
    ListPlatformAccounts
} from "../types.ts";

import {notifyError} from "../../../components/ServiceResponseNotifications.tsx";
import {
    generateClient,
    GraphQLQuery
} from "@aws-amplify/api";

const client = generateClient();


export const fetchRecords = async (query: { [k: string]: any }) => {

    let filter = {}
    if (query.filter) {
        filter = query.filter
    }


    try {
        const response = await client.graphql<GraphQLQuery<ListPlatformAccounts>>({
                                                                                      query: listPlatformAccounts,
                                                                                      variables: {
                                                                                          filter: filter,
                                                                                      }
                                                                                  });

        return response.data?.listPlatformAccounts.items;
    } catch (e: any) {
        notifyError(e);
    }
};


export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetPlatformAccount>>({
                                                                                    query: getPlatformAccount,
                                                                                    variables: {
                                                                                        id: id
                                                                                    },
                                                                                });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            return response.data.getPlatformAccount;
        } else if (response.errors) {
            response.errors.forEach((error: any) => {
                notifyError(error);
            })
        }

    } catch (e: any) {
        notifyError(e);
    }
};
