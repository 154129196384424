import {fetchPlatformAccounts} from '../reducers/recordsSlice';

import {
    recordsSelector,
    visibilitySelector
} from '../selectors';

import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";


export const usePlatformAccounts = () => {
    const dispatch = useAppDispatch();

    const {
        items,
        visibleItems,
        visibleCount
    } =
        useAppSelector((state) => visibilitySelector(state));

    const {loading} = useAppSelector((state) =>
                                         recordsSelector(state),
    );

    // FETCH
    const handleFetchRecords = () => {
        dispatch(fetchPlatformAccounts({}));
    };


    return {
        items,
        visibleItems,
        loading,
        visibleCount,
        handleFetchRecords,
    };
};
