import {RecordForm} from '../forms/simple/RecordForm';
import {BulkRecordForm} from "../forms/bulk/BulkRecordForm";

import {
    FormPageLayout,
    NextCloseButton,
    NextEditButton,
    SimplePageContentWrapper,
} from '@pac/platform-ui-components';
import {AddPageNavigation} from "../navigation/EditPageNavigation.tsx";
import {useAction} from "../hooks/useAction";
import {
    useNavigate,
    useParams
} from "react-router-dom";
import {useToggle} from "@mantine/hooks";
import {ReactElement} from "react";


export const AddPage = () => {
    const navigate = useNavigate()

    const [mode, toggle] = useToggle(['bulk', 'simple']);

    const {id: permissionProfileId} = useParams();

    if (typeof permissionProfileId === 'undefined') {
        throw new Error('permissionProfileId is required parameter')
    }

    const {
        record,
        handleCreateRecord
    } = useAction(permissionProfileId);


    const tools: ReactElement[] = []

    if (mode === 'simple') {
        tools.push(<NextEditButton variant={'filled'} key={'edit'} onClick={() => toggle()}>Switch
            to Bulk Creation</NextEditButton>)
        tools.push(<NextEditButton variant={'outline'} key={'edit'}
                                   onClick={() => toggle()}>Simple</NextEditButton>)
    } else {
        tools.push(<NextEditButton variant={'outline'} key={'edit'} onClick={() => toggle()}>Bulk
            Creation</NextEditButton>)
        tools.push(<NextEditButton variant={'filled'} key={'edit'}
                                   onClick={() => toggle()}>Switch to Simple</NextEditButton>)
    }

    tools.push(<NextCloseButton variant={'outline'} key={'close'}
                                onClick={() => navigate(`/permission-profiles/${permissionProfileId}`)}>Close</NextCloseButton>);


    const pageTitle = mode === 'simple' ? 'Add Action (simple)' : 'Add Actions (bulk)';
    return (
        <FormPageLayout
            title={pageTitle}
            pageNavigation={<AddPageNavigation/>}
            tools={[(<>{tools}</>)]}
        >
            {mode === 'simple' ?
                <SimplePageContentWrapper>
                    <RecordForm record={record} handleSubmit={handleCreateRecord}/>
                </SimplePageContentWrapper> :
                <SimplePageContentWrapper>
                    <BulkRecordForm record={record} handleSubmit={handleCreateRecord}/>
                </SimplePageContentWrapper>
            }

        </FormPageLayout>
    );
};
