import {
    useEffect,
    useState
} from 'react';

import {
    Autocomplete,
    AutocompleteProps,
    Group,
    Text
} from '@mantine/core';
import {useDeploymentEnvironments} from "../hooks/useDeploymentEnvironments";
import {FieldProps} from "formik";
import {DeploymentEnvironment} from "../types.ts";


export const DeploymentEnvironmentsAutocomplete = ({
                                                       field,
                                                       form: {setFieldValue},
                                                       ...rest
                                                   }: FieldProps) => {

    const [value, setValue] = useState<string>(field.value);
    const [disabled, setDisabled] = useState<boolean>(false);

    const {
        items: records,
        handleFetchRecords
    } = useDeploymentEnvironments()


    useEffect(() => {
        handleFetchRecords();
    }, []);

    const renderAutocompleteOption: AutocompleteProps['renderOption'] = ({option}) => {
        const record = records.find((record: DeploymentEnvironment) => record.platformAccount?.name === option.value);
        return (
            <Group gap="sm">
                <div>
                    <Text size="sm">{record.platformAccount.name} [ {record.region} ]</Text>
                </div>
            </Group>
        )
    };

    const data = records.map((record: DeploymentEnvironment) => {
        return record.platformAccount?.name
    });

    useEffect(() => {
        if (records.length === 0) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [records.length]);

    const handleChange = (value: string) => {
        setValue(value);

        const found = records.find((record: DeploymentEnvironment) => record.platformAccount?.name === value);
        setFieldValue(field.name, found.id);
    };

    return (
        <Autocomplete
            name={field.name}
            value={value}
            renderOption={renderAutocompleteOption}
            onChange={handleChange}
            data={data}
            disabled={disabled}
            placeholder='Select Deployment Environment'
            {...rest}
        />
    );
};
