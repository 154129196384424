import {Field} from 'formik';

import {
    Fieldset,
    Stack
} from '@mantine/core';

import {PacFormikRadioGroup} from '@pac/platform-ui-components';

const inputMethodOptions = [
    {
        label: 'Permit Action',
        value: 'permit',
    },
    {
        label: 'Forbid Action',
        value: 'forbid',
    },
];


// export const PolicyEffect = (_: FormikProps<NEXTPolicyFormValues>) => {
export const PolicyEffect = () => {

    return (
        <Stack gap={'xl'}>
            <Fieldset fw={600} tt={'uppercase'} legend={'Policy Effect'}>
                <Field
                    name="policyEffect"
                    label={''}
                    options={inputMethodOptions}
                    component={PacFormikRadioGroup}
                    my={'xl'}
                />
            </Fieldset>
        </Stack>
    );
};
