import {I18n} from '@aws-amplify/core';

import {NavLink} from 'react-router-dom';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {
    IPolicyStoreTableColumn,
    NEXTPolicyStore
} from "../types.ts";


export const columns: IPolicyStoreTableColumn[] = [
    {
        title: I18n.get('Policy Store ID'),
        render: (item: NEXTPolicyStore) => {
            return (
                <NavLink
                    to={{
                        pathname: `/policy-stores/${item.id}`,
                    }}
                >
                    {item.policyStoreId}
                </NavLink>
            );
        },
    },
    {
        title: I18n.get('Description'),
        width: '1fr',
        render: (item: NEXTPolicyStore) => item.description
    },
    {
        title: I18n.get('Created Date'),
        width: '1fr',
        render: (item: NEXTPolicyStore) => formatTimestamp(item.createdDate),
    },
    {
        title: I18n.get('Last Udated Date'),
        width: '1fr',
        render: (item: NEXTPolicyStore) => formatTimestamp(item.lastUpdatedDate),
    },
];
