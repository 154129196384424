import {
    useEffect,
    useState
} from 'react';

import {
    Autocomplete,
    AutocompleteProps,
    Group,
    Text
} from '@mantine/core';
import {usePlatformAccounts} from "../hooks/usePlatformAccounts";
import {FieldProps} from "formik";
import {PlatformAccount} from "../types.ts";


export const PlatformAccountsAutocomplete = ({
                                                 field: {
                                                     name,
                                                     value
                                                 },
                                                 form: {setFieldValue},
                                                 ...rest
                                             }: FieldProps) => {

    const [disabled, setDisabled] = useState<boolean>(false);

    const {
        items: records,
        handleFetchRecords
    } = usePlatformAccounts()


    useEffect(() => {
        handleFetchRecords();
    }, []);

    const data = records.map((record: PlatformAccount) => {
        return record.accountId
    });

    const renderAutocompleteOption: AutocompleteProps['renderOption'] = ({option}) => {
        const record = records.find((record: PlatformAccount) => record.accountId === option.value);
        return (
            <Group gap="sm">
                <div>
                    <Text size="sm">{record.name} [ {record.accountId} ]</Text>
                </div>
            </Group>
        )
    };


    useEffect(() => {
        if (records.length === 0) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [records.length]);

    const handleChange = (value: string) => {
        setFieldValue(name, value);
    };

    return (
        <Autocomplete
            name={name}
            value={value}
            onChange={handleChange}
            data={data}
            renderOption={renderAutocompleteOption}
            disabled={disabled}
            placeholder='Select Platform Account'
            {...rest}
        />
    );
};
