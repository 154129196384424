import {
    NavLink as ReactRouteNavLink,
    useParams
} from 'react-router-dom';
import {useActions} from "../../../actions";
import {useEffect} from "react";
import {
    Group,
    Text
} from "@mantine/core";
import {ActionIcon} from "../../../actions/images/ActionIcon.tsx";
import {DashboardWidgetBuilder} from "@pac/platform-ui-components";
import {useVerifiedPermissions} from "../../../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../../../config/rbac/actions.ts";


export const ActionsWidget = () => {
    const {id: permissionProfileId} = useParams();

    if (typeof permissionProfileId === 'undefined') {
        throw new Error('permissionProfileId is required parameter')
    }

    const {
        visibleCount,
        handleFetchRecords: fetchActions
    } = useActions(permissionProfileId)


    const {allowedActions} = useVerifiedPermissions()

    useEffect(() => {
        if (allowedActions.includes(rbacActions.ListActions)) {
            fetchActions();
        }

    }, []);

    const widget = {
        header: {
            content: <Group p={'sm'}><ActionIcon/><Text>Actions</Text></Group>,
        },
        body: {
            dataPoints: [
                {
                    value: visibleCount,
                    label: 'Actions',
                    color: '#194a74',
                },
            ],
            metrics: [],
        },
        footer: {
            actionItems: [
                {
                    label: 'All Actions',
                    component: {ReactRouteNavLink},
                    to: `/permission-profiles/${permissionProfileId}/#actions`,
                    visible: true,
                },
                {
                    label: 'Add Action',
                    component: {ReactRouteNavLink},
                    to: `/permission-profiles/${permissionProfileId}/actions/add`,
                    visible: true,
                },
            ],
        }
    };

    return <DashboardWidgetBuilder widget={widget}/>;
};
