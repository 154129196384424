import {I18n} from '@aws-amplify/core';

import {SimplePageHeader} from '@pac/platform-ui-components';
import {PermissionProfileIcon} from "../../permission-profiles/images/PermissionProfileIcon.tsx";


const useTools = () => {
    const tools = [] as any[];
    return tools;
};

export const IndexPageHeader = () => {
    return (
        <SimplePageHeader
            title={I18n.get('Permission Profiles')}
            icon={<PermissionProfileIcon/>}
            tools={useTools()}
        />
    );
};
