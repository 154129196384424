import {NextReloadButton} from '@pac/platform-ui-components';

import {useParams} from 'react-router-dom';

import {usePolicyTemplates} from '../hooks/usePolicyTemplates';

export const ReloadRecordsButton = () => {

    const {id: permissionProfileId} = useParams();

    if (typeof permissionProfileId === 'undefined') {
        throw new Error('permissionProfileId is required parameter')
    }

    const {handleFetchRecords} = usePolicyTemplates(permissionProfileId);

    return (<NextReloadButton onClick={handleFetchRecords}/>);
};
