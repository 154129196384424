import {
    Outlet,
    useNavigate
} from 'react-router-dom';

import {
    PlatformFooter,
    PlatformHeader,
    PlatformHeaderProfileButton,
    PlatformHeaderToolbar,
    PlatformLoader
} from '@pac/platform-ui-components';


import {PortalNavbar} from './menu-items/pages/PortalNavbar';

import {
    AppShell,
    Box,
} from '@mantine/core';

import {stage} from '../config/stage';

import {FaSignOutAlt} from 'react-icons/fa';
import classes from './UserLayout.module.css';
import {Suspense} from "react";

export const UserLayout = (
    {
        givenName,
        familyName,
        email
    }: {
        givenName: string,
        familyName: string,
        email: string
    }) => {

    const navigate = useNavigate();

    let header = <PlatformHeader/>;

    const toolbar = <PlatformHeaderToolbar
        tools={
            [
                <PlatformHeaderProfileButton
                    stage={stage} user={{
                    name: [givenName, familyName].join(' '),
                    'email-address': email
                } as { name: string, 'email-address': string }}
                    showDefaultMenuItems={true}
                    additionalMenuItems={[{
                        title: 'Sign Out',
                        icon: <FaSignOutAlt size={20}/>,
                        onClick: () => navigate('/signout'),
                    }]}/>
            ]
        }/>;

    header = <PlatformHeader toolbar={toolbar}/>;


    return (
        <AppShell
            header={{height: 70}}
            footer={{height: 95}}
            navbar={{
                width: 300,
                breakpoint: 'sm',
                collapsed: {mobile: true}
            }}
        >

            <AppShell.Header>
                {header}
            </AppShell.Header>

            <AppShell.Navbar p={'sm'}>
                <PortalNavbar/>
            </AppShell.Navbar>

            <AppShell.Main>
                <Suspense fallback={<PlatformLoader/>}>
                    <Box className={classes.main}>
                        <Outlet/>
                    </Box>
                </Suspense>
            </AppShell.Main>

            <AppShell.Footer p={'md'}>
                <PlatformFooter/>
            </AppShell.Footer>

        </AppShell>
    );
};
