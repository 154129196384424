import {FunctionComponent} from 'react';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';
import {AddPageNavigation} from "../navigation/EditPageNavigation.tsx";
import {useDataSource} from "../hooks/useDataSource";

export const AddPage: FunctionComponent = () => {

    const {
        record,
        handleCreateRecord
    } = useDataSource();

    return (
        <FormPageLayout
            title='Add Data Source'
            pageNavigation={<AddPageNavigation/>}
        >
            <SimplePageContentWrapper><RecordForm record={record}
                                                  handleSubmit={handleCreateRecord}/></SimplePageContentWrapper>
        </FormPageLayout>
    );
};
