import {
    Center,
    Stack,
    Title
} from '@mantine/core';

import PanasonicLogo from '../../../assets/images/panasonic-logo-white.png';

export const HeroSection = () => {
    return (
        <Stack className="public-home-page-hero">
            <Center display="block" mt="xl" px="xl">
                <img
                    src={PanasonicLogo}
                    alt="Panasonic Avionics"
                    className="logo"
                />
            </Center>

            <Center>
                <Title
                    order={3}
                    style={() => ({
                        textTransform: 'uppercase',
                    })}
                >
                    {import.meta.env.VITE_TITLE}
                </Title>
            </Center>
        </Stack>
    );
};
