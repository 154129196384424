export const createDeploymentEnvironment = `
  mutation createDeploymentEnvironment($input: CreateDeploymentEnvironmentInput!) {
    createDeploymentEnvironment(input: $input){
      id
      platformAccountId
      platformAccount {
        accountId
        name
      }
      region
      isDefault
      createdByUsername
      managedByUsername
      created
      updated
    }
  }  
`;

export const updateDeploymentEnvironment = `
  mutation updateDeploymentEnvironment($input: UpdateDeploymentEnvironmentInput!) {
    updateDeploymentEnvironment(input: $input){
      id
      platformAccountId
      platformAccount {
        accountId
        name
      }
      region
      isDefault
      createdByUsername
      managedByUsername
      created
      updated
    }
  }
`;

export const deleteDeploymentEnvironment = `
  mutation deleteDeploymentEnvironment($input: DeleteDeploymentEnvironmentInput!) {
    deleteDeploymentEnvironment(input: $input){
      id
    }
  }  
`;
