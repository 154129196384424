import {
    getEntityType,
    listEntityTypes
} from './queries';

import {
    createEntityType,
    deleteEntityType,
    updateEntityType
} from './mutations';

import {
    CreateEntityType,
    DeleteEntityType,
    GetEntityType,
    ListEntityTypes,
    NEXTEntityType,
    UpdateEntityType
} from "../types.ts";
import {
    generateClient,
    GraphQLQuery
} from "@aws-amplify/api";
import {
    notifyError,
    notifySuccess
} from "../../../components/ServiceResponseNotifications.tsx";

const client = generateClient();

export const fetchRecords = async (permissionProfileId: string, query?: { [k: string]: any }) => {

    let filter;

    const permissionProfileFilter = {
        permissionProfileId: permissionProfileId,
    };

    if (query && query.filter) {
        filter = {...permissionProfileFilter, ...query.filter}
    } else {
        filter = permissionProfileFilter;
    }

    try {
        const response = await client.graphql<GraphQLQuery<ListEntityTypes>>({
                                                                                 query: listEntityTypes,
                                                                                 variables: {
                                                                                     filter: filter
                                                                                 }
                                                                             });

        return response.data?.listEntityTypes.items;
    } catch (e) {
        console.debug(e);
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetEntityType>>({
                                                                               query: getEntityType,
                                                                               variables: {
                                                                                   id: id
                                                                               },
                                                                           });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess(`Got Entity Type`);
            return response.data.getEntityType;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e);
    }
};

export const add = async (payload: NEXTEntityType) => {

    console.debug('FORM PAYLOAD', payload)


    const variables = Object.assign({}, {
        input: {
            ...payload
        }
    });

    try {
        const response = await client.graphql<GraphQLQuery<CreateEntityType>>({
                                                                                  query: createEntityType,
                                                                                  variables: variables,
                                                                              });


        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Entity Type created');
            return response.data.createEntityType;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e)
    }
};

export const update = async (id: string, record: NEXTEntityType) => {

    console.debug('IN UPDATE ENTITY TYPE SERVICE id ', id)

    const variables = Object.assign({}, {
        input: {
            id: id,
            name: record.name,
            permissionProfileId: record.permissionProfileId,
            memberOfTypesIds: record.memberOfTypesIds
        }
    });

    try {
        const response = await client.graphql<GraphQLQuery<UpdateEntityType>>({
                                                                                  query: updateEntityType,
                                                                                  variables: variables,
                                                                              });

        notifySuccess('Entity Type updated');
        return response.data?.updateEntityType;


    } catch (e: any) {

        if (e.errors) {
            e.errors.forEach((error: any) => {
                notifyError(error);
            })
        }
        notifyError(e);
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteEntityType>>({
                                                                                  query: deleteEntityType,
                                                                                  variables: {
                                                                                      input: {
                                                                                          id: id
                                                                                      }
                                                                                  },
                                                                              });


        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Entity Type deleted');
            return response.data.deleteEntityType;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e);
    }
};
