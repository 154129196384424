import {FunctionComponent} from 'react';

import {
    Navigate,
    Route,
    Routes
} from 'react-router-dom';

import {IndexPage} from './pages/IndexPage';
import {DetailPage} from "./pages/DetailPage.tsx";
import {useVerifiedPermissions} from "../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../config/rbac/actions.ts";


export const DashboardLayout: FunctionComponent = () => {

    const {allowedActions} = useVerifiedPermissions()

    return (
        <Routes>
            <Route
                path="/"
                element={
                    (allowedActions.includes(rbacActions.ListPolicyStores) ? (
                        <IndexPage/>
                    ) : (
                        <Navigate to="/"/>
                    ))
                }
            />
            <Route
                path=":id"
                element={
                    (allowedActions.includes(rbacActions.GetDashboard) ? (
                        <DetailPage/>
                    ) : (
                        <Navigate to="/"/>
                    ))
                }
            />
        </Routes>
    );
};
