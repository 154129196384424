import {
    Group,
    JsonInput,
    Stack,
    Text
} from '@mantine/core';
import {NEXTPolicy} from "../types.ts";
import {NextCopyButton} from "@pac/platform-ui-components";


export const ProfilePolicyBlock = ({policy}: { policy: NEXTPolicy }) => {

    // const parsedPolicy = parseCedarPolicy(policy.statement);
    // console.debug(JSON.stringify(parsedPolicy, null, 2));

    return (
        <Stack gap={'xs'}>
            <Stack ml={'1em'} gap={'sm'} mb={'lg'}>
                <Text>Description:</Text>
                <Text fw={300}>{policy.definition.static.description}</Text>
            </Stack>
            <Stack ml={'1em'} gap={'xs'}>
                <Group justify={'space-between'}>
                    <Text>Policy Body:</Text>
                    <NextCopyButton value={policy.definition.static.statement}/>
                </Group>
                <JsonInput
                    value={policy.definition.static.statement}
                    autosize
                    minRows={6}
                />
            </Stack>

        </Stack>
    );
};