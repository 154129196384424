import React from 'react'

import {NavLink} from 'react-router-dom';

import {
    Box,
    Breadcrumbs
} from '@mantine/core';
import {BreadcrumbElement,} from "../../../types";
import {IdentityProvider} from "../types.ts";

const renderBreadcrumbs = (elements: BreadcrumbElement[]) => {

    const breadcrumbs = elements.map((item, index) => (
        <React.Fragment key={index}>{item.href ?
            <NavLink to={item.href}>{item.title}</NavLink> : item.title}</React.Fragment>
    ));
    return (
        <Box p={'xs'}><Breadcrumbs separator='→' mt='xs'>{breadcrumbs}</Breadcrumbs></Box>
    );

}

const rootPathElements = (): BreadcrumbElement[] => {

    return [
        {
            title: 'Home',
            href: '/dashboard'
        },
        {
            title: 'Identity Providers',
            href: `/identity-providers`
        }
    ];

}

export const AddPageNavigation = () => {

    const elements = rootPathElements()
        .concat([
                    {title: 'Add Identity Provider'}
                ]);
    return renderBreadcrumbs(elements)
};

export const EditPageNavigation = ({record}: { record: IdentityProvider }) => {


    const elements = rootPathElements()
        .concat([
                    {title: record.name}
                ]);
    return renderBreadcrumbs(elements)
};
