import {useEffect} from 'react';

import {signOut} from 'aws-amplify/auth';

import {
    Center,
    Loader
} from '@mantine/core';

export const SignOutPage = () => {

    useEffect(() => {
        const signOutAndRedirect = async () => {
            localStorage.setItem('amplify-signin-with-hostedUI', 'false');
            await signOut()
            window.location.href = '/';
        }

        signOutAndRedirect()
    }, []);


    return (
        <Center>
            <Loader/>
        </Center>
    );
};
