import {
    clearKeyword,
    setKeyword,
} from '../reducers/index';

import {visibilitySelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";


export const usePermissionGroupsSearch = (permissionProfileId: string) => {
    const dispatch = useAppDispatch();

    if (typeof permissionProfileId === 'undefined') {
        throw new Error('permissionProfileId is required parameter')
    }

    const {
        keyword,
    } =
        useAppSelector((state) => visibilitySelector(state, permissionProfileId));


    // FILTERING AND PAGING
    const handleFilterByKeyword = (keyword: string) => {
        if (keyword !== '') {
            dispatch(setKeyword(keyword));
        } else {
            dispatch(clearKeyword());
        }
    };

    return {
        keyword,
        handleFilterByKeyword,
    };
};
