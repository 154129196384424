import {
    getDataSource,
    listDataSources
} from './queries';

import {
    createDataSource,
    deleteDataSource,
    updateDataSource
} from './mutations';

import {
    CreateDataSource,
    DataSource,
    DeleteDataSource,
    GetDataSource,
    ListDataSources,
    UpdateDataSource
} from "../types.ts";
import {
    notifyError,
    notifySuccess
} from "../../../components/ServiceResponseNotifications.tsx";
import {
    generateClient,
    GraphQLQuery
} from "@aws-amplify/api";

const client = generateClient();


export const fetchRecords = async (query: { [k: string]: any }) => {

    let filter = {}
    if (query.filter) {
        filter = query.filter
    }


    try {
        const response = await client.graphql<GraphQLQuery<ListDataSources>>({
                                                                                 query: listDataSources,
                                                                                 variables: {
                                                                                     filter: filter,
                                                                                 }
                                                                             });

        return response.data?.listDataSources.items;
    } catch (e: any) {
        notifyError(e);
    }
};


export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetDataSource>>({
                                                                               query: getDataSource,
                                                                               variables: {
                                                                                   id: id
                                                                               },
                                                                           });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            return response.data.getDataSource;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }

    } catch (e: any) {
        notifyError(e);
    }
};

export const add = async (payload: DataSource) => {


    const variables = Object.assign({}, {
        input: {
            ...payload
        }
    });

    try {
        const response = await client.graphql<GraphQLQuery<CreateDataSource>>({
                                                                                  query: createDataSource,
                                                                                  variables: variables,
                                                                              });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Data Source created');
            return response.data.createDataSource;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }

    } catch (e: any) {
        notifyError(e);
    }
};

export const update = async (id: string, record: DataSource) => {

    console.debug('IN UPDATE DATA SOURCE SERVICE id ', id)

    const variables = Object.assign({}, {
        input: {
            id: record.id,
            name: record.name,
            description: record.description,
        }
    });

    try {
        const response = await client.graphql<GraphQLQuery<UpdateDataSource>>({
                                                                                  query: updateDataSource,
                                                                                  variables: variables,
                                                                              });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Data Source updated');
            return response.data.updateDataSource;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e);
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteDataSource>>({
                                                                                  query: deleteDataSource,
                                                                                  variables: {
                                                                                      input: {
                                                                                          id: id
                                                                                      }
                                                                                  },
                                                                              });


        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Data Source deleted');
            return response.data.deleteDataSource;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e);
    }
};
