import {
    getAction,
    listActions
} from './queries';

import {
    createAction,
    deleteAction,
    updateAction
} from './mutations';


import {
    generateClient,
    GraphQLQuery
} from '@aws-amplify/api';
import {
    CreateAction,
    DeleteAction,
    GetAction,
    ListActions,
    NEXTAction,
    UpdateAction
} from "../types.ts";
import {
    notifyError,
    notifySuccess
} from "../../../components/ServiceResponseNotifications.tsx";

const client = generateClient();

export const fetchRecords = async (permissionProfileId: string, query?: { filter: { [k: string]: string } }) => {

    let filter;

    const permissionProfileFilter = {
        permissionProfileId: permissionProfileId,
    };

    if (query && query.filter) {
        filter = {...permissionProfileFilter, ...query.filter}
    } else {
        filter = permissionProfileFilter;
    }

    try {
        const response = await client.graphql<GraphQLQuery<ListActions>>(
            {
                query: listActions,
                variables: {
                    filter: filter
                }
            });

        return response.data?.listActions.items;
    } catch (e: any) {
        notifyError(e);
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetAction>>({
                                                                           query: getAction,
                                                                           variables: {
                                                                               id: id
                                                                           },
                                                                       });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            return response.data.getAction;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }

    } catch (e: any) {
        notifyError(e);
    }
};

export const add = async (payload: NEXTAction) => {

    const variables = Object.assign({}, {
        input: {
            permissionProfileId: payload.permissionProfileId,
            name: payload.name,
            principalTypeIds: payload.principalTypeIds,
            resourceTypeIds: payload.resourceTypeIds
        }
    });


    try {
        const response = await client.graphql<GraphQLQuery<CreateAction>>({
                                                                              query: createAction,
                                                                              variables: variables,
                                                                          });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Action created');
            return response.data.createAction;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }

    } catch (e: any) {
        notifyError(e)
    }
};

export const update = async (id: string, record: NEXTAction) => {

    const variables = Object.assign({}, {
        input: {
            id: id,
            permissionProfileId: record.permissionProfileId,
            name: record.name,
            principalTypeIds: record.principalTypeIds,
            resourceTypeIds: record.resourceTypeIds
        }
    });

    try {
        const response = await client.graphql<GraphQLQuery<UpdateAction>>({
                                                                              query: updateAction,
                                                                              variables: variables,
                                                                          });


        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Action updated');
            return response.data.updateAction;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }

    } catch (e: any) {
        notifyError(e);
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteAction>>({
                                                                              query: deleteAction,
                                                                              variables: {
                                                                                  input: {
                                                                                      id: id
                                                                                  }
                                                                              },
                                                                          });


        if (typeof response.data !== 'undefined' && response.data !== null) {
            notifySuccess('Action deleted');
            return response.data.deleteAction;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }


    } catch (e: any) {
        notifyError(e);
    }
};
