import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update
} from '../../policy-stores/services/PolicyStoresService';

import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase
} from '../../../store/RecordsSliceCaseHelpers';
import {NEXTPolicyStore} from "../types.ts";
import {moduleConfig} from "../config";

const entityKey = moduleConfig.entityKey;

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
};

const normalizeRecord = (record: NEXTPolicyStore) => {
    return Object.assign({}, record, {id: record.policyStoreId});
};

// genenrates pending, fulfilled and rejected
export const fetchPolicyStores = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    () => {
        return fetchRecords()
            .then((response) => {
                return response && response.map((record) => {
                    return normalizeRecord(record);
                });
            });
    },
);

export const fetchPolicyStore = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return normalizeRecord(response);
                }
            });
    });


export const savePolicyStore = createAsyncThunk(
    `${entityKey}/add`,
    (payload: NEXTPolicyStore) => {
        return add(payload)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return normalizeRecord(response);
                }
            });
    });

export const updatePolicyStore = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record
     }: { id: string, record: NEXTPolicyStore }) => {

        return update(id, record)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return normalizeRecord(response);
                }
            });
    },
);

export const deletePolicyStore = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    },
);

const policyStoresSlice = createSlice({
                                          name: entityKey,
                                          initialState,
                                          reducers: {},
                                          extraReducers: (builder) => {
                                              // FETCH MANY
                                              fetchManyCase(builder, fetchPolicyStores, entityKey);

                                              // FETCH ONE
                                              fetchOneCase(builder, fetchPolicyStore);

                                              // SAVE ONE
                                              saveOneCase(builder, savePolicyStore);

                                              // UPDATE ONE
                                              updateOneCase(builder, updatePolicyStore);

                                              // DELETE ONE
                                              deleteOneCase(builder, deletePolicyStore);
                                          },
                                      });

export default policyStoresSlice.reducer;
