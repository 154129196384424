import {FunctionComponent} from 'react';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';
import {AddPageNavigation} from "../navigation/EditPageNavigation.tsx";
import {usePermissionProfile} from "../hooks/usePermissionProfile";

export const AddPage: FunctionComponent = () => {

    const {
        record,
        handleCreateRecord
    } = usePermissionProfile();

    return (
        <FormPageLayout
            title='Add Permission Profile'
            pageNavigation={<AddPageNavigation/>}
        >
            <SimplePageContentWrapper>
                <RecordForm record={record} handleSubmit={handleCreateRecord}/>
            </SimplePageContentWrapper>
        </FormPageLayout>
    );
};
