import {
    FunctionComponent,
    useEffect
} from 'react';

import {useParams} from 'react-router-dom';

import {
    Grid,
    Stack
} from '@mantine/core';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    PlatformLoader,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';

import {useIdentityProvider} from '../hooks/useIdentityProvider';

import {EditPageNavigation} from '../navigation/EditPageNavigation.tsx';
import {Profile} from "../profile/Profile";

import {useEditPageTools} from "../hooks/useEditPageTools";

export const EditPage: FunctionComponent = () => {

    const {
        id: identityProviderId
    } = useParams();

    const {
        record: identityProvider,
        handleFetchRecord,
        handleUpdateRecord
    } = useIdentityProvider(identityProviderId);


    const {
        mode,
        tools
    } = useEditPageTools();


    useEffect(() => {
        handleFetchRecord()
    }, [])


    if (!identityProvider) {
        return <PlatformLoader message='Loading permission profile...Please wait'/>;
    }


    return (
        <FormPageLayout
            title={(`Identity Provider: ${identityProvider.name}`)}
            pageNavigation={<EditPageNavigation record={identityProvider}/>}
            tools={tools}
        >

            {mode === 'view' ?
                <Grid>
                    <Grid.Col span={{
                        base: 4,
                        sm: 12
                    }}>

                        <Profile record={identityProvider}/>

                    </Grid.Col>
                    <Grid.Col span={{
                        base: 8,
                        sm: 12
                    }}>


                        <Stack gap='lg'>


                        </Stack>


                    </Grid.Col>
                </Grid>
                :
                <SimplePageContentWrapper>
                    <RecordForm record={identityProvider} handleSubmit={handleUpdateRecord}/>
                </SimplePageContentWrapper>}

        </FormPageLayout>
    );
};
