import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update
} from '../../identity-providers/services/IdentityProvidersService';

import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase
} from '../../../store/RecordsSliceCaseHelpers';
import {IdentityProvider} from "../types.ts";
import {moduleConfig} from "../config";


const entityKey = moduleConfig.entityKey;

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
};

const normalizeRecord = (record: IdentityProvider) => {
    return Object.assign({}, record,);
};

// genenrates pending, fulfilled and rejected
export const fetchIdentityProviders = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    (query: object) => {
        return fetchRecords(query)
            .then((response) => {
                return response?.map((record) => {
                    return normalizeRecord(record);
                });
            });
    },
);

export const fetchIdentityProvider = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                return normalizeRecord(response as IdentityProvider);
            });
    });


export const saveIdentityProvider = createAsyncThunk(
    `${entityKey}/add`,
    (payload: IdentityProvider) => {
        return add(payload)
            .then((response) => normalizeRecord(response as IdentityProvider));
    });

export const updateIdentityProvider = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record
     }: { id: string, record: IdentityProvider }) => {

        return update(id, record)
            .then((response) => {
                return normalizeRecord(response as IdentityProvider);
            });
    },
);

export const deleteIdentityProvider = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    },
);

const identityProvidersSlice = createSlice({
                                               name: entityKey,
                                               initialState,
                                               reducers: {},
                                               extraReducers: (builder) => {
                                                   // FETCH MANY
                                                   fetchManyCase(builder, fetchIdentityProviders, entityKey);

                                                   // FETCH ONE
                                                   fetchOneCase(builder, fetchIdentityProvider);

                                                   // SAVE ONE
                                                   saveOneCase(builder, saveIdentityProvider);

                                                   // UPDATE ONE
                                                   updateOneCase(builder, updateIdentityProvider);

                                                   // DELETE ONE
                                                   deleteOneCase(builder, deleteIdentityProvider);
                                               },
                                           });

export default identityProvidersSlice.reducer;
