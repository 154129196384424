import {stage} from './stage';


type UrlsShape = {
    [key: string]: string
}

const urls: UrlsShape = {
    prd: 'https://permissions.nextcloud.aero',
    test: 'https://permissions-test.nextcloud.aero',
    dev: 'https://permissions-dev.nextcloud.aero',
    ide: import.meta.env.VITE_HOST + ':8084',
};


type ConfigShape = {
    [key: string]: string
}

const config: ConfigShape = {
    name: 'NEXT Permissions',
    url: urls[stage],
};

export default config;
