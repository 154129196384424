import {
    getAuthorizationRequest,
    listAuthorizationRequests,
    replayAuthorizationRequest
} from './queries';

import {
    createAuthorizationRequest,
    deleteAuthorizationRequest,
    updateAuthorizationRequest
} from './mutations';

import {
    CreateAuthorizationRequest,
    DeleteAuthorizationRequest,
    GetAuthorizationRequest,
    ListAuthorizationRequests,
    NEXTAuthorizationRequest,
    ReplayAuthorizationRequest,
    UpdateAuthorizationRequest
} from "../types.ts";
import {
    generateClient,
    GraphQLQuery
} from "@aws-amplify/api";

const client = generateClient();

export const fetchRecords = async (
    permissionProfileId: string,
    query?: {
        filter: { [k: string]: any }
    }) => {

    let filter;

    const permissionProfileFilter = {
        permissionProfileId: permissionProfileId,
    };

    if (query && query.filter) {
        filter = {...permissionProfileFilter, ...query.filter}
    } else {
        filter = permissionProfileFilter;
    }

    try {
        const response = await client.graphql<GraphQLQuery<ListAuthorizationRequests>>({
                                                                                           query: listAuthorizationRequests,
                                                                                           variables: {
                                                                                               filter: filter
                                                                                           }
                                                                                       });

        return response.data?.listAuthorizationRequests.items;
    } catch (e) {
        console.debug(e);
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetAuthorizationRequest>>({
                                                                                         query: getAuthorizationRequest,
                                                                                         variables: {
                                                                                             id: id
                                                                                         },
                                                                                     });
        return response.data?.getAuthorizationRequest;
    } catch (e) {
        console.trace(e);
    }
};

export const add = async (payload: NEXTAuthorizationRequest) => {

    console.debug('FORM PAYLOAD', payload)


    const variables = Object.assign({}, {
        input: {
            permissionProfileId: payload.permissionProfileId,
            actionId: payload.actionId,
            actionType: payload.actionType,
            context: payload.context,
            principalId: payload.principalId,
            principalType: payload.principalType,
            resourceId: payload.resourceId,
            resourceType: payload.resourceType,
            sliceComplement: payload.sliceComplement
        }
    });

    try {
        const response = await client.graphql<GraphQLQuery<CreateAuthorizationRequest>>({
                                                                                            query: createAuthorizationRequest,
                                                                                            variables: variables,
                                                                                        });

        return response.data?.createAuthorizationRequest;
    } catch (e) {
        console.debug(e);
    }
};

export const update = async (id: string, record: NEXTAuthorizationRequest) => {

    console.debug('IN UPDATE ENTITY TYPE SERVICE id ', id)

    const variables = Object.assign({}, {
        input: {
            id: record.permissionProfileId,
            permissionProfileId: record.permissionProfileId,
            actionId: record.actionId,
            actionType: record.actionType,
            context: record.context,
            principalId: record.principalId,
            principalType: record.principalType,
            resourceId: record.resourceId,
            resourceType: record.resourceType,
            sliceComplement: record.sliceComplement
        }
    });

    try {
        const response = await client.graphql<GraphQLQuery<UpdateAuthorizationRequest>>({
                                                                                            query: updateAuthorizationRequest,
                                                                                            variables: variables,
                                                                                        });
        return response.data?.updateAuthorizationRequest;
    } catch (e) {
        console.trace(e);
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteAuthorizationRequest>>({
                                                                                            query: deleteAuthorizationRequest,
                                                                                            variables: {
                                                                                                input: {
                                                                                                    id: id
                                                                                                }
                                                                                            },
                                                                                        });
        return response.data?.deleteAuthorizationRequest;
    } catch (e) {
        console.trace(e);
    }
};

export const replay = async (permissionProfileId: string, action: string, context: string, principal: string, resource: string) => {

    console.debug('action', action)
    console.debug('context', context)
    console.debug('principal', principal)
    console.debug('resource', resource)

    //store id ps-6b12b695-d6d7-418a-979d-949675cddd7e

    try {
        // const response = await API.graphql({
        //                                                            query: replayAuthorizationRequest,
        //                                                            variables: {
        //                                                                request: {
        //                                                                    policyStoreId: policyStoreId,
        //                                                                    action: {ActionType: action},
        //                                                                    principal: {EntityType: principal},
        //                                                                    resource: {EntityType: resource}
        //                                                                }
        //                                                            },
        //                                                        });


        const response = await client.graphql<GraphQLQuery<ReplayAuthorizationRequest>>({
                                                                                            query: replayAuthorizationRequest,
                                                                                            variables: {
                                                                                                request: {
                                                                                                    permissionProfileId: permissionProfileId,
                                                                                                    action: {
                                                                                                        ActionType: 'GetReport',
                                                                                                        ActionId: 'all'
                                                                                                    },
                                                                                                    principal: {
                                                                                                        EntityType: 'User',
                                                                                                        EntityId: 'Panasonic.aero.guest_plamen.garkov_panasonicavionics.aero'
                                                                                                    },
                                                                                                    resource: {
                                                                                                        EntityType: 'Report',
                                                                                                        EntityId: '3c6d807b-e8e3-401d-80f4-39db3ffc445b'
                                                                                                    }
                                                                                                }
                                                                                            },
                                                                                        });

        return response.data?.isAuthorized;
    } catch (e) {
        console.trace(e);
    }
};
