import {useEffect} from 'react';

import {MultiSelect} from '@mantine/core';
import {useEntityTypes} from "../hooks/useEntityTypes";
import {FieldProps} from "formik";
import {useParams} from "react-router-dom";
import {NEXTEntityType} from "../types.ts";

export const EntityTypesMultiSelect = ({
                                           field: {
                                               name,
                                               value
                                           },
                                           form: {setFieldValue},
                                           ...rest
                                       }: FieldProps) => {

    const {id: permissionProfileId} = useParams();

    if (typeof permissionProfileId === 'undefined') {
        throw new Error('permissionProfileId is required parameter')
    }

    const {
        items,
        handleFetchRecords
    } = useEntityTypes(permissionProfileId)

    useEffect(() => {
        handleFetchRecords();
    }, []);

    const data = items.map((item: NEXTEntityType) => {
        return {
            value: item.id,
            label: item.name
        };
    });

    const handleChange = (value: string[]) => {
        setFieldValue(name, value);
    };

    return (
        <MultiSelect
            data={data}
            value={value}
            onChange={handleChange}
            searchable
            {...rest}
        />
    );
};
