import {useEffect} from 'react';

import {useParams} from 'react-router-dom';

import {Stack} from '@mantine/core';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    PlatformLoader,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';

import {EditPageNavigation} from '../navigation/EditPageNavigation.tsx';
import {Profile} from "../profile/Profile";
import {useEditPageTools} from "../hooks/useEditPageTools";
import {useIdentitySource} from "../hooks/useIdentitySource";

export const EditPage = () => {

    const {
        id: permissionProfileId,
        identitySourceId
    } = useParams();

    if (typeof permissionProfileId === 'undefined') {
        throw new Error('permissionProfileId is required parameter')
    }

    const {
        record: identitySource,
        handleFetchRecord,
        handleUpdateRecord
    } = useIdentitySource(permissionProfileId, identitySourceId);

    const {
        mode,
        tools
    } = useEditPageTools();

    useEffect(() => {
        handleFetchRecord();
    }, []);

    if (typeof identitySource === 'undefined') {
        return <PlatformLoader message='Loading Identity Source...Please wait'/>;
    }


    return (
        <FormPageLayout
            title={identitySource.id}
            pageNavigation={<EditPageNavigation record={identitySource}/>}
            tools={tools}
        >

            {mode === 'view' ?
                <Stack gap='lg'>
                    <Profile
                        record={identitySource}
                    />
                </Stack>
                :
                <SimplePageContentWrapper>
                    <RecordForm
                        record={identitySource}
                        handleSubmit={handleUpdateRecord}
                    />
                </SimplePageContentWrapper>}

        </FormPageLayout>
    );
};
