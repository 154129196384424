import {IdentitySource,} from "../types.ts";

import {
    PlatformPropertiesCard,
    PlatformPropertiesCardProperty
} from '@pac/platform-ui-components'
import {OIDCProviderType} from "../../identity-providers/types.ts";


export const Profile = ({record}: { record: IdentitySource }) => {


    const properties = [
        {
            label: 'IdentitySource ID',
            value: record.identitySourceId,
        }, {
            label: 'Permission Profile ID',
            value: record.permissionProfileId,
        }, {
            label: 'Provider Type',
            value: record.providerType === OIDCProviderType.AmazonCognito ? 'Amazon Cognito' : 'External OIDC Provider',
        }, {
            label: 'User Pool Arn',
            value: record.userPoolArn,
        }, {
            label: 'User Pool Client IDs',
            value: record.clientIds ? record.clientIds.join(', ') : 'n/a',
        }, {
            label: 'Principal Entity Type',
            value: record.principalEntityType,
        }, {
            label: 'Group Entity Type',
            value: record.groupEntityType,
        }, {
            label: 'Created',
            value: record.created,
        }, {
            label: 'Last Updated',
            value: record.updated,
        }] as PlatformPropertiesCardProperty[];

    return (
        <PlatformPropertiesCard cols={2} properties={properties}/>
    );


};

