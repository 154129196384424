import {
    deleteAuthorizationRequest,
    fetchAuthorizationRequest,
    replayAuthorizationRequest,
    saveAuthorizationRequest,
    updateAuthorizationRequest
} from '../reducers/recordsSlice';

import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";
import {NEXTAuthorizationRequest} from "../types.ts";

export const useAuthorizationRequest = (permissionProfileId: string, authorizationRequestId?: string) => {
    const dispatch = useAppDispatch();

    if (typeof permissionProfileId === 'undefined') {
        throw new Error('permissionProfileId is required parameter')
    }

    const {
        record,
        loading
    } = useAppSelector((state) =>
                           recordSelector(state, authorizationRequestId ? authorizationRequestId : ''),
    );


    // FETCH
    const handleFetchRecord = () => {

        if (typeof authorizationRequestId === 'undefined') {
            throw new Error('authorizationRequestId is required parameter')
        }

        dispatch(fetchAuthorizationRequest(authorizationRequestId));
    };


    // CREATE, UPDATE DELETE
    const handleCreateRecord = (values: NEXTAuthorizationRequest) => {
        dispatch(saveAuthorizationRequest(values));
    };

    const handleUpdateRecord = (values: NEXTAuthorizationRequest) => {

        if (typeof authorizationRequestId === 'undefined') {
            throw new Error('authorizationRequestId is required parameter')
        }

        dispatch(updateAuthorizationRequest({
                                                id: authorizationRequestId,
                                                record: values
                                            }));
    };

    const handleDeleteRecord = () => {

        if (typeof authorizationRequestId === 'undefined') {
            throw new Error('authorizationRequestId is required parameter')
        }

        dispatch(deleteAuthorizationRequest(authorizationRequestId));
    };

    const handleReplay = () => {

        if (typeof authorizationRequestId === 'undefined') {
            throw new Error('authorizationRequestId is required parameter')
        }

        dispatch(replayAuthorizationRequest(authorizationRequestId));
    };

    return {
        record,
        loading,
        handleFetchRecord,
        handleCreateRecord,
        handleUpdateRecord,
        handleDeleteRecord,
        handleReplay
    };
};
