import {
    deletePolicyStore,
    fetchPolicyStore,
    savePolicyStore,
    updatePolicyStore
} from '../reducers/recordsSlice';

import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";
import {NEXTPolicyStore} from "../types.ts";

export const usePolicyStore = (permissionProfileId?: string) => {
    const dispatch = useAppDispatch();

    const {
        record,
        loading
    } = useAppSelector((state) =>
                           recordSelector(state, permissionProfileId ? permissionProfileId : ''),
    );

    // FETCH
    const handleFetchRecord = () => {

        if (typeof permissionProfileId === 'undefined') {
            throw new Error('permissionProfileId is required parameter')
        }

        dispatch(fetchPolicyStore(permissionProfileId));
    };


    // CREATE, UPDATE DELETE
    const handleCreateRecord = (values: NEXTPolicyStore) => {
        dispatch(savePolicyStore(values));
    };

    const handleUpdateRecord = (values: NEXTPolicyStore) => {

        if (typeof permissionProfileId === 'undefined') {
            throw new Error('permissionProfileId is required parameter')
        }

        dispatch(updatePolicyStore({
                                       id: permissionProfileId,
                                       record: values
                                   }));
    };

    const handleDeleteRecord = () => {
        if (typeof permissionProfileId === 'undefined') {
            throw new Error('permissionProfileId is required parameter')
        }

        dispatch(deletePolicyStore(permissionProfileId));
    };

    return {
        record,
        loading,
        handleFetchRecord,
        handleCreateRecord,
        handleUpdateRecord,
        handleDeleteRecord,
    };
};
