import {I18n} from '@aws-amplify/core';

import {NavLink} from 'react-router-dom';

import {formatTimestamp} from '../../../utils/TableTimestamps';


import {Text} from '@mantine/core'
import {
    IAuthorizationRequestTableColumn,
    NEXTAuthorizationRequest
} from "../types.ts";


export const columns: IAuthorizationRequestTableColumn[] = [
    {
        title: I18n.get('Auth Request ID'),
        render: (item: NEXTAuthorizationRequest) => {
            return (
                <NavLink
                    to={{
                        pathname: `/permission-profiles/${item.permissionProfileId}/authorization-requests/${item.id}`,
                    }}
                >
                    {item.id}
                </NavLink>
            );
        },
    },
    {
        title: I18n.get('Action ID'),
        width: '1fr',
        render: (item: NEXTAuthorizationRequest) => item.actionId,
    },
    {
        title: I18n.get('Principal'),
        width: '1fr',
        render: (item: NEXTAuthorizationRequest) => item.principalType,
    },
    {
        title: I18n.get('Principal ID'),
        width: '1fr',
        render: (item: NEXTAuthorizationRequest) => item.principalId,
    },
    {
        title: I18n.get('Resource'),
        width: '1fr',
        render: (item: NEXTAuthorizationRequest) => item.resourceType,
    },
    {
        title: I18n.get('Resource ID'),
        width: '1fr',
        render: (item: NEXTAuthorizationRequest) => item.resourceId,
    },
    {
        title: I18n.get('Decision'),
        width: '1fr',
        render: (item: NEXTAuthorizationRequest) => item.decision === 'Allow' ? <Text>{item.decision}</Text> :
            <Text>{item.decision}</Text>
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: NEXTAuthorizationRequest) => formatTimestamp(item.created),
    },
];
