export const listPolicyTemplates = `
query listPolicyTemplates($filter: PolicyTemplatesFilterInput!) {
  listPolicyTemplates(filter: $filter) {
    items {
      policyStoreId
      policyTemplateId
      statement
      description
      createdDate
      lastUpdatedDate
    }
  }
}
`

export const getPolicyTemplate = `
  query getPolicyTemplate($policyStoreId: ID!, $policyTemplateId: ID!) {
    getPolicyTemplate(policyStoreId: $policyStoreId, policyTemplateId: $policyTemplateId){
      policyStoreId
      policyTemplateId
      statement
      description
      createdDate
      lastUpdatedDate
    }
  }  
`;