import {I18n} from '@aws-amplify/core';

import {SimplePageHeader} from '@pac/platform-ui-components';
import {AuthorizationRequestIcon} from "../images/AuthorizationRequestIcon.tsx";
import {AddRecordButton} from "../buttons/AddRecordButton.tsx";
import {EditRecordButton} from "../buttons/EditRecordButton.tsx";
import {DeleteRecordButton} from "../buttons/DeleteRecordButton.tsx";
import {ReloadRecordsButton} from "../buttons/ReloadRecordsButton.tsx";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../../config/rbac/actions.ts";

const useTools = () => {

    const {allowedActions} = useVerifiedPermissions()

    const tools = [<ReloadRecordsButton key="reload"/>];


    if (allowedActions.includes(rbacActions.UpdateAuthorizationRequest)) {
        tools.push(<EditRecordButton key="edit"/>);
    }

    if (allowedActions.includes(rbacActions.DeleteAuthorizationRequest)) {
        tools.push(<DeleteRecordButton key="delete"/>);
    }

    if (allowedActions.includes(rbacActions.CreateAuthorizationRequest)) {
        tools.push(<AddRecordButton key="add"/>);
    }

    return tools;
};

export const IndexPageHeader = () => {
    return (
        <SimplePageHeader
            title={I18n.get('AuthorizationRequests')}
            icon={<AuthorizationRequestIcon/>}
            tools={useTools()}
        />
    );
};
