import {stage} from './stage';

type ConfigShape = {
    [key: string]: {
        region: string,
        consoleDistributionUrl: string,
        platform: {
            imagesBucket: string,
            imagesDistributionUrl: string,
        },
    }
}

const config: ConfigShape = {
    prd: {
        region: 'us-west-2',
        consoleDistributionUrl: 'https://console-dev.nextcloud.aero/assets',
        platform: {
            imagesBucket: 'pac-platform-images-us-west-2',
            imagesDistributionUrl: 'https://d2g0vtxljsrm9e.cloudfront.net',
        },
    },
    test: {
        region: 'us-west-2',
        consoleDistributionUrl: 'https://console-dev.nextcloud.aero/assets',
        platform: {
            imagesBucket: 'pac-platform-images-us-west-2',
            imagesDistributionUrl: 'https://d2g0vtxljsrm9e.cloudfront.net',
        },
    },
    dev: {
        region: 'us-west-2',
        consoleDistributionUrl: 'https://console-dev.nextcloud.aero/assets',
        platform: {
            imagesBucket: 'pac-platform-images-us-west-2',
            imagesDistributionUrl: 'https://d2g0vtxljsrm9e.cloudfront.net',
        },
    },
    ide: {
        region: 'us-west-2',
        consoleDistributionUrl: 'https://console-dev.nextcloud.aero/assets',
        platform: {
            imagesBucket: 'pac-platform-images-us-west-2',
            imagesDistributionUrl: 'https://d2g0vtxljsrm9e.cloudfront.net',
        },
    },
};

export default config[stage];
