import {FunctionComponent} from 'react';

import {
    Navigate,
    Route,
    Routes
} from 'react-router-dom';

import {IndexPage} from './pages/IndexPage';
import {AddPage} from './pages/AddPage';
import {EditPage} from './pages/EditPage';
import {useVerifiedPermissions} from "../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../config/rbac/actions.ts";

export const PolicyStoresLayout: FunctionComponent = () => {

    const {allowedActions} = useVerifiedPermissions()

    return (
        <Routes>
            <Route
                path="/"
                element={
                    (allowedActions.includes(rbacActions.ListPolicyStores) ? (
                        <IndexPage/>
                    ) : (
                        <Navigate to="/"/>
                    ))
                }
            />
            <Route
                path="add"
                element={
                    (allowedActions.includes(rbacActions.CreatePolicyStore) ? (
                        <AddPage/>
                    ) : (
                        <Navigate to="/policy-stores"/>
                    ))
                }
            />
            <Route
                path=":id"
                element={
                    (allowedActions.includes(rbacActions.GetPolicyStore) ? (
                        <EditPage/>
                    ) : (
                        <Navigate to="/policy-stores"/>
                    ))
                }
            >
            </Route>

        </Routes>
    );
};
