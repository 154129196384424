import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update
} from '../../deployment-environments/services/DeploymentEnvironmentsService';

import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase
} from '../../../store/RecordsSliceCaseHelpers';
import {DeploymentEnvironment} from "../types.ts";
import {moduleConfig} from "../config";


const entityKey = moduleConfig.entityKey;

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
};

const normalizeRecord = (record: DeploymentEnvironment) => {
    return Object.assign({}, record,);
};

// genenrates pending, fulfilled and rejected
export const fetchDeploymentEnvironments = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    (query: object) => {
        return fetchRecords(query)
            .then((response) => {
                return response?.map((record) => {
                    return normalizeRecord(record);
                });
            });
    },
);

export const fetchDeploymentEnvironment = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                return normalizeRecord(response as DeploymentEnvironment);
            });
    });


export const saveDeploymentEnvironment = createAsyncThunk(
    `${entityKey}/add`,
    (payload: DeploymentEnvironment) => {
        return add(payload)
            .then((response) => normalizeRecord(response as DeploymentEnvironment));
    });

export const updateDeploymentEnvironment = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record
     }: {
        id: string,
        record: DeploymentEnvironment
    }) => {

        return update(id, record)
            .then((response) => {
                return normalizeRecord(response as DeploymentEnvironment);
            });
    },
);

export const deleteDeploymentEnvironment = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    },
);

const deploymentEnvironmentsSlice = createSlice({
                                                    name: entityKey,
                                                    initialState,
                                                    reducers: {},
                                                    extraReducers: (builder) => {
                                                        // FETCH MANY
                                                        fetchManyCase(builder, fetchDeploymentEnvironments, entityKey);

                                                        // FETCH ONE
                                                        fetchOneCase(builder, fetchDeploymentEnvironment);

                                                        // SAVE ONE
                                                        saveOneCase(builder, saveDeploymentEnvironment);

                                                        // UPDATE ONE
                                                        updateOneCase(builder, updateDeploymentEnvironment);

                                                        // DELETE ONE
                                                        deleteOneCase(builder, deleteDeploymentEnvironment);
                                                    },
                                                });

export default deploymentEnvironmentsSlice.reducer;
