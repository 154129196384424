import {I18n} from '@aws-amplify/core';

import {NextAddButton} from '@pac/platform-ui-components';

import {useNavigate} from 'react-router-dom';


export const AddRecordButton = () => {
    const navigate = useNavigate();
    return (
        <NextAddButton
            onClick={() => {
                navigate('entity-types/add');
            }}
        >
            {I18n.get('Add Entity Type')}
        </NextAddButton>
    );
};

