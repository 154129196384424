export const createAuthorizationRequest = `
  mutation createAuthorizationRequest($input: CreateAuthorizationRequestInput!) {
    createAuthorizationRequest(input: $input){
      id
      permissionProfileId
      actionId
      actionType
      context
      principalId
      principalType
      resourceId
      resourceType
      decision
      created
      updated
    }
  }  
`;

export const updateAuthorizationRequest = `
  mutation updateAuthorizationRequest($input: UpdateAuthorizationRequestInput!) {
    updateAuthorizationRequest(input: $input){
      id
      permissionProfileId
      actionId
      actionType
      context
      principalId
      principalType
      resourceId
      resourceType
      decision
      created
      updated
    }
  }
`;

export const deleteAuthorizationRequest = `
  mutation deleteAuthorizationRequest($input: DeleteAuthorizationRequestInput!) {
    deleteAuthorizationRequest(input: $input){
      id
    }
  }  
`;
