export const createPolicy = `
  mutation createPolicy($input: CreatePolicyInput!) {
    createPolicy(input: $input){
      policyId
      policyStoreId
      policyType
      principal {
        entityId
        entityType
      }
      resource {
        entityId
        entityType
      }
      definition {
        static {
            description
            statement
        }
      }
      createdDate
    }
  }  
`;

export const updatePolicy = `
  mutation updatePolicy($input: UpdatePolicyInput!) {
    updatePolicy(input: $input){
      policyId
      policyStoreId
      policyType
      principal {
        entityId
        entityType
      }
      resource {
        entityId
        entityType
      }
      definition {
        static {
            description
            statement
        }
      }
      createdDate
      lastUpdatedDate
    }
  }
`;

export const deletePolicy = `
  mutation deletePolicy($input: DeletePolicyInput!) {
    deletePolicy(input: $input){
      policyId
    }
  }  
`;
