import {
    notifyError,
    notifySuccess
} from "../components/ServiceResponseNotifications.tsx";
import accounts from "../config/accounts.ts";
import {
    AssumeRoleCommand,
    STSClient
} from "@aws-sdk/client-sts";
import {fetchAuthSession} from "@aws-amplify/auth";

import {
    del,
    get,
    post,
    put
} from 'aws-amplify/api';


export const list = async (apiName: string, collectionPath: string, customQuery?: { [key: string]: any }) => {

    const query = {...customQuery ?? {}};

    const path = collectionPath;

    if (typeof query.page !== 'undefined') {
        //query.offset = (query.page - 1) * query.limit;

        if (query.page === 1) {
            query.offset = 0
        } else {
            query.offset = (query.page - 1) * query.limit;
        }

        delete query.page;
    }

    const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
    const headers = {
        'Authorization': authToken,
    };

    const options = {
        queryStringParameters: query,
        headers: headers,
        response: true,
    };

    const body = await makeGetApiCall(apiName, path, options);
    return body;
}

export const fetch = async (apiName: string, collectionPath: string, recordId: string, query?: object) => {
    const path = [collectionPath, recordId].join('/');

    const queryStringParameters = {
        'response-group': 'large',
        ...query
    };

    const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
    const headers = {
        'Authorization': authToken,
    };

    const options = {
        queryStringParameters: queryStringParameters,
        headers: headers,
        response: true,
    };

    return makeGetApiCall(apiName, path, options);
}

export const add = async (apiName: string, collectionPath: string, payload: FormData) => {
    const path = collectionPath;


    const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': authToken as string,
    };

    const options = {
        body: payload,
        headers: headers,
        response: true,
    };

    const operation = post(
        {
            apiName,
            path,
            options
        }
    );
    const response = await operation.response;

    if (response?.headers.location) {
        return makeGetApiCall(
            apiName,
            response.headers.location,
            {
                headers: headers,
                response: true
            }
        );
    } else {
        return response;
    }
}

export const update = async (apiName: string, collectionPath: string, recordId: string, payload: object) => {
    const path = [collectionPath, recordId].join('/');

    const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': authToken as string,
    };

    const options = {
        body: payload,
        headers: headers,
        response: true,
    };

    return makePutApiCall(apiName, path, options);
}

export const remove = async (apiName: string, collectionPath: string, recordId: string) => {
    const path = [collectionPath, recordId].join('/');

    const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();

    const headers = {
        'Authorization': authToken as string,
    };

    const options = {
        headers: headers,
        response: true
    };
    return makeDeleteApiCall(apiName, path, options);
}


// private methods

export const makeGetApiCall = async (apiName: string, path: string, options: { [key: string]: any }) => {
    console.log(`making GET call to ${apiName} api with path: ${path}`);
    console.log('GET call options', options);

    try {
        const operation = get(
            {
                apiName,
                path,
                options
            }
        );

        const {
            body,
            headers,
        } = await operation.response;
        console.debug('response', body, headers);
        return await body.json();

    } catch (e: unknown) {
        notifyError(e)
    }
}

export const makePostApiCall = async (apiName: string, path: string, options: { [key: string]: any }) => {
    console.log(`making POST call to ${apiName} api with path: ${path}`);
    console.log('POST call options', options);

    try {
        const operation = post(
            {
                apiName,
                path,
                options
            }
        );
        const {body} = await operation.response;
        notifySuccess('Record created')
        return await body.json();

    } catch (e: unknown) {
        notifyError(e)
    }
}

export const makePutApiCall = async (apiName: string, path: string, options: { [key: string]: any }) => {
    console.log(`making PUT call to ${apiName} api with path: ${path}`);
    console.log('PUT call options', options);

    try {
        const operation = put(
            {
                apiName,
                path,
                options
            }
        );
        const response = await operation.response;
        notifySuccess('Record updated')
        return response

    } catch (e: any) {
        notifyError(e)
    }
}

export const makeDeleteApiCall = async (apiName: string, path: string, options: { [key: string]: any }) => {
    console.log(`making DELETE call to ${apiName} api with path: ${path}`);
    console.log('DELETE call options', options);

    try {
        const operation = del(
            {
                apiName,
                path,
                options
            }
        );
        const response = await operation.response;
        notifySuccess('Record deleted')
        return response;

    } catch (e: unknown) {
        notifyError(e)
    }
}


export function isDefaultAccount(accountId: string) {
    let isDefaultAccount = false;

    for (let i = 0; i < accounts.length; i++) {
        if (accounts[i].id === accountId) {
            if (accounts[i].isDefault === true) {
                isDefaultAccount = true;
            }
        }
    }

    return isDefaultAccount;
}

export async function getCrossAccountCredentials(serviceAccountId: string) {
    const {credentials} = (await fetchAuthSession());

    const client = new STSClient({
                                     region: 'us-west-2',
                                     credentials: credentials,
                                 });

    return new Promise((resolve, reject) => {
        const timestamp = new Date().getTime();

        const params = {
            RoleArn: `arn:aws:iam::${serviceAccountId}:role/pac-shared-svcs-apimgmt-role`,
            RoleSessionName: `be-descriptibe-here-${timestamp}`,
        };

        const command = new AssumeRoleCommand(params);
        client.send(command)
              .then(
                  (data) => {

                      if (typeof data.Credentials?.AccessKeyId !== 'undefined' && typeof data.Credentials.SecretAccessKey !== 'undefined' && typeof data.Credentials.SessionToken !== 'undefined') {
                          resolve({
                                      accessKeyId: data.Credentials.AccessKeyId,
                                      secretAccessKey: data.Credentials.SecretAccessKey,
                                      sessionToken: data.Credentials.SessionToken,
                                  });
                      }

                      reject('data credentials are not set')

                  },
                  (error) => {
                      console.trace(error);
                      reject(error);
                  }
              );
    });
}
