import {
    Center,
    Container,
    Grid,
    Group,
    Stack,
    Text,
    Title
} from '@mantine/core';
import {NavLink} from 'react-router-dom';
import {
    AiOutlineArrowRight,
    AiOutlineExclamationCircle
} from "react-icons/ai";
import {SimplePageContentWrapper} from "@pac/platform-ui-components";

export const ForbiddenPage = () => {
    return (
        <Container mt={"xl"}>
            <SimplePageContentWrapper>
                <Grid>
                    <Grid.Col span={4}>
                        <Center pt={'sm'}>
                            <AiOutlineExclamationCircle color={'red'} size={'4em'}/>
                        </Center>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <Stack gap={'xl'}>
                            <Title order={1}>403 Forbidden</Title>
                            <NavLink to="/">
                                <Group>
                                    <Text>Home Page</Text>
                                    <AiOutlineArrowRight size={'1em'}/>
                                </Group>
                            </NavLink>
                        </Stack>
                    </Grid.Col>
                </Grid>
            </SimplePageContentWrapper>
        </Container>
    );
};
