import {
    Group,
    Stack,
    Text
} from '@mantine/core';

import {NavLink} from "react-router-dom";
import {NEXTPolicy} from "../types.ts";

import {
    PlatformPropertiesCard,
    PlatformPropertiesCardProperty
} from '@pac/platform-ui-components'
import {ProfilePolicyBlock} from "./ProfilePolicyBlock.tsx";


const EntityBlock = ({
                         permissionProfileId,
                         element,
                         entity
                     }: {
    permissionProfileId: string,
    element: string,
    entity: { entityType: string, entityId: string }
}) => {
    return (
        <Stack gap={'xs'}>
            <Group ml={'1em'} gap={'xs'}><Text
                fw={600}>Entity Type:</Text><Text>{entity.entityType}</Text></Group>
            <Group ml={'1em'} gap={'xs'}><Text
                fw={600}>Entity Id:</Text><Text>{entity.entityId}</Text><NavLink
                to={`/permission-profiles/${permissionProfileId}/policies?element=${element}&entityType=${entity.entityType}&entityId=${entity.entityId}`}>Policies</NavLink></Group>
        </Stack>
    );
};

export const Profile = ({record}: { record: NEXTPolicy }) => {


    const properties = [
        {
            label: 'Policy ID',
            value: record.policyId,
        }, {
            label: 'Permission Profile ID',
            value: record.permissionProfileId,
        }, {
            label: 'Inline Policy Definition',
            value: <ProfilePolicyBlock policy={record}/>,
            span: 12,
        }, {
            label: 'Principal',
            value: record.principal ?
                <EntityBlock permissionProfileId={record.permissionProfileId} element={'Principal'}
                             entity={record.principal}/> : 'All'
        }, {
            label: 'Resource',
            value: record.resource ? <EntityBlock permissionProfileId={record.permissionProfileId} element={'Resource'}
                                                  entity={record.resource}/> : 'All'
        }, {
            label: 'Policy Type',
            value: record.policyType,
            span: 12
        },
        {
            label: 'Created',
            value: record.createdDate ? record.createdDate : 'n/a',
            formatAs: 'Timestamp',
        },
        {
            label: 'Last Updated',
            value: record.lastUpdatedDate ? record.lastUpdatedDate : 'n/a',
            formatAs: 'Timestamp',
        }] as PlatformPropertiesCardProperty[];

    return (
        <PlatformPropertiesCard cols={2} properties={properties}/>
    );

};
