import {
    Field,
    FormikProps
} from 'formik';

import {I18n} from '@aws-amplify/core';
import {
    PacFormikDropdown,
    PacFormikInput,
    PacFormikRadioGroup,
    PacFormikTextarea
} from "@pac/platform-ui-components";
import {
    Fieldset,
    Stack
} from '@mantine/core';
import {DataSource} from "../types.ts";

export const FormComponent = (props: FormikProps<DataSource>) => {

    const values = props.values
    const editing = values.id !== undefined
    console.debug('FORM VALUES', values, editing)

    return (
        <Stack gap={'xl'}>
            <Fieldset fw={600} tt={'uppercase'} legend={'Service Details'}>

                <Field
                    name='name'
                    label='Name'
                    placeholder={I18n.get('Name')}
                    component={PacFormikInput}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='description'
                    label='Description'
                    placeholder={I18n.get('Description')}
                    component={PacFormikTextarea}
                    my={'xl'}
                />


            </Fieldset>

            <Fieldset fw={600} tt={'uppercase'} legend={'Query'}>

                <Field
                    name='queryUrl'
                    label='Query URL'
                    placeholder={I18n.get('Enter Query URL. i.e. https://api.myservice.com/v1/users')}
                    component={PacFormikInput}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='dataFormat'
                    label='Data Format'
                    placeholder={I18n.get('Data Format')}
                    component={PacFormikDropdown}
                    data={[
                        {
                            value: 'JSON',
                            label: 'JSON'
                        },
                        {
                            value: 'XML',
                            label: 'XML'
                        },
                        {
                            value: 'CSV',
                            label: 'CSV'
                        },
                    ]}
                    my={'xl'}
                />

                <Field
                    name='encoding'
                    label='Encoding'
                    placeholder={I18n.get('Data Encoding')}
                    component={PacFormikDropdown}
                    data={[
                        {
                            value: 'UTF-8',
                            label: 'UTF-8'
                        },
                        {
                            value: 'UTF-16',
                            label: 'UTF-16'
                        },
                        {
                            value: 'UTF-32',
                            label: 'UTF-32'
                        },
                    ]}
                    my={'xl'}
                />

                <Field
                    name='httpMethod'
                    label='HTTP Method'
                    placeholder={I18n.get('HTTP Method')}
                    component={PacFormikDropdown}
                    data={[
                        {
                            value: 'GET',
                            label: 'GET'
                        },
                        {
                            value: 'POST',
                            label: 'POST'
                        },
                        {
                            value: 'PUT',
                            label: 'PUT'
                        },
                        {
                            value: 'DELETE',
                            label: 'DELETE'
                        },
                        {
                            value: 'PATCH',
                            label: 'PATCH'
                        },
                        {
                            value: 'HEAD',
                            label: 'HEAD'
                        },
                        {
                            value: 'OPTIONS',
                            label: 'OPTIONS'
                        },
                        {
                            value: 'TRACE',
                            label: 'TRACE'
                        }
                    ]}
                    my={'xl'}
                />

            </Fieldset>

            <Fieldset fw={600} tt={'uppercase'} legend={'Query Parameters'}>
                {/*

                <Field
                    name='queryParameters'
                    placeholder={I18n.get('Query Parameters')}
                    component={PacFormikList}
                    getRowElements={() => {
                        return [
                            {
                                name: 'name',
                                label: 'Name',
                                placeholder: I18n.get('Name'),
                                component: PacFormikInput,
                                withAsterisk: true
                            },
                            {
                                name: 'value',
                                label: 'Value',
                                placeholder: I18n.get('Value'),
                                component: PacFormikInput,
                                withAsterisk: true
                            }
                        ]
                    }}
                    my={'xl'}
                />

                */}


            </Fieldset>

            <Fieldset fw={600} tt={'uppercase'} legend={'Authentication'}>

                <Field
                    name='authenticationType'
                    label='Authentication Type'
                    placeholder={I18n.get('Authentication Type')}
                    component={PacFormikRadioGroup}
                    options={[
                        {
                            value: 'NONE',
                            label: 'None'
                        },
                        {
                            value: 'OAUTH2',
                            label: 'OAuth2'
                        },
                        {
                            value: 'API_KEY',
                            label: 'API Key'
                        },
                        {
                            value: 'BASIC_AUTH',
                            label: 'Basic Auth'
                        },
                        {
                            value: 'BEARER_TOKEN',
                            label: 'Bearer Token'
                        }
                    ]}
                    my={'xl'}
                />


                {
                    values.authenticationType === 'API_KEY' && (
                        <Field
                            name='apiKey'
                            label='API Key'
                            placeholder={I18n.get('API Key')}
                            component={PacFormikInput}
                            my={'xl'}
                        />
                    )
                }

                {
                    values.authenticationType === 'BASIC_AUTH' && (
                        <>
                            <Field
                                name='basicAuthUsername'
                                label='Username'
                                placeholder={I18n.get('Username')}
                                component={PacFormikInput}
                                my={'xl'}
                            />
                            <Field
                                name='basicAuthPassword'
                                label='Password'
                                placeholder={I18n.get('Password')}
                                component={PacFormikInput}
                                my={'xl'}
                            />
                        </>
                    )
                }

                {
                    values.authenticationType === 'BEARER_TOKEN' && (
                        <Field
                            name='bearerToken'
                            label='Bearer Token'
                            placeholder={I18n.get('Bearer Token')}
                            component={PacFormikInput}
                            my={'xl'}
                        />
                    )
                }


            </Fieldset>

        </Stack>
    );
};

