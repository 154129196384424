import {stage} from '../stage';
import {ResourcesConfig} from "aws-amplify";

type ConfigShape = {
    [key: string]: {
        domain?: string,
        redirectSignIn: string[],
        redirectSignOut: string[],
        identityPoolId: string,
        region: string,
        userPoolId?: string,
        userPoolClientId?: string,
        cookieStorageDomain?: string,
    }
}


const cognito: ConfigShape = {
    prd: {
        domain: 'login.nextcloud.aero',
        redirectSignIn: ['https://permissions.nextcloud.aero'],
        redirectSignOut: ['https://permissions.nextcloud.aero'],
        region: 'us-west-2',
        userPoolId: 'us-west-2_1f0VHRTZ9',
        userPoolClientId: '67nfjf0dhtv7ik5aet8colcuki',
        identityPoolId: 'us-west-2:35164169-98e6-45f7-aa61-80b5b9daddd1',
        cookieStorageDomain: '.nextcloud.aero',
    },
    test: {
        domain: 'login-test.nextcloud.aero',
        redirectSignIn: ['https://permissions-test.nextcloud.aero'],
        redirectSignOut: ['https://permissions-test.nextcloud.aero'],
        region: 'us-west-2',
        userPoolId: 'us-west-2_Jcz7Vrpul',
        userPoolClientId: '4485sglmgolkitrbcuqd72d498',
        identityPoolId: 'us-west-2:bb150987-bdca-4ff0-b2c7-210d39f97d49',
        cookieStorageDomain: '.nextcloud.aero',
    },
    dev: {
        domain: 'login-dev.nextcloud.aero',
        redirectSignIn: ['https://permissions-dev.nextcloud.aero'],
        redirectSignOut: ['https://permissions-dev.nextcloud.aero'],
        region: 'us-west-2',
        userPoolId: 'us-west-2_xr1nstZBn',
        userPoolClientId: '7hh67r47qv204hse4gojnd2r0p',
        identityPoolId: 'us-west-2:e4ff6eb2-6eaa-4f84-8970-ba3a03f62c54',
        cookieStorageDomain: '.nextcloud.aero',
    },
    ide: {
        domain: 'login-dev.nextcloud.aero',
        redirectSignIn: ['https://localhost:8084'],
        redirectSignOut: ['https://localhost:8080'],
        region: 'us-west-2',
        userPoolId: 'us-west-2_xr1nstZBn',
        userPoolClientId: '7hh67r47qv204hse4gojnd2r0p',
        identityPoolId: 'us-west-2:e4ff6eb2-6eaa-4f84-8970-ba3a03f62c54',
        cookieStorageDomain: '.vfs.cloud9.us-west-2.amazonaws.com',
    },
};


export const config = {
    Cognito: {
        // Amazon Cognito User Pool ID
        userPoolId: cognito[stage].userPoolId,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolClientId: cognito[stage].userPoolClientId,

        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: cognito[stage].identityPoolId,

        // OPTIONAL - Set to true to use your identity pool's unauthenticated role when user is not logged in
        allowGuestAccess: false,

        loginWith: {
            oauth: {
                // Domain name
                domain: cognito[stage].domain,

                // Authorized scopes
                scopes: [
                    'phone',
                    'email',
                    'profile',
                    'openid',
                    'aws.cognito.signin.user.admin',
                ],

                // Callback URL
                redirectSignIn: cognito[stage].redirectSignIn,

                // Sign out URL
                redirectSignOut: cognito[stage].redirectSignOut,

                // 'code' for Authorization code grant,
                // 'token' for Implicit grant
                responseType: 'token',
            },
        }


    }
} as ResourcesConfig['Auth'];