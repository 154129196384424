import {
    deleteDataSource,
    fetchDataSource,
    saveDataSource,
    updateDataSource
} from '../reducers/recordsSlice';

import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";
import {DataSource} from "../types.ts";

export const useDataSource = (dataSourceId?: string) => {
    const dispatch = useAppDispatch();

    const {
        record,
        loading
    } = useAppSelector((state) =>
                           recordSelector(state, dataSourceId ? dataSourceId : ''),
    );


    // FETCH
    const handleFetchRecord = () => {

        if (typeof dataSourceId === 'undefined') {
            throw new Error('dataSourceId is required parameter')
        }

        dispatch(fetchDataSource(dataSourceId));
    };


    // CREATE, UPDATE DELETE
    const handleCreateRecord = (values: DataSource) => {
        dispatch(saveDataSource(values));
    };

    const handleUpdateRecord = (values: DataSource) => {

        if (typeof dataSourceId === 'undefined') {
            throw new Error('dataSourceId is required parameter')
        }

        dispatch(updateDataSource({
                                      id: dataSourceId,
                                      record: values
                                  }));
    };

    const handleDeleteRecord = () => {

        if (typeof dataSourceId === 'undefined') {
            throw new Error('dataSourceId is required parameter')
        }

        dispatch(deleteDataSource(dataSourceId));
    };


    return {
        record,
        loading,
        handleFetchRecord,
        handleCreateRecord,
        handleUpdateRecord,
        handleDeleteRecord,
    };
};
