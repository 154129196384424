import {
    configureStore,
    Store
} from '@reduxjs/toolkit';

import {PermissionProfilesReducer} from '../features/permission-profiles/reducers/index';

import {PoliciesReducer} from '../features/policies/reducers/index';
import {PolicyTemplatesReducer} from '../features/policy-templates/reducers/index';
import {PolicyStoresReducer} from '../features/policy-stores/reducers/index';
import {ActionsReducer} from '../features/actions/reducers/index';
import {EntityTypesReducer} from '../features/entity-types/reducers/index';


import {ApplicationsReducer} from '../features/applications/reducers/index';
import {AuthorizationRequestsReducer} from '../features/authorization-requests/reducers/index';
import {UsersReducer} from "../features/users/reducers";
import {TenantsReducer} from "../features/tenants/reducers";
import {IdentitySourcesReducer} from "../features/identity-sources/reducers";
import {PermissionGroupsReducer} from "../features/permission-groups/reducers";
import {IdentityProvidersReducer} from "../features/identity-providers/reducers";
import {DeploymentEnvironmentsReducer} from "../features/deployment-environments/reducers";
import {PlatformAccountsReducer} from "../features/platform-accounts/reducers";
import {DataSourcesReducer} from "../features/data-sources/reducers";

export const reducers = {
    actions: ActionsReducer,
    applications: ApplicationsReducer,
    authorizationRequests: AuthorizationRequestsReducer,
    entityTypes: EntityTypesReducer,
    permissionGroups: PermissionGroupsReducer,
    permissionProfiles: PermissionProfilesReducer,
    policies: PoliciesReducer,
    policyTemplates: PolicyTemplatesReducer,
    policyStores: PolicyStoresReducer,
    users: UsersReducer,
    tenants: TenantsReducer,
    identitySources: IdentitySourcesReducer,
    identityProviders: IdentityProvidersReducer,
    deploymentEnvironments: DeploymentEnvironmentsReducer,
    platformAccounts: PlatformAccountsReducer,
    dataSources: DataSourcesReducer
};

// const sortObj = (obj: { [key: string]: string }) => {
//     return Object.keys(obj)
//                  .sort()
//                  .reduce(function (result: { [key: string]: string }, key) {
//                      result[key] = obj[key];
//                      return result;
//                  }, {});
// };

// const sorted = sortObj(reducers);
//
// export const setupStore = (preloadedState) => {
//     return configureStore({
//                               reducer: sorted,
//                               middleware: (getDefaultMiddleware) =>
//                                   getDefaultMiddleware({
//                                                            serializableCheck: false,
//                                                        }),
//                               preloadedState,
//                           });
// };

const preloadedState = {};

// console.debug('PRELOADED', preloadedState);

// added this middleware  to disable this error - A non-serializable value was detected in the state, in the path...
// @todo disable if better way is found
export const store: Store = configureStore({
                                               reducer: reducers,
                                               middleware: (getDefaultMiddleware) =>
                                                   getDefaultMiddleware({
                                                                            serializableCheck: false,
                                                                        }),
                                               preloadedState,
                                           });


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch