import {FunctionComponent} from 'react';

import {Stack} from '@mantine/core';

import {
    PlatformTabbedComponent,
    PlatformTabComponentTab,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';

import {IndexPage as AuthorizationRequestsIndex} from "../../authorization-requests/pages/IndexPage";

export const AuthorizationRequestsPageSegment: FunctionComponent = () => {

    const transactionsTabs = [
        {
            title: 'Authorization Requests',
            component: <AuthorizationRequestsIndex/>
        },
    ] as PlatformTabComponentTab[]


    return (
        <Stack gap='lg'>

            <SimplePageContentWrapper>
                <PlatformTabbedComponent
                    tabs={transactionsTabs}></PlatformTabbedComponent>
            </SimplePageContentWrapper>

        </Stack>

    );
};
