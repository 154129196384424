import {stage} from './stage';

type ConfigShape = {
    [key: string]: {
        domain: string,
    }
}

const zones: ConfigShape = {
    prd: {
        domain: 'email.nextcloud.aero',
    },
    test: {
        domain: 'email-test.nextcloud.aero',
    },
    dev: {
        domain: 'email-dev.nextcloud.aero',
    },
    ide: {
        domain: 'email-dev.nextcloud.aero',
    },
};

const config = {
    domain: zones[stage].domain,
};

export default config;
