import App from './app';
import StaticAssets from './static-assets';
import RUM from './cloudwatch-rum';
import about from './about';
import emailHostedZones from './email-hosted-zones';
import platform from './platform';
import permissions from "./permissions.ts";


const config = {
    RUM: RUM,
    portal: App,
    platform: platform,
    staticAssets: StaticAssets,
    enableKeepAlive: false,
    about: about,
    emailHostedZones: emailHostedZones,
    permissions: permissions,
};

export default config;
