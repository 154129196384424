import {config as API} from './api';
import {config as Auth} from './auth';
import {ResourcesConfig} from "aws-amplify";

import AppSyncApis from './appsync';

const endpoint = AppSyncApis.endpoint;

export const config = {
    Auth: Auth,
    API: {
        REST: API,
        GraphQL: {
            endpoint: endpoint,
            region: 'us-west-2',
            defaultAuthMode: 'userPool'
        }
    },
    // Analytics: Analytics,
    // Storage: Storage,
} as ResourcesConfig;