import {Field} from 'formik';

import {I18n} from '@aws-amplify/core';

import {SimpleGrid} from '@mantine/core';

import {PacFormikInput} from '@pac/platform-ui-components';
import {EntityTypesAutocomplete} from "../inputs/EntityTypesAutocomplete.tsx";


export const FormComponent = () => {

    return (
        <SimpleGrid cols={1}>

            <Field
                name='name'
                label='Entity Type Name'
                placeholder={I18n.get('Entity Type Name')}
                component={PacFormikInput}
            />

            <Field
                name='memberOfTypesIds'
                label='Member Of Types'
                placeholder={I18n.get('Member Of Types')}
                component={EntityTypesAutocomplete}
            />

            <Field
                name='recordType'
                label='Record Type'
                placeholder={I18n.get('Record Type')}
                component={PacFormikInput}
            />


        </SimpleGrid>
    );
};
