export const listAuthorizationRequests = `
query listAuthorizationRequests($filter: AuthorizationRequestsFilterInput) {
  listAuthorizationRequests(filter: $filter) {
    items {
      id
      permissionProfileId
      actionId
      actionType
      context
      principalId
      principalType
      resourceId
      resourceType
      decision
      created
      updated
    }
  }
}
`

export const getAuthorizationRequest = `
  query getAuthorizationRequest($id: ID!) {
    getAuthorizationRequest(id: $id){
      id
      permissionProfileId
      actionId
      actionType
      context
      principalId
      principalType
      resourceId
      resourceType
      decision
      created
      updated
    }
  }  
`;

export const replayAuthorizationRequest = `
  query isAuthorized($request: AuthorizationRequestInput!) {
    isAuthorized(request: $request){
      Decision
    }
  }  
`;