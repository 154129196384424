export const listDeploymentEnvironments = `
query listDeploymentEnvironments($filter: DeploymentEnvironmentsFilterInput!, $limit: Int, $nextToken: String) {
  listDeploymentEnvironments(filter: $filter, limit: $limit, nextToken:$nextToken) {
    items {
      id
      platformAccountId
      platformAccount {
        accountId
        name
      }
      region
      isDefault
      createdByUsername
      managedByUsername
      created
      updated
    }
  }
}
`

export const getDeploymentEnvironment = `
  query getDeploymentEnvironment($id: ID!) {
    getDeploymentEnvironment(id: $id){
      id
      platformAccountId
      platformAccount {
        accountId
        name
      }
      region
      isDefault
      createdByUsername
      managedByUsername
      created
      updated
    }
  }  
`;