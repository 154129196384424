import {Navigate} from 'react-router-dom';

import {useAuth} from '../hooks/useAuth';
import {ReactNode} from "react";

export const RequireAuth = ({children}: { children: ReactNode }) => {
    const {user} = useAuth();
    
    if (typeof user === 'undefined') {
        return <Navigate to="/"/>;
    }

    return <>{children}</>;
};

