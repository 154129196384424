import {
    addToSelection,
    clearKeyword,
    clearSelection,
    removeFromSelection,
    setKeyword,
    setPage,
    setPageSize,
} from '../reducers/index';

import {
    clearState,
    deleteIdentitySource,
    fetchIdentitySources
} from '../reducers/recordsSlice';

import {
    recordsSelector,
    selectionSelector,
    visibilitySelector
} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";

export const useIdentitySources = (permissionProfileId: string) => {
    const dispatch = useAppDispatch();

    if (typeof permissionProfileId === 'undefined') {
        throw new Error('permissionProfileId is required parameter')
    }

    const selection = useAppSelector((state) => selectionSelector(state));

    const {
        items,
        visibleItems,
        limit,
        keyword,
        page,
        totalPages,
        visibleCount
    } = useAppSelector((state) => visibilitySelector(state, permissionProfileId));

    const {loading} = useAppSelector((state) =>
                                         recordsSelector(state),
    );

    // FETCH
    const handleFetchRecords = (query?: object) => {
        if (query) {
            console.debug('query', query)
        }
        dispatch(fetchIdentitySources({
                                          permissionProfileId
                                      }))
    };

    const handleReloadRecords = (query?: object) => {
        if (query) {
            console.debug('query', query)
        }

        dispatch(clearState());
        dispatch(fetchIdentitySources({
                                          permissionProfileId,
                                      }))
    };

    const handleDeleteRecords = () => {
        if (Object.keys(selection).length > 0) {
            for (const key in selection) {
                dispatch(deleteIdentitySource({
                                                  permissionProfileId: permissionProfileId,
                                                  id: key
                                              }));
            }
        }
    };

    // SELECTION
    const handleRowSelection = (row: { id: string }) => {
        if (typeof selection[row.id] !== 'undefined') {
            dispatch(removeFromSelection(row.id));
        } else {
            dispatch(addToSelection(row));
        }
    };

    const handleSelectAllRows = () => {
        for (let i = 0; i < items.length; i++) {
            if (typeof selection[items[i].id] !== 'undefined') {
                dispatch(removeFromSelection(items[i].id));
            } else {
                dispatch(addToSelection(items[i]));
            }
        }
    };

    // FILTERING AND PAGING
    const handleFilterByKeyword = (keyword: string) => {
        if (keyword !== '') {
            dispatch(setKeyword(keyword));
        } else {
            dispatch(clearKeyword());
        }
    };

    const handleItemsPerPageChange = (pageSize: string | number | null) => {
        dispatch(setPageSize(pageSize));
    };

    const handlePageChange = (page: number) => {
        dispatch(setPage(page));
    };

    // BATCH ACTIONS
    const handleBatchActionCancel = () => {
        dispatch(clearSelection());
    };

    return {
        items,
        visibleItems,
        limit,
        loading,
        selection,
        keyword,
        page,
        totalPages,
        visibleCount,
        handleRowSelection,
        handleSelectAllRows,
        handleFetchRecords,
        handleReloadRecords,
        handleDeleteRecords,
        handleFilterByKeyword,
        handleItemsPerPageChange,
        handleBatchActionCancel,
        handlePageChange,
    };
};
