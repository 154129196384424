const translationPairs = {
    Home: "Home",
    Customers: 'Customers',
    Applications: 'Applications',
    Users: 'Users',
    Identities: "Identities",
    "Platform Management": "Platform Management",
    "Audit Log": "Audit Log",
    Name: "Name",
    Action: "Action",
    Created: "Created",
};

export default translationPairs;
