import {FunctionComponent} from 'react';
import {
    PlatformNavbar,
    PlatformPropertiesCardSkeleton
} from "@pac/platform-ui-components";
import {getMenuItems} from "../data/menus.tsx";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";


export const PortalNavbar: FunctionComponent = () => {


    const menuItems = getMenuItems();

    const {
        authorizing,
        allowedActions,
    } = useVerifiedPermissions();

    if (authorizing) {
        return <PlatformPropertiesCardSkeleton/>;
    }


    const authorizedMenuItems = menuItems.filter((item) => {
        return allowedActions.includes(item.rbac as string);
    });

    if (Array.isArray(authorizedMenuItems) && authorizedMenuItems.length === 0) {
        return null;
    }

    return (
        <PlatformNavbar navbarHeader={'PAC Permissions'} menuItems={authorizedMenuItems}/>
    );
};

