import {NEXTPolicyStore} from "../types.ts";
import {
    PlatformPropertiesCard,
    PlatformPropertiesCardCopyButtonDecorator
} from "@pac/platform-ui-components";


export const Profile = ({record}: { record: NEXTPolicyStore }) => {


    const properties = [
        {
            label: 'Policy Store ID',
            value: record.policyStoreId,
            decorator: (value: string) => <PlatformPropertiesCardCopyButtonDecorator value={value}/>,
        }, {
            label: 'Description',
            value: record.description,
        }, {
            label: 'Validation Settings Mode',
            value: record.validationSettings ? record.validationSettings.mode : 'n/a',
        }, {
            label: 'ARN',
            value: record.arn,
        },
        {
            label: 'Created Date',
            value: record.createdDate ? record.createdDate : 'n/a',
            formatAs: 'Timestamp',
        },
        {
            label: 'Last Updated Date',
            value: record.lastUpdatedDate ? record.lastUpdatedDate : 'n/a',
            formatAs: 'Timestamp',
        }];

    return (
        <PlatformPropertiesCard cols={2} properties={properties}/>
    );
};

