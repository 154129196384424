export const listPolicyStores = `
query listPolicyStores {
  listPolicyStores {
    items {
      permissionProfileId
      arn
      description
      createdDate
      lastUpdatedDate
    }
  }
}
`

export const getPolicyStore = `
  query getPolicyStore($permissionProfileId: ID!) {
    getPolicyStore(permissionProfileId: $permissionProfileId){
      permissionProfileId
      arn
      description
      validationSettings {
        mode
      }
      createdDate
      lastUpdatedDate
    }
  }  
`;