import * as Yup from 'yup';

export const BulkRecordSchema = Yup.object().shape({
    resourceNameSingular: Yup.string()
        .min(2, 'Too Short!')
        .max(150, 'Too Long!')
        .required('Required'),
    resourceNamePlural: Yup.string()
        .min(2, 'Too Short!')
        .max(150, 'Too Long!')
        .required('Required'),
});
