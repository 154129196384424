export const parseCedarPolicy = (policyStr: string) => {

    return {
        effect: getEffect(policyStr),
        principal: getPrincipal(policyStr),
        action: getAction(policyStr),
        resource: getResource(policyStr),
        conditions: getConditions(policyStr)
    };
}

// get policy effect using regex
export const getEffect = (policyStr: string) => {
    const match = policyStr.match(/(permit|forbid)/);
    return match ? match[1] : null;
}

// get policy principal using regex
export const getPrincipal = (policyStr: string) => {
    const match = policyStr.match(/principal: (.*)/);
    return match ? match[1] : null;
}

// get policy action using regex
export const getAction = (policyStr: string) => {
    const match = policyStr.match(/action in (.*)/);
    return match ? match[1] : null;
}

// get policy resource using regex
export const getResource = (policyStr: string) => {
    const match = policyStr.match(/resource (.*)/);
    return match ? match[1] : null;
}

// get policy conditions using regex
export const getConditions = (policyStr: string) => {
    let conditions = [] as { key: string, value: string }[];

    const match = policyStr.match(/{\n?(.*)\n?}/);

    if (match === null) {
        return conditions;
    }

    const conditionString = match ? match[1].trim() : ''
    console.debug('conditions string', conditionString)
    const conditionsArray = conditionString.split('&&')
    console.debug('conditions array', conditionsArray)
    conditions = conditionsArray.map((condition: string) => {

        // if condition contains == then split on ==, else if condition contains in then split at in
        let key = '', value = ''
        if (condition.includes('==')) {
            [key, value] = condition.split('==')
        } else if (condition.includes(' in ')) {
            [key, value] = condition.split(' in ')
        }

        console.debug('key', key)
        console.debug('value', value)

        return {
            key: key.trim(),
            value: value.trim()
                        .replace(/"/g, '')
        }
    })

    const filteredConditions = conditions.filter(condition => condition.value !== '')
    return filteredConditions;

}
