import {
    Group,
    Text
} from "@mantine/core";


import {modals} from '@mantine/modals';

import {AiOutlineWarning} from "react-icons/ai";

export const openDeleteConfirmationModal = (handler: () => void) => modals.openConfirmModal({
                                                                                                title: 'Confirmation',
                                                                                                children: (
                                                                                                    <Group>
                                                                                                        <AiOutlineWarning
                                                                                                            size={24}
                                                                                                            color="red"/>
                                                                                                        <Text
                                                                                                            size="sm"> Are
                                                                                                            you sure you
                                                                                                            want
                                                                                                            to
                                                                                                            delete
                                                                                                            selected
                                                                                                            record(s) </Text>
                                                                                                    </Group>
                                                                                                ),
                                                                                                labels: {
                                                                                                    confirm: 'Confirm',
                                                                                                    cancel: 'Cancel'
                                                                                                },
                                                                                                onCancel: () => {
                                                                                                    return;
                                                                                                },
                                                                                                onConfirm: handler,
                                                                                            });




