import {I18n} from '@aws-amplify/core';

import {
    PacFormikInput,
    TableBody,
    TableDataItem,
    TableHeaderColumn,
    TableHeaderRow
} from '@pac/platform-ui-components';


import {
    Field,
    FieldProps
} from 'formik';

import {
    SimpleGrid,
    Table,
    TextInput
} from '@mantine/core';

import {FormValuesToActionsPayload} from './BulkActionsValueHelper';

import pluralize from 'pluralize';

import _ from 'lodash'

const LinkedInput = ({
                         field: {
                             name,
                             value,

                             onChange
                         },
                         form: {setFieldValue},
                         linkedInputFields,
                         ...rest
                     }: FieldProps & { linkedInputFields: { name: string, cb: (value: string) => string }[] }) => {

    const onInputBlur = () => {
        //
        const fieldValue = value.trim();
        const normalizedValue = _.upperFirst(
            _.camelCase(fieldValue.toLowerCase())
        );

        setFieldValue(name, normalizedValue);

        linkedInputFields.forEach((record) => {
            setFieldValue(record.name, record.cb(value));
        });
    };

    return <TextInput
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onInputBlur}
        {...rest}
    />

}


export const BulkFormComponent = ({values}: {
    values: { resourceNameSingular: string, resourceNamePlural: string, baseRoute: string }
}) => {
    const columns = [
        {
            id: 'name',
            title: I18n.get('Action Name'),
            width: '1fr',
            dataKey: 'name',
        },
        {
            id: 'description',
            title: I18n.get('Description'),
            width: '1fr',
            dataKey: 'description',
        },
        {
            id: 'route',
            title: I18n.get('Action Route'),
            width: '1fr',
            dataKey: 'route',
        },
    ];

    const actionPayloads = FormValuesToActionsPayload(values);

    let actions: object[] = [];

    if (values.resourceNameSingular) {
        actions = [
            actionPayloads.list,
            actionPayloads.create,
            actionPayloads.get,
            actionPayloads.update,
            actionPayloads.delete,
        ];
    }

    const pluralizeInputValue = (value: string) =>
        _.upperFirst(_.camelCase(pluralize(value)
                                     .toLowerCase()));

    const makeBaseRoute = (value: string) =>
        ['/', _.kebabCase(pluralize(value)
                              .toLowerCase())].join('');

    const linkedInputFields: { name: string, cb: (value: string) => string }[] = [
        {
            name: 'resourceNamePlural',
            cb: pluralizeInputValue
        },
        {
            name: 'baseRoute',
            cb: makeBaseRoute
        },
    ];


    return (
        <SimpleGrid cols={1}>
            <Field
                type="text"
                name="resourceNameSingular"
                label="Resource Name - Singular"
                linkedInputFields={linkedInputFields}
                component={LinkedInput}
                withAsterisk
            />

            <Field
                type="text"
                name="resourceNamePlural"
                label="Resource Name - Plural"
                component={PacFormikInput}
                withAsterisk
            />

            <Field
                type="text"
                name="baseRoute"
                label="Base Route"
                component={PacFormikInput}
                withAsterisk
            />

            <Table verticalSpacing="sm" striped highlightOnHover>
                <TableHeaderRow
                    columns={columns as TableHeaderColumn[]}
                />
                <TableBody items={actions as TableDataItem[]} selection={{}} loading={false} columns={columns}/>
            </Table>

            <Field type="hidden" name="generatedActions" value={actions}/>
        </SimpleGrid>
    );
};

