const translationPairs = {
  Add: "Добави",
  Home: "Начало",
  Customers: "Клиенти",
  Customer: "Клиент",
  Applications: "Програми",
  Application: "Програма",
  Analytics: "Анализ",
  Users: "Потребители",
  User: "Потребител",
  "Email Templates": "Шаблони",
  "Email Template": "Емайл Шаблон",
  "API Management": "АПИ Мениджмент",
  "Email Management": "Емайл Мениджмент",
  Identities: "Идентичности",
  "Platform Management": "Платформа",
  "Audit Log": "Одит",
  "REST APIs": "Програминг Интерфейси",
  "REST API": "Програминг Интерфейс",
  "REST API Keys": "Интерфейс Ключове",
  "REST API Key": "Интерфейс Ключ",
  Name: "Име",
  Action: "Промени",
  Created: "Създадено",
};

export default translationPairs;
