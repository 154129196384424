import {
    FunctionComponent,
    useEffect
} from 'react';

import {useParams} from 'react-router-dom';

import {Stack} from '@mantine/core';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    PlatformLoader
} from '@pac/platform-ui-components';

import {useAuthorizationRequest} from '../hooks/useAuthorizationRequest';

import {EditPageNavigation} from '../navigation/EditPageNavigation.tsx';
import {Profile} from "../profile/Profile";

import {useEditPageTools} from "../hooks/useEditPageTools";

export const EditPage: FunctionComponent = () => {

    const {
        id: permissionProfileId,
        authorizationRequestId
    } = useParams();

    if (typeof permissionProfileId === 'undefined') {
        throw new Error('permissionProfileId is required parameter')
    }

    const {
        record: authorizationRequest,
        handleFetchRecord,
        handleUpdateRecord
    } = useAuthorizationRequest(permissionProfileId, authorizationRequestId);


    const {
        mode,
        tools
    } = useEditPageTools();

    useEffect(() => {
        handleFetchRecord()
    }, [])


    if (!authorizationRequest) {
        return <PlatformLoader message='Loading authorization request...Please wait'/>;
    }


    return (
        <FormPageLayout
            title={(`Authorization Request: ${authorizationRequest.id}`)}
            pageNavigation={<EditPageNavigation record={authorizationRequest}/>}
            tools={tools}
        >

            {mode === 'view' ?
                <Stack gap='lg'>
                    <Profile
                        record={authorizationRequest}
                    />
                </Stack>
                :
                <RecordForm
                    record={authorizationRequest}
                    handleSubmit={handleUpdateRecord}
                />}

        </FormPageLayout>
    );
};
