import {useEffect} from 'react';

import {MultiSelect} from '@mantine/core';
import {FieldProps} from 'formik'
import {useTenants} from "../hooks/useTenants";
import {Tenant} from "../types.ts";

export const TenantsMultiSelect = ({
                                       field: {
                                           name,
                                           value
                                       },
                                       form: {setFieldValue},
                                       ...rest
                                   }: FieldProps) => {


    const {
        items: records,
        handleFetchRecords
    } = useTenants()


    useEffect(() => {
        handleFetchRecords();
    }, []);

    const data = records.map((tenant: Tenant) => {
        return {
            value: tenant.id,
            label: tenant.name
        };
    });

    const handleChange = (value: string[]) => {
        setFieldValue(name, value);
    };

    return (
        <MultiSelect
            data={data}
            value={value}
            onChange={handleChange}
            searchable
            {...rest}
        />
    );
};
