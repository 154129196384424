import React from 'react'

import {
    NavLink,
    useParams
} from 'react-router-dom';

import {
    Box,
    Breadcrumbs
} from '@mantine/core';
import {BreadcrumbElement,} from "../../../types";
import {PolicyTemplate} from "../types.ts";

const renderBreadcrumbs = (elements: BreadcrumbElement[]) => {

    const breadcrumbs = elements.map((item, index) => (
        <React.Fragment key={index}>{item.href ?
            <NavLink to={item.href}>{item.title}</NavLink> : item.title}</React.Fragment>
    ));
    return (
        <Box p={'xs'}><Breadcrumbs separator='→' mt='xs'>{breadcrumbs}</Breadcrumbs></Box>
    );

}

const rootPathElements = (permissionProfileId: string): BreadcrumbElement[] => {

    return [
        {
            title: 'Home',
            href: '/dashboard'
        },
        {
            title: 'Permission Profiles',
            href: `/permission-profiles`
        },
        {
            title: permissionProfileId,
            href: `/permission-profiles/${permissionProfileId}`
        }
    ];

}

export const AddPageNavigation = () => {
    const {id: permissionProfileId} = useParams();

    if (typeof permissionProfileId === 'undefined') {
        throw new Error('permissionProfileId is required parameter')
    }

    const elements = rootPathElements(permissionProfileId)
        .concat([
                    {title: 'Add Policy Template'}
                ]);
    return renderBreadcrumbs(elements)
};

export const EditPageNavigation = ({record}: { record: PolicyTemplate }) => {

    const elements = rootPathElements(record.permissionProfileId)
        .concat([
                    {
                        title: 'Policy Templates'
                    },
                    {title: record.policyTemplateId}
                ]);
    return renderBreadcrumbs(elements)
};
