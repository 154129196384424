const translationPairs = {
  Home: "घर",
  Customers: "ग्राहक",
  Applications: "अनुप्रयोगों",
  Analytics: "वैश्लेषिकी",
  Users: "उपयोगकर्ताओं",
  Templates: "प्रतिरूप",
  "API Management": "एपीआई प्रबंध",
  "Email Management": "ईमेल प्रबंध",
  Identities: "पहचान",
  "Platform Management": "मंच प्रबंध",
  "Audit Log": "लेखा परीक्षा",
  "REST APIs": "एपीआई",
  "REST API Keys": "एपीआई चांबियाँ",
  Name: "नाम",
  Action: "कार्य",
  Created: "बना",
};

export default translationPairs;
