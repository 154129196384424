import {Formik} from 'formik';

import {BulkRecordSchema} from './BulkRecordSchema';

import {BulkFormComponent} from './BulkFormComponent';

import {
    DisplayFormikState,
    FormWithActionBar,
} from '@pac/platform-ui-components';

import {
    useNavigate,
    useParams
} from 'react-router-dom';

import {FormValuesToActionsPayload} from './BulkActionsValueHelper';

import {stage} from '../../../../config/stage';
import {NEXTAction} from "../../types.ts";


type BulkRecordFormValues = {
    resourceNameSingular: string,
    resourceNamePlural: string,
    baseRoute: string
}

export const BulkRecordForm = ({
                                   record,
                                   handleSubmit
                               }: { record: NEXTAction, handleSubmit: (values: NEXTAction) => void }) => {
    const navigate = useNavigate();

    const {id: permissionProfileId} = useParams();

    const onSubmit = (values: BulkRecordFormValues) => {

        const actions = FormValuesToActionsPayload(values);

        handleSubmit(actions.list);
        handleSubmit(actions.create);
        handleSubmit(actions.get);
        handleSubmit(actions.update);
        handleSubmit(actions.delete);

        navigate(`/permission-profiles/${permissionProfileId}/#schema`);
    };

    const renderForm = (props: {
        values: {
            resourceNameSingular: string,
            resourceNamePlural: string,
            baseRoute: string
        }, handleSubmit: () => void
    }) => {
        return (
            <FormWithActionBar handleSubmit={props.handleSubmit}>
                <BulkFormComponent {...props} />
                {false && stage === 'ide' && (
                    <DisplayFormikState {...props} />
                )}
            </FormWithActionBar>
        );
    };

    let initialValues = {} as BulkRecordFormValues;

    if (record) {
        initialValues = Object.assign({}, initialValues, record);
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={BulkRecordSchema}
            onSubmit={onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

