import {NavLink} from 'react-router-dom';

export const PageNotFoundPage = () => {
    return (
        <div className="mt-2 text-center">
            <h1>404 Page Not Found</h1>
            <NavLink to="/">Home Page</NavLink>
        </div>
    );
};
