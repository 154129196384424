import {
    AiOutlineApartment,
    AiOutlineControl,
    AiOutlineDatabase,
    AiOutlineHome,
    AiOutlineWallet
} from "react-icons/ai";
import {PlatformNavbarMenuItem} from "@pac/platform-ui-components";


export const getMenuItems = () => {


    const items: PlatformNavbarMenuItem[] = [
        {
            id: 'home',
            link: '/dashboard',
            label: 'Home',
            icon: <AiOutlineHome/>,
            rbac: 'GetDashboard',
        },
        {
            id: 'permission-profiles',
            link: '/permission-profiles',
            label: 'Permission Profiles',
            icon: <AiOutlineWallet/>,
            rbac: 'ListPermissionProfiles',
        },
        {
            id: 'deployment-environments',
            link: '/deployment-environments',
            label: 'Deployment Environments',
            icon: <AiOutlineApartment/>,
            rbac: 'ListDeploymentEnvironments',
        },
        {
            id: 'data-sources',
            link: '/data-sources',
            label: 'Data Sources',
            icon: <AiOutlineDatabase/>,
            rbac: 'ListDataSources',
        },
        {
            id: 'identity-providers',
            link: '/identity-providers',
            label: 'Identity Providers',
            icon: <AiOutlineControl/>,
            rbac: 'ListIdentityProviders',
        },
    ];

    return items;
};

