export const listDataSources = `
query listDataSources($filter: DataSourcesFilterInput!, $limit: Int, $nextToken: String) {
  listDataSources(filter: $filter, limit: $limit, nextToken:$nextToken) {
    items {
      id
      name
      description
      createdByUsername
      managedByUsername
      created
      updated
    }
  }
}
`

export const getDataSource = `
  query getDataSource($id: ID!) {
    getDataSource(id: $id){
      id
      name
      description
      createdByUsername
      managedByUsername
      created
      updated
    }
  }  
`;