import {
    Field,
    FormikProps
} from 'formik';

import {I18n} from '@aws-amplify/core';

import {
    Fieldset,
    SimpleGrid,
    Text
} from '@mantine/core';

import {
    PacFormikInput,
    PacFormikRadioCardGroup,
    PacFormikTextarea
} from '@pac/platform-ui-components';
import {DeploymentEnvironmentsAutocomplete} from "../../deployment-environments/inputs/DeploymentEnvironmentsAutocomplete.tsx";

export const FormComponent = (props: FormikProps<any>) => {

    const {values} = props;

    const editing = values.id ? true : false;


    return (
        <SimpleGrid cols={1}>
            <Fieldset legend={<Text fw={800} tt={'uppercase'}>Profile</Text>}>

                <Field
                    name='name'
                    label='Permission Profile Name'
                    placeholder={I18n.get('Permission Profile Name')}
                    component={PacFormikInput}
                    my={'xl'}
                />

                <Field
                    name='description'
                    label='Permission Profile Description'
                    placeholder={I18n.get('Description')}
                    component={PacFormikTextarea}
                    my={'xl'}
                />


            </Fieldset>


            {editing ?


                <Fieldset legend={<Text fw={800} tt={'uppercase'}>Policy Store</Text>}>

                    <Field
                        name="deploymentEnvironmentId"
                        label={'Deployment Environment'}
                        component={DeploymentEnvironmentsAutocomplete}
                        disabled={true}
                        my={'xl'}
                    />

                    <Field
                        name="policyStoreId"
                        label={'Policy Store ID'}
                        component={PacFormikInput}
                        disabled={true}
                        my={'xl'}
                    />


                </Fieldset>

                :

                <>

                    <Fieldset legend={<Text fw={800} tt={'uppercase'}>Policy Store</Text>}>
                        <Field
                            name="policyStoreAction"
                            value={values.policyStoreAction}
                            onChange={(value: string) => {
                                console.debug('value', value)
                                props.setFieldValue('policyStoreId', '')
                                props.setFieldValue('deploymentEnvironmentId', '')
                            }}
                            component={PacFormikRadioCardGroup}
                            data={[
                                {
                                    value: 'create',
                                    label: 'Create new policy store',
                                    description: 'Create a new policy store in the selected deployment environment'
                                },
                                {
                                    value: 'import',
                                    label: 'Use existing policy store',
                                    description: 'Connect to an existing policy store in different deployment environment. Requires delegation IAM policy'
                                }
                            ]}
                            my={'xl'}
                        />


                    </Fieldset>

                    {values.policyStoreAction && values.policyStoreAction.toLowerCase() === 'create' ?
                        <Fieldset legend={<Text fw={800} tt={'uppercase'}>Select Deployment Environment</Text>}>

                            <Field
                                name="deploymentEnvironmentId"
                                label={'Deployment Environment'}
                                component={DeploymentEnvironmentsAutocomplete}
                                my={'xl'}
                            />


                        </Fieldset> : null}

                    {values.policyStoreAction && values.policyStoreAction.toLowerCase() === 'import' ?
                        <Fieldset legend={<Text fw={800} tt={'uppercase'}>Existing Policy Store Details</Text>}>

                            <Field
                                name="deploymentEnvironmentId"
                                label={'Deployment Environment'}
                                component={DeploymentEnvironmentsAutocomplete}
                                my={'xl'}
                            />

                            <Field
                                name="policyStoreId"
                                label={'Policy Store ID'}
                                component={PacFormikInput}
                                my={'xl'}
                            />


                        </Fieldset> : null}

                </>}

        </SimpleGrid>
    );
};

