import {Field} from 'formik';

import {I18n} from '@aws-amplify/core';

import {SimpleGrid} from '@mantine/core';

import {
    PacFormikInput,
    PacFormikRadioGroup,
    PacFormikTextarea
} from '@pac/platform-ui-components';
import {NEXTPolicyStore} from "../types.ts";


export const FormComponent = ({values}: { values: NEXTPolicyStore }) => {

    const editing =
        typeof values !== 'undefined' &&
        Object.prototype.hasOwnProperty.call(values, 'arn') &&
        typeof values.arn !== 'undefined' &&
        values.arn !== '' && values.arn !== null
            ? true
            : false;

    return (
        <SimpleGrid cols={1}>

            <Field
                name='validationSettings.mode'
                label={I18n.get('Validation Settings Mode')}
                description={I18n.get('Validation Settings Mode')}
                component={PacFormikRadioGroup}
                options={[{
                    label: 'OFF',
                    value: 'OFF'
                }, {
                    label: 'STRICT',
                    value: 'STRICT'
                }]}
                disabled={editing ? true : false}
                withAsterisk
            />

            <Field
                name='description'
                label={I18n.get('Description')}
                description={I18n.get('Describe policy store')}
                component={PacFormikTextarea}
            />

            {editing ? <Field
                name='policyStoreId'
                label={I18n.get('Policy Store ID')}
                description={I18n.get('Policy Store Id')}
                component={PacFormikInput}
                disabled={true}
                withAsterisk
            /> : null}

            {editing ? <Field
                name='arn'
                label={I18n.get('Arn')}
                description={I18n.get('Arn')}
                component={PacFormikInput}
                disabled={true}
                withAsterisk
            /> : null}


        </SimpleGrid>
    );
};
