import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update
} from '../services/ApplicationsService';

import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase
} from '../../../store/RecordsSliceCaseHelpers';
import {moduleConfig} from "../config";
import {Application} from "../../users/types.ts";

const entityKey = moduleConfig.entityKey;

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
};

const normalizeRecord = (record: object) => {
    return Object.assign({}, record, {
        //id: record.id,
    });
};

// genenrates pending, fulfilled and rejected
export const fetchApplications = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    (query: object) => {
        return fetchRecords(query)
            .then((response) => {

                const collection = response?.Items;
                const totalCount = response?.TotalCount

                const normalCollection = collection.map((record: Application) => {
                    return normalizeRecord(record);
                });

                return {
                    items: normalCollection,
                    totalCount: totalCount
                }


            });
    },
);

export const fetchApplication = createAsyncThunk(`${entityKey}/fetch`, (id: string) => {
    return fetch(id)
        .then((response) => {
            return normalizeRecord(response as any);
        });
});

export const saveApplication = createAsyncThunk(
    `${entityKey}/add`,
    (payload: object) => {
        return add(payload)
            .then((response) => response);
    },
);

export const updateApplication = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record
     }: { id: string, record: object }) => {
        return update(id, record)
            .then(() => record);
    },
);

export const deleteApplication = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    },
);

const applicationsSlice = createSlice({
                                          name: entityKey,
                                          initialState,
                                          reducers: {},
                                          extraReducers: (builder) => {
                                              // FETCH MANY
                                              fetchManyCase(builder, fetchApplications, entityKey);

                                              // FETCH ONE
                                              fetchOneCase(builder, fetchApplication);

                                              // SAVE ONE
                                              saveOneCase(builder, saveApplication);

                                              // UPDATE ONE
                                              updateOneCase(builder, updateApplication);

                                              // DELETE ONE
                                              deleteOneCase(builder, deleteApplication);
                                          },
                                      });

export default applicationsSlice.reducer;
