import {FunctionComponent} from 'react';

import {useParams} from 'react-router-dom';

import {Stack} from '@mantine/core';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    PlatformLoader,
    SimplePageContentWrapper
} from '@pac/platform-ui-components';

import {useEntityType} from '../hooks/useEntityType';

import {EditPageNavigation} from '../navigation/EditPageNavigation.tsx';
import {Profile} from "../components/Profile";

import {useEditPageTools} from "../hooks/useEditPageTools";

export const EditPage: FunctionComponent = () => {

    const {
        id: permissionProfileId,
        entityTypeId
    } = useParams();

    if (typeof permissionProfileId === 'undefined') {
        throw new Error('permissionProfileId is required parameter')
    }

    const {
        record: entityType,
        handleUpdateRecord
    } = useEntityType(permissionProfileId, entityTypeId);


    const {
        mode,
        tools
    } = useEditPageTools();


    if (!entityType) {
        return <PlatformLoader message='Loading entity type...Please wait'/>;
    }


    return (
        <FormPageLayout
            title={(`Entity Type: ${entityType.name}`)}
            pageNavigation={<EditPageNavigation record={entityType}/>}
            tools={tools}
        >

            {mode === 'view' ?
                <Stack gap='lg'>
                    <Profile
                        record={entityType}
                    />
                </Stack>
                :
                <SimplePageContentWrapper>
                    <RecordForm record={entityType} handleSubmit={handleUpdateRecord}/>
                </SimplePageContentWrapper>}

        </FormPageLayout>
    );
};
