export const createPolicyStore = `
  mutation createPolicyStore($input: CreatePolicyStoreInput!) {
    createPolicyStore(input: $input){
      policyStoreId
      arn
      description
      createdDate
      lastUpdatedDate
    }
  }  
`;

export const updatePolicyStore = `
  mutation updatePolicyStore($input: UpdatePolicyStoreInput!) {
    updatePolicyStore(input: $input){
      policyStoreId
      arn
      description
    }
  }
`;

export const deletePolicyStore = `
  mutation deletePolicyStore($input: DeletePolicyStoreInput!) {
    deletePolicyStore(input: $input){
      policyStoreId
    }
  }  
`;
