import {
    getIdentitySource,
    listIdentitySources
} from './queries';

import {
    createIdentitySource,
    deleteIdentitySource,
    updateIdentitySource
} from './mutations';

import {
    CreateIdentitySource,
    DeleteIdentitySource,
    GetIdentitySource,
    IdentitySource,
    ListIdentitySources,
    UpdateIdentitySource
} from "../types.ts";
import {
    notifyError,
    notifySuccess
} from "../../../components/ServiceResponseNotifications.tsx";
import {
    generateClient,
    GraphQLQuery
} from "@aws-amplify/api";
import getUuid from "uuid-by-string";

const client = generateClient();

export const fetchRecords = async (permissionProfileId: string, filter: object = {}, maxResults = 200, nextToken = '') => {

    if (maxResults) {
        console.log('maxResults', maxResults)
    }

    let variables = {
        // MaxResults: maxResults,
    };

    if (filter) {
        filter = Object.assign({}, filter, {permissionProfileId: permissionProfileId})
    } else {
        filter = {permissionProfileId: permissionProfileId}
    }


    if (filter) {
        variables = Object.assign({}, variables, {filter: filter})
    }
    if (nextToken.length > 0) {
        variables = Object.assign({}, variables, {NextToken: nextToken})
    }


    try {
        const response = await client.graphql<GraphQLQuery<ListIdentitySources>>({
                                                                                     query: listIdentitySources,
                                                                                     variables: variables
                                                                                 });

        return response.data?.listIdentitySources.items;
    } catch (e: any) {
        notifyError(e);
    }
};

export const fetch = async (permissionProfileId: string, id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetIdentitySource>>({
                                                                                   query: getIdentitySource,
                                                                                   variables: {
                                                                                       permissionProfileId: permissionProfileId,
                                                                                       identitySourceId: id
                                                                                   },
                                                                               });

        if (typeof response.data !== 'undefined' && response.data !== null) {
            return response.data.getIdentitySource;
        } else if (response.errors) {
            response.errors.forEach(error => {
                notifyError(error);
            })
        }

    } catch (e: any) {
        notifyError(e);
    }
};

export const add = async (permissionProfileId: string, payload: IdentitySource) => {

    const {
        providerType,
        ...rest
    } = payload

    const variables = Object.assign({}, {
        input: {
            ...rest,
            clientToken: getUuid(payload.userPoolArn + new Date()),
            permissionProfileId: permissionProfileId,
        }
    });

    try {
        const response = await client.graphql<GraphQLQuery<CreateIdentitySource>>({
                                                                                      query: createIdentitySource,
                                                                                      variables: variables,
                                                                                  });

        notifySuccess('Identity Source created');
        return response.data?.createIdentitySource;

    } catch (e: any) {

        if (e.errors) {
            e.errors.forEach((error: any) => {
                notifyError(error);
            })
        } else {
            notifyError(e);
        }

    }
};

export const update = async (permissionProfileId: string, id: string, definition: IdentitySource) => {

    const {
        id: sourceId,
        created,
        updated,
        providerType,
        ...rest
    } = definition

    const variables = Object.assign({}, {
        input: {
            ...rest,
            permissionProfileId: permissionProfileId,
            identitySourceId: id
        }
    });

    try {
        const response = await client.graphql<GraphQLQuery<UpdateIdentitySource>>({
                                                                                      query: updateIdentitySource,
                                                                                      variables: variables,
                                                                                  });

        notifySuccess('Identity Source updated');
        return response.data?.updateIdentitySource;

    } catch (e: any) {
        if (e.errors) {
            e.errors.forEach((error: any) => {
                notifyError(error);
            })
        } else {
            notifyError(e);
        }
    }
};

export const deleteRecord = async (permissionProfileId: string, id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteIdentitySource>>(
            {
                query: deleteIdentitySource,
                variables: {
                    input: {
                        permissionProfileId: permissionProfileId,
                        identitySourceId: id
                    }
                },
            });

        notifySuccess('Identity Source deleted');
        return response.data?.deleteIdentitySource;

    } catch (e: any) {
        if (e.errors) {
            e.errors.forEach((error: any) => {
                notifyError(error);
            })
        } else {
            notifyError(e);
        }
    }
};
