import {
    deletePermissionProfile,
    fetchPermissionProfile,
    savePermissionProfile,
    updatePermissionProfile
} from '../reducers/recordsSlice';

import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";
import {PermissionProfile} from "../types.ts";

export const usePermissionProfile = (permissionProfileId?: string) => {
    const dispatch = useAppDispatch();

    const {
        record,
        loading
    } = useAppSelector((state) =>
                           recordSelector(state, permissionProfileId ? permissionProfileId : ''),
    );


    // FETCH
    const handleFetchRecord = () => {

        if (typeof permissionProfileId === 'undefined') {
            throw new Error('permissionProfileId is required parameter')
        }

        dispatch(fetchPermissionProfile(permissionProfileId));
    };


    // CREATE, UPDATE DELETE
    const handleCreateRecord = (values: PermissionProfile) => {
        dispatch(savePermissionProfile(values));
    };

    const handleUpdateRecord = (values: PermissionProfile) => {

        if (typeof permissionProfileId === 'undefined') {
            throw new Error('permissionProfileId is required parameter')
        }

        dispatch(updatePermissionProfile({
                                             id: permissionProfileId,
                                             record: values
                                         }));
    };

    const handleDeleteRecord = () => {

        if (typeof permissionProfileId === 'undefined') {
            throw new Error('permissionProfileId is required parameter')
        }

        dispatch(deletePermissionProfile(permissionProfileId));
    };


    return {
        record,
        loading,
        handleFetchRecord,
        handleCreateRecord,
        handleUpdateRecord,
        handleDeleteRecord,
    };
};
