export const moduleConfig = {
    entityKey: 'users'
}

export const colors = {
    'turquoise': '#40E0D0',
    'blue': '#0000FF',
    'yellow': '#FFFF00',
    'purple': '#800080',
    'green': '#00FF00',
    'red': '#FF0000',
} as { [key: string]: string }
