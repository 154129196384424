export const createDataSource = `
  mutation createDataSource($input: CreateDataSourceInput!) {
    createDataSource(input: $input){
      id
      name
      description
      createdByUsername
      managedByUsername
      created
      updated
    }
  }  
`;

export const updateDataSource = `
  mutation updateDataSource($input: UpdateDataSourceInput!) {
    updateDataSource(input: $input){
      id
      name
      description
      createdByUsername
      managedByUsername
      created
      updated
    }
  }
`;

export const deleteDataSource = `
  mutation deleteDataSource($input: DeleteDataSourceInput!) {
    deleteDataSource(input: $input){
      id
    }
  }  
`;
