import {stage} from './stage';

type ConfigShape = {
    [key: string]: {
        enabled: boolean,
        policyStoreId: string,
        region: string,
    }
}

const config: ConfigShape = {
    prd: {
        enabled: true,
        policyStoreId: 'SJjaDsywJB38iD4kfvKQ4g',
        region: 'us-west-2',
    },
    test: {
        enabled: false,
        policyStoreId: 'MZRsUdiq3jDwfqGQvNFnN6',
        region: 'us-west-2',
    },
    dev: {
        enabled: true,
        policyStoreId: 'D94oqx3S8XoXwQDr82naFJ',
        region: 'us-west-2',
    },
    ide: {
        enabled: true,
        policyStoreId: 'D94oqx3S8XoXwQDr82naFJ',
        region: 'us-west-2',
    },
};

export default config[stage];
