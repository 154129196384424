import {useAuth} from "../hooks/useAuth";
import {useUserAttributes} from "../hooks/useUserAttributes.ts";
import {GuestLayout} from "./GuestLayout.tsx";
import {UserLayout} from "./UserLayout.tsx";

export const AppLayout = () => {

    const {user} = useAuth();

    const {
        givenName,
        familyName,
        email
    } = useUserAttributes()


    return user ? <UserLayout givenName={givenName} familyName={familyName} email={email}/> : <GuestLayout/>

};
