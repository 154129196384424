import React from 'react'
import {NavLink} from 'react-router-dom';

import {
    Box,
    Breadcrumbs
} from '@mantine/core';

export const IndexPageNavigation = () => {

    const breadcrumbs = [{
        title: 'Home',
        href: '/dashboard'
    },
        {title: 'Policy Stores'}].map((item, index) => (
        <React.Fragment key={index}>{item.href ?
            <NavLink to={item.href}>{item.title}</NavLink> : item.title}</React.Fragment>
    ));
    return (
        <Box p={'xs'}><Breadcrumbs separator='→' mt='xs'>{breadcrumbs}</Breadcrumbs></Box>
    );
};

