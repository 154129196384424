export const createPolicyTemplate = `
  mutation createPolicyTemplate($input: CreatePolicyTemplateInput!) {
    createPolicyTemplate(input: $input){
      policyStoreId
      policyTemplateId
      statement
      createdDate
      lastUpdatedDate
    }
  }  
`;

export const updatePolicyTemplate = `
  mutation updatePolicyTemplate($input: UpdatePolicyTemplateInput!) {
    updatePolicyTemplate(input: $input){
      policyStoreId
      policyTemplateId
      statement
      createdDate
      lastUpdatedDate
    }
  }
`;

export const deletePolicyTemplate = `
  mutation deletePolicyTemplate($input: DeletePolicyTemplateInput!) {
    deletePolicyTemplate(input: $input){
      policyTemplateId
    }
  }  
`;
