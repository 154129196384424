import {NEXTAction} from "../../types.ts";

export const FormValuesToActionsPayload = (values: {
    resourceNameSingular: string,
    resourceNamePlural: string,
    baseRoute: string
}) => {
    // const nameSingular = values.resourceNameSingular;
    // const namePlural = values.resourceNamePlural;

    const listName = ['List', values.resourceNamePlural].join('');
    const createName = ['Create', values.resourceNameSingular].join('');
    const getName = ['Get', values.resourceNameSingular].join('');
    const updateName = ['Update', values.resourceNameSingular].join('');
    const deleteName = ['Delete', values.resourceNameSingular].join('');

    const listDescription = ['List', values.resourceNamePlural].join(' ');
    const createDescription = ['Create', values.resourceNameSingular].join(' ');
    const getDescription = ['Get', values.resourceNameSingular].join(' ');
    const updateDescription = ['Update', values.resourceNameSingular].join(' ');
    const deleteDescription = ['Delete', values.resourceNameSingular].join(' ');

    const listRoute = [values.baseRoute, ''].join('');
    const createRoute = [values.baseRoute, '/add'].join('');
    const getRoute = [values.baseRoute, '/{id}'].join('');
    const updateRoute = [values.baseRoute, '/{id}'].join('');
    const deleteRoute = [values.baseRoute, '/{id}'].join('');

    const listPayload: NEXTAction = {
        id: 'list-name',
        name: listName,
        description: listDescription,
        route: listRoute,
        method: 'GET',
    };
    const createPayload: NEXTAction = {
        id: 'create-name',
        name: createName,
        description: createDescription,
        route: createRoute,
        method: 'POST',
    };
    const getPayload: NEXTAction = {
        id: 'get-name',
        name: getName,
        description: getDescription,
        route: getRoute,
        method: 'GET',
    };
    const updatePayload: NEXTAction = {
        id: 'update-name',
        name: updateName,
        description: updateDescription,
        route: updateRoute,
        method: 'PUT',
    };
    const deletePayload: NEXTAction = {
        id: 'delete-name',
        name: deleteName,
        description: deleteDescription,
        route: deleteRoute,
        method: 'DELETE',
    };

    return {
        list: listPayload,
        create: createPayload,
        get: getPayload,
        update: updatePayload,
        delete: deletePayload,
    };
};
