import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    replay,
    update
} from '../../authorization-requests/services/AuthorizationRequestsService';

import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase
} from '../../../store/RecordsSliceCaseHelpers';
import {recordSelector} from "../selectors";
import {NEXTAuthorizationRequest} from "../types.ts";
import {moduleConfig} from "../config";


const entityKey = moduleConfig.entityKey;

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
};


const normalizeRecord = (record: NEXTAuthorizationRequest) => {
    return Object.assign({}, record);
};

// genenrates pending, fulfilled and rejected
export const fetchAuthorizationRequests = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    (permissionProfileId: string) => {

        return fetchRecords(permissionProfileId)
            .then((response) => {
                return response?.map((record) => {
                    return normalizeRecord(record);
                });
            });
    },
);

export const fetchAuthorizationRequest = createAsyncThunk(`${entityKey}/fetch`,
                                                          (id: string) => {
                                                              return fetch(id)
                                                                  .then((response) => {
                                                                      return normalizeRecord(response as NEXTAuthorizationRequest);
                                                                  });
                                                          });


export const saveAuthorizationRequest = createAsyncThunk(`${entityKey}/add`,
                                                         (payload: NEXTAuthorizationRequest) => {
                                                             return add(payload)
                                                                 .then((response) => normalizeRecord(response as NEXTAuthorizationRequest));
                                                         });

export const updateAuthorizationRequest = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record
     }: { id: string, record: NEXTAuthorizationRequest }) => {

        return update(id, record)
            .then((response) => {
                return normalizeRecord(response as NEXTAuthorizationRequest);
            });
    },
);

export const deleteAuthorizationRequest = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    },
);

export const replayAuthorizationRequest = createAsyncThunk(
    `${entityKey}/replay`,
    (id: string, {getState}) => {

        const state = getState()
        const {record} = recordSelector(state, id)

        return replay(record.permissionProfileId, record.action, record.context, record.principal, record.resource)
            .then(() => id);
    },
);

const authorizationRequestsSlice = createSlice({
                                                   name: entityKey,
                                                   initialState,
                                                   reducers: {},
                                                   extraReducers: (builder) => {
                                                       // FETCH MANY
                                                       fetchManyCase(builder, fetchAuthorizationRequests, entityKey);

                                                       // FETCH ONE
                                                       fetchOneCase(builder, fetchAuthorizationRequest);

                                                       // SAVE ONE
                                                       saveOneCase(builder, saveAuthorizationRequest);

                                                       // UPDATE ONE
                                                       updateOneCase(builder, updateAuthorizationRequest);

                                                       // DELETE ONE
                                                       deleteOneCase(builder, deleteAuthorizationRequest);

                                                       // REPLAY
                                                       fetchOneCase(builder, replayAuthorizationRequest);

                                                   },
                                               });

export default authorizationRequestsSlice.reducer;
