import {Field} from 'formik';

import {I18n} from '@aws-amplify/core';

import {SimpleGrid} from '@mantine/core';

import {
    PacFormikInput,
    PacFormikRadioGroup,
    PacFormikTextarea
} from '@pac/platform-ui-components';

export const FormComponent = () => {

    return (
        <SimpleGrid cols={1}>

            <Field
                name='name'
                label='Identity Provider Name'
                placeholder={I18n.get('Identity Provider Name')}
                component={PacFormikInput}
            />

            <Field
                name='providerType'
                label='Identity Provider Type'
                placeholder={I18n.get('Identity Provider Type')}
                component={PacFormikRadioGroup}
                options={[
                    {
                        value: 'AmazonCognito',
                        label: 'Amazon Cognito'
                    },
                    {
                        value: 'ExternalProvider',
                        label: 'External Provider'
                    }
                ]}
            />

            <Field
                name='description'
                label='Identity Provider Description'
                placeholder={I18n.get('Description')}
                component={PacFormikTextarea}
            />


        </SimpleGrid>
    );
};

