import {
    Grid,
    SimpleGrid,
} from '@mantine/core';
import {PoliciesWidget} from "./dashboard/PoliciesWidget.tsx";
import {PolicyTemplatesWidget} from './dashboard/PolicyTemplatesWidget.tsx';
import {EntityTypesWidget} from "./dashboard/EntityTypesWidget.tsx";
import {ActionsWidget} from "./dashboard/ActionsWidget.tsx";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../../config/rbac/actions.ts";

export const PortalDashboard = () => {

    const {allowedActions} = useVerifiedPermissions()

    return (
        <Grid>
            <Grid.Col span={1}/>
            <Grid.Col span={10}>
                <SimpleGrid cols={3}>
                    {allowedActions.includes(rbacActions.ListPolicies) ? <PoliciesWidget/> : null}
                    {allowedActions.includes(rbacActions.ListPolicyTemplates) ? <PolicyTemplatesWidget/> : null}
                    {allowedActions.includes(rbacActions.ListEntityTypes) ? <EntityTypesWidget/> : null}
                    {allowedActions.includes(rbacActions.ListActions) ? <ActionsWidget/> : null}
                </SimpleGrid>
            </Grid.Col>
            <Grid.Col span={1}/>
        </Grid>
    );
};
