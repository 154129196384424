import {
    deletePermissionProfile,
    fetchPermissionProfiles
} from '../reducers/recordsSlice';

import {
    recordsSelector,
    selectionSelector,
    visibilitySelector
} from '../selectors';

import {columns} from '../tables/columns';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux";


export const usePermissionProfiles = () => {
    const dispatch = useAppDispatch();

    const selection = useAppSelector((state) => selectionSelector(state));

    const {
        items,
        visibleItems,
        visibleCount
    } =
        useAppSelector((state) => visibilitySelector(state));

    const {loading} = useAppSelector((state) =>
                                         recordsSelector(state),
    );

    // FETCH
    const handleFetchRecords = () => {
        dispatch(fetchPermissionProfiles({}));
    };


    // MULTIPLE DELETE
    const handleDeleteRecords = () => {
        if (Object.keys(selection).length > 0) {
            for (const key in selection) {
                dispatch(deletePermissionProfile(key));
            }
        }
    };


    return {
        items,
        visibleItems,
        loading,
        visibleCount,
        columns,
        handleFetchRecords,
        handleDeleteRecords,
    };
};
