import {useParams} from 'react-router-dom';

import {Stack} from '@mantine/core';

import {RecordForm} from '../forms/simple/RecordForm';

import {
    FormPageLayout,
    PlatformLoader
} from '@pac/platform-ui-components';

import {useAction} from '../hooks/useAction';

import {EditPageNavigation} from '../navigation/EditPageNavigation.tsx';
import {Profile} from "../profile/Profile";
import {useEditPageTools} from "../hooks/useEditPageTools";

export const EditPage = () => {

    const {
        id: permissionProfileId,
        actionId
    } = useParams();

    if (typeof permissionProfileId === 'undefined') {
        throw new Error('permissionProfileId is required parameter')
    }


    const {
        record: action,
        handleUpdateRecord
    } = useAction(permissionProfileId, actionId);

    const {
        mode,
        tools
    } = useEditPageTools();


    if (!action) {
        return <PlatformLoader message='Loading action...Please wait'/>;
    }


    return (
        <FormPageLayout
            title={(`Action: ${action.name}`)}
            pageNavigation={<EditPageNavigation record={action}/>}
            tools={tools}
        >

            {mode === 'view' ?
                <Stack gap='lg'>
                    <Profile
                        record={action}
                    />
                </Stack>
                :
                <RecordForm
                    record={action}
                    handleSubmit={handleUpdateRecord}
                />}

        </FormPageLayout>
    );
};
